import React from 'react'
import './App.css';
import packageJson from "../package.json";
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, Redirect } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import UnderMaintenance from "./components/Templates/UnderMaintenance";
import LoginForm from "./components/Auth/View/Form";
import AxiosLib from "./components/Library/AxiosLib";
import PageNotFound from "./components/Templates/PageNotFound";
import LayoutMobile from './components/MobileAppsView/LayoutMobile';
import LayoutNew from './components/Templates/LayoutNew';


function App() {
    const baseUri = window.location.href;
    if(!baseUri.includes("localhost")){
        console.log = console.warn = console.error = () => { };
        console.warn = () => { };
    }

    const IsAuth = localStorage.getItem("AuthToken");
    const dispatch = useDispatch();
    const SignOutPage = () => {
        dispatch({ type: "SIGN_OUT" });
    }
    const [IsError, setIsError] = useState({ title: "-", value: false });
    const [maintenance, setMaintenance] = useState([]);

    const fetchingMaintenance = () => {
        AxiosLib({ PortalID: packageJson.system_param.PortalID, CurrentDate: new Date() }, 'mc/api/maintenance-portal')
        .then(
            response => {
                if (response.data) {
                    setMaintenance(response.data);
                } else if (response.error) {
                    setIsError({ title: response.error, value: true });
                }
            }
        )
        .catch((error) => {
            console.log("errror", error);
            //alert("Error Connection Refuse");
        });
    }

    useEffect(() => {
        fetchingMaintenance();
    },[])

    return (

        <Switch>
            {!IsAuth ? (
                /*Render auth page when user at `/auth` and not authorized.*/
                (IsError.value) ? (
                    <UnderMaintenance data={{ Title: "Blocked by your network", Description: "Error Connection Refuse.<br/> "+IsError.title }} />
                ) : (
                    (Object.keys(maintenance).length > 0) ? <UnderMaintenance data={maintenance[0]} /> : <Route path="/sign-in" component={LoginForm} />
                )
            ) : (
                /*Otherwise redirect to root page (`/`)*/
                <Redirect from="/sign-in" to="/" />
            )}

            <Route path="/sign-out" component={SignOutPage} />



            {!IsAuth ? (
                /*Redirect to `/auth` when user is not authorized*/
                <Redirect to="/sign-in" />
            ) : (
                (IsError.value) ? (
                    <UnderMaintenance data={{ Title: "Blocking by your network", Description: "Error Connection Refuse.<br/> "+IsError.title }} />
                ) : (
                    (Object.keys(maintenance).length) ? <UnderMaintenance data={maintenance[0]} /> : (
                        <>
                            <BrowserView>
                                <LayoutNew />
                            </BrowserView>
                            <MobileView>
                                <LayoutMobile />
                            </MobileView>
                        </>
                    )
                )
            )}
            <Route component={PageNotFound} />
        </Switch>

    );
}

export default App;
