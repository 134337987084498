import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import AxiosLibWithHeader2 from '../../../../Library/AxiosLibWithHeader2';
import LoadingBtn from "../../../Widgets/LoadingBtn";
import TableData from "./components/TableData";
import Detail from "./components/Detail";

export function ThesisScore() {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];
    //console.log(AuthDecode)
    const [lstThesis, setLstThesis] = useState({
        message: "",
        data: [],
        loading: false,
    });
    const [selectedSession, setSelectedSession] = useState({});
    const [thesisSchemaAkses, setthesisSchemaAkses] = useState({roleid:(AuthDecode.identity)?AuthDecode.identity.roleid:0});
    const GET_THS_BY_EXAMINER = (value) => {
        setLstThesis({ ...lstThesis, loading: true, data: [], message: "" });
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };
        var body = {
            parameters: {
                offset: 1,
                limit: 1000
            },
            target_data: "GET_THS_BY_EXAMINER",
        };
        const postData = {
            keys: keys,
            body: body,
        };

        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            // console.log(response)
            if (response.error) {
                setLstThesis({
                    ...lstThesis,
                    message: response.error,
                    loading: false,
                    data: []
                });
            } else if ("message" in response.response) {
                var msg = response.response.message.user;
                setLstThesis({ ...lstThesis, message: msg, loading: false, data: [] });
            } else {
                var results = response.response.data;

                if (results) {
                    setLstThesis({ ...lstThesis, data: results, loading: false, message: "" });
                } else {
                    setLstThesis({
                        ...lstThesis,
                        message: "No record found",
                        loading: false,
                        data: [],
                    });
                }
            }
        });
    };
    useEffect(() => {
        GET_THS_BY_EXAMINER();
    }, [selectedSession])



    return (
        <div id='atd-class-list' className='d-flex flex-column flex-grow-1'>
            <div className="alert alert-custom alert-white alert-shadow fade show gutter-b" role="alert">
                <div className="alert-icon">
                    <span className="svg-icon svg-icon-primary svg-icon-xl">
                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <rect x="0" y="0" width="24" height="24"></rect>
                                <path d="M7.07744993,12.3040451 C7.72444571,13.0716094 8.54044565,13.6920474 9.46808594,14.1079953 L5,23 L4.5,18 L7.07744993,12.3040451 Z M14.5865511,14.2597864 C15.5319561,13.9019016 16.375416,13.3366121 17.0614026,12.6194459 L19.5,18 L19,23 L14.5865511,14.2597864 Z M12,3.55271368e-14 C12.8284271,3.53749572e-14 13.5,0.671572875 13.5,1.5 L13.5,4 L10.5,4 L10.5,1.5 C10.5,0.671572875 11.1715729,3.56793164e-14 12,3.55271368e-14 Z" fill="#000000" opacity="0.3"></path>
                                <path d="M12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 Z M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z" fill="#000000" fillRule="nonzero"></path>
                            </g>
                        </svg>
                    </span>
                </div>
                <div className="alert-text"><h3>{(Object.values(selectedSession).length > 0) ? "Thesis Scoring Sheet" : "Submit Score"}</h3></div>
            </div>

            {(Object.values(selectedSession).length > 0) ? (
                <Detail data={selectedSession} setSelectedSession={setSelectedSession} />
            ) : (
                <div className="card card-custom card-stretch gutter-b mb-8">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="d-flex align-items-center">
                                <div className="border-right pr-3">

                                </div>
                                <div className="title d-flex align-items-center ml-5">
                                    <span className="card-icon">
                                        <i className="flaticon2-writing"></i>
                                    </span>
                                    <h3 className="card-label">Student List</h3>
                                </div>
                            </div>
                        </div>
                        <div className="card-toolbar">
                            <div className="btn-group">
                                {(parseInt(thesisSchemaAkses.roleid) !== 4)? 
                                <Link to="thesis-scheme/" target="_blank" className="btn btn-light-primary btn-lg font-weight-bolder">
                                <i className="flaticon-cogwheel-2"></i><span>Grading Scheme Setting</span>

                                </Link>
                                 : ""
                                }
                                
                                
                                <button className="btn btn-sm btn-clear" type='button'>
                                    Total Item {Object.values(lstThesis.data).length}
                                </button>
                            </div>

                        </div>
                    </div>
                    <div className="card-body">
                        {(lstThesis.loading) ? <LoadingBtn /> : ''}
                        <div className={((lstThesis.loading) ? "d-none" : "d-block") + ""} id="card-bar" >
                            {(Object.values(lstThesis.data).length > 0) ? (
                                <TableData data={lstThesis.data} setSelectedSession={setSelectedSession} />
                            ) : <>No record found</>}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
