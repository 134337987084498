import React from "react";
import AvatarBoy from "../../../../../media/avatars/001-boy.svg";
import AvatarGirl from "../../../../../media/avatars/002-girl.svg";
import { StudentIDFormat } from "../../../../Library/GlobalHelper";
import { BoxLabel, CardMessage } from "./PCFUI";

export default function CardProfilePCF({ studentid, student_status, student_bio }) {

  if (Object.values(student_bio).length > 0) {
    return (
      <div className="card-profile">
        <div className="card card-custom card-stretch gutter-b">
          <div className="card-body">
            <div className="d-flex">
              <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                <div className="symbol symbol-130 symbol-primary">
                  <img
                    src={
                      student_bio.gender === "p" ? AvatarBoy : AvatarGirl
                    }
                    alt={student_bio.fullname}
                    className="bg-light-primary"
                  />
                </div>
              </div>

              <div className="flex-grow-1">
                <div className="d-flex justify-content-between flex-wrap mt-0">
                  <div className="d-flex mr-3">
                    <p className="text-dark-75 font-size-h1 font-weight-bold mr-3 mb-0">
                      {student_bio.fullname}
                    </p>
                    <span className="">
                      <i
                        className={
                          "font-size-h5 " +
                          (Object.values(student_status).length > 0 && (
                            student_status.status.id !== "asc.student.status.active" ? "flaticon2-cancel text-danger" : "flaticon2-correct  text-success"
                          ))}
                      ></i>
                    </span>
                  </div>

                  <div className="my-lg-0 my-3">
                    <span className="btn btn-light-success  font-weight-bold mr-3">
                      {student_status?.status?.name || "Unkonwn"} Student
                    </span>
                    {student_status.enrollment_type && (
                      <span className="btn btn-light font-weight-bold">
                        {student_status?.enrollment_type?.name || "Unkonwn"}
                      </span>
                    )}
                  </div>
                </div>


                <div className="mt-1">
                  <div className="d-flex flex-wrap mb-2">
                    <LabelTextIcon icon={"flaticon2-new-email"} value={student_bio?.sgumail || "-"} />
                    <LabelTextIcon icon={"flaticon2-calendar-3"} value={StudentIDFormat(studentid)} />
                    <LabelTextIcon icon={"socicon-whatsapp"} value={student_bio?.cellular || "-"} />
                  </div>

                  <div className="d-flex flex-wrap justify-content-between mt-1">
                    <div className="d-flex flex-column flex-grow-1 pr-8">
                      {Object.values(student_status).length > 0 && (
                        <div className="d-flex flex-wrap">
                          <BoxLabel title={student_status?.program?.name || "Program Degree"} value={student_status?.clu?.name || "-"} />
                          {student_status.clu_spec && (
                            <BoxLabel title={"Specialization"} value={student_status?.clu_spec?.name || "-"} />
                          )}
                        </div>
                      )}
                    </div>

                    <div className="">
                      <BoxLabel title={"Semester"} value={student_status?.semester} />
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return <CardMessage message={"No record found"} icon={"box"} />
  }
}


const LabelTextIcon = ({ value, icon }) => {
  return (
    <span className="text-dark-50 font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2 d-flex align-items-center">
      <i className={icon + " mr-1 font-size-lg"}></i>
      <span>
        {value}
      </span>
    </span>
  )
}