import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import Moment from "moment";
import AxiosLib from "../../Library/AxiosLib";

export function SubHeader(props) {
  const token = localStorage.getItem("AuthToken");
  const decoded = jwt_decode(token);
  const URLPath = window.location.pathname;
  const URLPathSplit = URLPath.split("/");
  const URLPathName = URLPathSplit[1];
  var breadcrumb = [];

  if (URLPathSplit.length > 2) {
    for (var i = 2; i < URLPathSplit.length; i++) {
      if (i <= 3) {
        breadcrumb.push(
          <span className="text-muted font-weight-bold mr-4  text-capitalize" key={i}>
            {URLPathSplit[i].split("-").join(" ")}
          </span>
        );
      }
    }
  }

  const [time, setTime] = useState();
  const [currtime, setCurrtime] = useState({
    loading: false,
    data: [],
    message: ""
  });
  const GetCurrentTime = () => {
    setCurrtime({ ...currtime, loading: true, data: [] });
    AxiosLib("", "app/api/current-time").then((response) => {
      if (response.error) {
        setCurrtime({ ...currtime, loading: false, data: [], message: response.error });
      } else {
        setCurrtime({ ...currtime, loading: false, data: response, message: "" });
        setTime(Moment(response.today));
      }
    });
  }


  useEffect(() => {
    GetCurrentTime();

    const timer = setInterval(() => {
      setTime(Moment());
    }, 1000); // Update every second

    return () => {
      clearInterval(timer);
    };
  }, [])

  const hours = Moment(time).format("HH");
  const minutes = Moment(time).format("mm");
  const seconds = Moment(time).format("ss");

  return (
    <div className="subheader py-2 py-lg-4 subheader-solid" id="kt_subheader">
      <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
        <div className="d-flex align-items-center flex-wrap mr-2">
          <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5 text-capitalize">
            {URLPathName.split("-").join(" ")}
          </h5>
          {URLPathName === "detail" || URLPathName === "score-and-grade" || URLPathName === "regulations-form" ? (
            <>
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"></div>
              <span className="text-muted font-weight-bold mr-4 text-capitalize">
                {URLPathName == "regulations-form"
                  ? "Form Submit"
                  : URLPathSplit[2].split("-").join(" ")}
              </span>
            </>
          ) : breadcrumb.length > 0 ? (
            <>
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"></div>
              {breadcrumb}
            </>
          ) : (
            ""
          )}
        </div>

        {decoded.remotedBy && (
          <div className="d-flex">
            <div className="topbar-item mr-10">
              <div className="btn-group">
                <div className="btn btn-danger btn-icon btn-sm">
                  <i className="la la-user-cog"></i>
                </div>
                <div className="btn btn-light btn-sm font-size-sm">
                  Controlled by{" "}
                  <span className="r-name">
                    {decoded.remotedBy.given_name +
                      " " +
                      decoded.remotedBy.family_name}
                  </span>{" "}
                  / <span className="r-role">{decoded.remotedBy.role}</span>
                </div>
                <button
                  className="btn btn-danger btn-icon btn-sm"
                  type="button"
                  title="Sign Out"
                  onClick={(e) => props.signOutRemote()}
                >
                  <i className="la la-sign-out"></i>
                </button>
              </div>
            </div>
            <div className="topbar-item mr-10">
              <div className="btn-group">
                <div className="btn btn-info btn-icon btn-sm">
                  <i className="la la-user-cog"></i>
                </div>
                <div className="btn btn-light btn-sm font-size-sm">
                  Session user 
                  <span className="r-name ml-1">
                    {decoded.currentData.given_name +
                      " " +
                      decoded.currentData.family_name}
                  </span>{" "}
                  / <span className="r-role">{decoded.currentData.role}</span>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="d-flex align-items-center">
          <span
            className="btn btn-sm btn-light font-weight-bold mr-2"
            id="kt_dashboard_daterangepicker"
          >
            <span
              className="text-muted font-size-base font-weight-bold mr-2"
              id="kt_dashboard_daterangepicker_title"
            >
              Today
            </span>
            <span className="text-primary font-size-base font-weight-bolder" id="kt_dashboard_daterangepicker_dates">
              {(Object.values(currtime.data).length > 0) ? ((currtime.data.today) ? (
                <>
                  <span className="date mr-3">{Moment(currtime.data.today).format("MMM DD, YYYY")}</span>
                  <span className="time">
                    {hours}:{minutes}
                  </span>
                </>
              ) : "-") : "-"}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default SubHeader;
