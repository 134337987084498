import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import LoadingBtn from "../../../Widgets/LoadingBtn";
import { AlertNotif } from "../../../../Library/AlertNotif";
import { REQUEST_TARGET_MODULES } from "../../../../Library/APIsTarget";


const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: 9999 })
};


const DepartmentLookUp = ({ data, setData, required = false, disabled = false, title }) => {
    const [selected, setSelected] = useState(data || "");
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState();
    const [result, setResult] = useState({
        loading: false,
        message: "",
        data: [],
    });
    const ReloadData = async () => {
        setResult({
            loading: true,
            message: "",
            data: [],
        });

        const param = { clu_type: "asc.clu.type.Major" };
        const param_estes = {
            parameters: param,
            target_data: "CLU_LOOK_UP",
        }

        const result = await REQUEST_TARGET_MODULES(param_estes);

        if (result.data && Object.values(result.data).length > 0) {
            const option_result = result.data.map((v) => ({
                value: v.id,
                label: v.name,
            })).sort((a, b) => a.label.localeCompare(b.label));


            setOptions(option_result);
            if (selected) {
                const findSelected = option_result.find(
                    (item) => item.value === selected
                );
                setSelectedOption(findSelected);
            }
            setResult(result);
        } else {
            setResult(result);
        }
    };

    useEffect(() => {
        ReloadData();
    }, []);

    const HandleChange = (option) => {
        if (option && Object.values(option).length > 0) {
            setSelectedOption(option);
            setSelected(option.value);
            setData(option.value);
        } else {
            setSelectedOption([]);
            setSelected("");
            setData("");
        }
    };

    return (
        <div className="form-group mb-5">
            <label className={"font-weight-bold " + (required && "required")}>
                {title ? title : "Department"}
            </label>
            {result.loading ? (
                <LoadingBtn />
            ) : result.message ? (
                <AlertNotif message={result.message} />
            ) : (
                Object.values(result.data).length > 0 && (
                    <Select
                        name="clu_id"
                        classNamePrefix="react-select"
                        value={selectedOption}
                        onChange={HandleChange}
                        isDisabled={disabled}
                        options={options}
                        placeholder="Choose One"
                        styles={customStyles}
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        isClearable
                    />
                )
            )}
        </div>
    );
};

const StudentInput = ({
    data,
    setData,
    required = false,
    disabled = false,
    title,
}) => {
    const [selected, setSelected] = useState(data || "");
    const HandlerChange = (value) => {
        setSelected(value);
        setData(value);
    };
    return (
        <div className="form-group mb-5">
            <label className={"font-weight-bold " + (required && "required")}>
                {title ? title : "Student Name"}
            </label>
            <input
                type="text"
                className="form-control form-control-sm"
                disabled={disabled}
                defaultValue={selected}
                onChange={(e) => HandlerChange(e.target.value)}
            />
        </div>
    );
};

const StudentID = ({ data, setData, required = false, disabled = false, title }) => {
    const [selected, setSelected] = useState(data || "");
    const inputRef = useRef(null);

    const HandlerChange = (value) => {
        const numericValue = value.replace(/\D/g, '');
        setSelected(numericValue);
        setData(numericValue);
    };

    useEffect(() => {
        const input = inputRef.current;
        const handlePaste = (e) => {
            e.preventDefault();
            const pastedText = e.clipboardData.getData('text');
            const numericValue = pastedText.replace(/\D/g, '');
            document.execCommand('insertText', false, numericValue);
        };

        input.addEventListener('paste', handlePaste);
        return () => {
            input.removeEventListener('paste', handlePaste);
        };
    }, []);

    return (
        <div className="form-group mb-5">
            <label className={"font-weight-bold " + (required && "required")}>
                {title ? title : "Student ID"}
            </label>
            <input
                type="text"
                maxLength={10}
                className="form-control form-control-sm"
                disabled={disabled}
                value={selected}
                onChange={(e) => HandlerChange(e.target.value)}
                onInput={(e) => {
                    e.target.value = e.target.value.replace(/\D/g, '');
                }}
                ref={inputRef}
            />
        </div>
    );
};

export { DepartmentLookUp, StudentInput, StudentID }