import React, { useEffect, useState } from 'react'
import { DepartmentLookUp, StudentID, StudentInput } from './components/FormStdUI';
import { openToast } from '../../../Library/ToastPopUp';
import { RemoveEmptyValue } from '../../../Library/GlobalHelper';
import { REQUEST_TARGET_MODULES } from '../../../Library/APIsTarget';
import LoadingBtn from '../../Widgets/LoadingBtn';
import { CardMessage } from '../../Student/Reregistration-New/Components/PCFUI';
import TableData from './TableData';

export function BrowseStudent() {
    const [param, setParam] = useState();
    const [results, setResults] = useState({ loading: false, data: [], message: '' });
    const ReloadData = async (param) => {
        setResults({ loading: true, data: [], message: "" })
        const param_estes = {
            parameters: param,
            target_data: "GET_STUDENT",
        }

        const result = await REQUEST_TARGET_MODULES(param_estes);
        if (result.data && Object.values(result.data).length > 0) {
            setResults({ loading: false, data: result.data, message: "" })
        } else {
            setResults(result);
        }
    }

    useEffect(() => {
        if (param) {
            const post_param = RemoveEmptyValue(param);
            ReloadData(post_param);
        }
    }, [param])

    return (
        <div className="row">
            <div className="col-lg-3">
                <FilterData setFilter={setParam} />
            </div>
            <div className="col-lg-9">
                <div className='card card-custom'>
                    <div className="card-header flex-wrap border-0 pt-6 pb-0">
                        <div className="card-title">
                            <h3 className="card-label">
                                Browse Student Data
                            </h3>
                        </div>
                    </div>

                    <div className="card-body pt-0">
                        {results.loading ? <LoadingBtn /> : 
                        results.message ? <CardMessage message={results.message} icon={"box"} /> :
                        Object.values(results.data).length > 0 ? (
                            <TableData data={results.data} ReloadData={ReloadData} />
                        ) : <CardMessage message={"No record found"} icon={"box"} />}
                    </div>
                </div>
            </div>
        </div>

    )
}

const FilterData = ({ setFilter }) => {
    const [clu_id, setClu_id] = useState("");
    const [studentname, setStudentname] = useState("");
    const [studentid, setStudentid] = useState("");

    const SubmitFilter = (e) => {
        e.preventDefault();
        if (studentid || studentname || clu_id) {
            setFilter({ studentid, studentname, clu_id });
        } else {
            openToast({ header: "Information", message: "Please fill in at least one search field" });
        }
    }
    return (
        <div className="card card-custome">
            <div className="card-header flex-wrap border-0 pt-6 pb-0">
                <div className="card-title">
                    <h3 className="card-label">
                        <span>Filter Student</span>
                        <span className="d-block text-muted pt-2 font-size-sm">Please fill in at least one search field</span>
                    </h3>
                </div>
            </div>
            <div className="card-body py-0">
                <form method="post" autoComplete='off' onSubmit={(e) => SubmitFilter(e)}>
                    <StudentInput data={studentname} setData={setStudentname} />
                    <StudentID data={studentid} setData={setStudentid} />
                    <DepartmentLookUp data={clu_id} setData={setClu_id} />
                    <div className="my-8">
                        <button className="btn btn-primary btn-sm btn-block font-weight-bold font-size-lg" type="submit">
                            Search
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}
