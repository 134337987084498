import React from "react";

const Approved = () => {
  return (
    <div className="d-flex flex-column align-items-center" style={{ gap: 5 }}>
      <p style={{ fontSize: 24 }}>SUBMITTED</p>
      <p style={{ fontSize: 16 }}>
        Your <span className="text-success">APPROVAL</span> has been submitted
      </p>
    </div>
  );
};

const Rejected = () => {
  return (
    <div className="d-flex flex-column align-items-center" style={{ gap: 5 }}>
      <p style={{ fontSize: 24 }}>SUBMITTED</p>
      <p style={{ fontSize: 16 }}>
        Your <span className="text-danger">REJECTION</span> has been submitted
      </p>
    </div>
  );
};

const SuccessFailedDialog = ({ status, res, retry, back }) => {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ gap: 10 }}
    >
      {res === "" ? (
        <i
          class="far fa-check-circle text-success my-5"
          style={{ fontSize: 60 }}
        ></i>
      ) : (
        <i class="far fa-times-circle text-danger" style={{ fontSize: 44 }}></i>
      )}
      <div>
        {res === "" ? (
          status === 1 ? (
            <Approved />
          ) : (
            <Rejected />
          )
        ) : (
          <>
            <div
              className="d-flex flex-column align-items-center"
              style={{ gap: 5 }}
            >
              <p style={{ fontSize: 24 }}>FAILED</p>
              <p style={{ fontSize: 16, textAlign: "center" }}>{res}</p>
              <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ gap: 10 }}
              >
                <button
                  type="button"
                  className="btn btn-secondary w-50"
                  onClick={() => back(false)}
                >
                  Back
                </button>
                <button
                  type="button"
                  className="btn btn-primary w-50"
                  onClick={() => retry()}
                >
                  Retry
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SuccessFailedDialog;
