import React, { useEffect, useState } from 'react'
import jwt_decode from "jwt-decode"
import { Link } from 'react-router-dom'
import UserPicBoy from '../../../media/avatars/001-boy.svg'
import AxiosLibWithHeader2 from '../../Library/AxiosLibWithHeader2';
import LoadingBtn from '../../Page/Widgets/LoadingBtn';

export function Header() {
    const token = localStorage.getItem("AuthToken");
    const AuthDecode = jwt_decode(token);
    //const param = props.data;
    // const acadAct = (param) ? param.Acad : {};
    // const [acad, setAcad] = useState();

    // useEffect(() => {
    //     if (param.RoleID === 1) {
    //         const acadType = (param) ? param.AcadType : '';
    //         var selectIndex = (acadAct) ? (acadAct.findIndex(v => v.atp_group_type.id === acadType)) : 0;
    //         if (selectIndex !== '-1') {
    //             setAcad(acadAct[selectIndex]);
    //         }
    //     }
    // })
    const [stdStatus, setStdStatus] = useState({ loading: false, data: [], message: "" });
    const GET_STUDENT_STATUS = () => {
        setStdStatus({ loading: true, data: [], message: "" })
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };
        var body = {
            parameters: {
                studentid: AuthDecode.currentData.entityid,
            },
            target_data: "GET_STUDENT_STATUS",
        };
        const postData = {
            keys: keys,
            body: body,
        };

        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                setStdStatus({ loading: false, data: [], message: response.error })
            } else if ("message" in response.response) {
                setStdStatus({ loading: false, data: [], message: response.response.message.user })
            } else {
                setStdStatus({ loading: false, data: response.response.data, message: "" })
            }
        });
    }

    useEffect(() => {
        if (AuthDecode.currentData.roleid === 1) {
            GET_STUDENT_STATUS();
        }
    }, []);

    return (
        <div id="kt_header" className="header header-fixed">
            <div className="container-fluid d-flex align-items-stretch justify-content-between">
                <div className="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper">
                    <div id="kt_header_menu" className="header-menu header-menu-mobile header-menu-layout-default">
                        <ul className="menu-nav">
                            {(AuthDecode.currentData.roleid === 1) ? (
                                <StudentAcad stdStatus={stdStatus} />
                            ) : <NavEmp />}
                        </ul>
                    </div>
                </div>

                <div className="topbar">
                    <div className="dropdown">
                        <div className="topbar-item">
                            {AuthDecode.TypeCK === "development" && (
                                <ModeDev name={AuthDecode.TypeCK} />
                            )}
                        </div>
                        <div className="topbar-item" data-toggle="dropdown" data-offset="0px,0px">
                            <div className="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2">
                                <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
                                <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{AuthDecode.identity.given_name}</span>
                                <span className="symbol symbol-lg-35 symbol-25 symbol-light-success">
                                    <span className="symbol-label font-size-h5 font-weight-bold">{AuthDecode.identity.given_name.charAt(0).toUpperCase()}</span>
                                </span>
                            </div>
                        </div>
                        <div className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0">
                            <div className="d-flex align-items-center p-8 rounded-top">
                                <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                                    <img src={UserPicBoy} alt={"pic-" + AuthDecode.identity.given_name} className="p-1" />
                                </div>
                                <div className="d-flex flex-column">
                                    <Link to="/profile" title="View profile" className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary text-capitalize">
                                        {AuthDecode.identity.given_name + " " + AuthDecode.identity.family_name}
                                    </Link>
                                    <div className="text-muted mt-1 text-capitalize">{AuthDecode.identity.role}</div>
                                </div>
                            </div>
                            <div className="separator separator-solid"></div>
                            <div className="navi navi-spacer-x-0 pt-0">
                                <div className="navi-footer">
                                    <Link to="/sign-out" className="btn btn-light-primary font-weight-bold">Sign Out</Link>
                                    <a href="https://wa.me/message/NNSYT7PGEZM5E1" target="_blank" className="btn btn-clean font-weight-bold">Change Password</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

const ModeDev = ({ name }) => {
    return (
        <div
            className="topbar-item mr-10"
            title={"Consumer Key for " + name}
        >
            <div className="btn-group">
                <div className="btn btn-info btn-icon btn-sm">
                    <span className="svg-icon menu-icon">
                        <svg
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                        >
                            <g
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                            >
                                <polygon points="0 0 24 0 24 24 0 24" />
                                <path
                                    d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z"
                                    fill="#000000"
                                    fillRule="nonzero"
                                />
                                <path
                                    d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z"
                                    fill="#000000"
                                    opacity="0.3"
                                />
                            </g>
                        </svg>
                    </span>
                </div>
                <div className="btn btn-light-info btn-sm font-size-sm">
                    <span className="text-uppercase">{name}</span>
                </div>
            </div>
        </div>
    )
}

const NavEmp = () => {
    return (
        <ul className="menu-nav ">
            <li className="menu-item font-weight-bold text-dark">
                <Link to="/calendar-timetable" className="menu-link text-dark-50 text-hover-primary d-flex align-items-center">
                    <i className="icon-1 text-dark-50 flaticon2-calendar-9"></i>
                    <span className="ml-1">Calendar Timetable</span>
                </Link>
            </li>
            <li className="menu-item  font-weight-bold">
                <Link to="/find-student" className="menu-link text-dark-50 text-hover-primary d-flex align-items-center">
                    <i className="icon-1 text-dark-50 flaticon2-search-1"></i>
                    <span className='ml-1'>Find Student</span>
                </Link>
            </li>
            <li className="menu-item  font-weight-bold">
                <a href="https://drive.google.com/drive/u/0/folders/1fxdSxrtCHuwJRDwniiilHna87qg7Mmiv" target='_blank' className="menu-link text-dark-50 text-hover-primary d-flex align-items-center">
                    <i className="icon-1 text-dark-50 flaticon2-open-text-book"></i>
                    <span className='ml-1'>Curriculum</span>
                </a>
            </li>
        </ul>
    )
}

const StudentAcad = ({ stdStatus }) => {
    if (stdStatus.loading) {
        return (
            <ul className="menu-nav ">
                <li className="menu-item menu-item-submenu menu-item-rel menu-item-active">
                    <LoadingBtn />
                </li>
            </ul>
        )
    } else if (stdStatus.message) {
        return (
            <ul className="menu-nav ">
                <li className="menu-item menu-item-submenu menu-item-rel menu-item-active">
                    <div className="alert alert-custom alert-light-danger py-1 px-5" role="alert">
                        <div className="alert-text">
                            <div className="d-flex align-items-center">
                                <i className="flaticon-warning text-danger font-weight-bold"></i>
                                <span className="mx-2 font-weight-bold">{stdStatus.message}</span>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        );
    } else if (Object.keys(stdStatus.data).length > 0) {
        return (
            <ul className="menu-nav ">
                <li className="menu-item menu-item-submenu menu-item-rel menu-item-active">
                    <span className="menu-link bg-light-primary text-primary">
                        <span className="menu-text text-primary"><i className="fas fa-tag text-primary mr-2"></i> Program  {(stdStatus.data.program) ? stdStatus.data.program.name : "-"}</span>
                    </span>
                </li>
                <li className="menu-item menu-item-submenu menu-item-rel menu-item-active">
                    <span className="menu-link bg-light-primary text-primary">
                        <span className="menu-text text-primary"><i className="fas fa-tag text-primary mr-2"></i> Academic Calendar: {(stdStatus.data.atp_term) ? stdStatus.data.atp_term.name : "-"} </span>
                    </span>
                </li>
            </ul>
        )
    } else {
        return "";
    }
}