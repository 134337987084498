import React from 'react'
import AxiosLib from '../../../Library/AxiosLib';
import { useState } from 'react';
import { useEffect } from 'react';

export default function Filter({findUserAccount}) {

    const [postFilter, setPostFilter] = useState({ Type: 0, EntityID: "", EntityName: '' });
    const [placeholderText, setPlaceholderText] = useState("entity id");
    const [messageError, setMessageError] = useState("");

    const [typeRole, setTypeRole] = useState({loading:false, data:[], message:""});

    const TypeUserRole = (param) => {
        setTypeRole({loading:true, data:[], message:""})
        AxiosLib(param, "/mc/api/fetch-type-user").then((response) => {
            if (response.error) {
                setTypeRole({loading:false, data:[], message:response.error})
            } else {
                const remove_staff = response.data.filter(item => item.ID !== "3");
                setTypeRole({loading:false, data:remove_staff, message:""})
            }
        });
    };

    useEffect(()=>{
        TypeUserRole({ IsActive: 1 });
    },[])

    const handlerTypeUser = (value) => {
        value = parseInt(value);
        var obj = { Type: value, EntityID: "", EntityName: "" };
        if (value !== 0) {
            if (value === 1) {
                setPlaceholderText("student name or student id");
            } else if (value === 2 || value === 3 || value === 4) {
                setPlaceholderText("Name");
            }
            setMessageError('');
            setPostFilter(obj);
        } else {
            setMessageError("Please fill up the form");
        }
    }

    const handlerEntityID = (value) => {
        if (value) {
            if (postFilter.Type === 2 || postFilter.Type === 3 || postFilter.Type === 4) {
                setPostFilter({ ...postFilter, EntityID: "", EntityName: value })
            } else {
                setPostFilter({ ...postFilter, EntityID: value, EntityName: '' })
            }
            setMessageError('');
        } else {
            setPostFilter({ ...postFilter, EntityID: "", EntityName: '' })
        }
    }

    const handlerSubmitFilter = (e) =>{
        e.preventDefault();
        if(postFilter.Type && (postFilter.EntityID !== "" || postFilter.EntityName !== "")){
            findUserAccount(postFilter);
        }else{
            setMessageError("Please fill up the form");
        }
    }

    return (
        <div className='card card-custom'>
            <div className="card-body">
                <h3 className='mb-5'>Form search user</h3>
                <form method='post' autoComplete='off' id="form-remote" onSubmit={(e)=>handlerSubmitFilter(e)}>
                    <div className="form-group">
                        <label className='text-uppercase font-weight-bolder'>Type</label>
                        <select name="Type" className='form-control required' onChange={(e) => handlerTypeUser(e.target.value)}>
                            <option value="0">Choose One</option>
                            {(Object.values(typeRole.data).length > 0) ? (
                                typeRole.data.map((v, index) => (
                                    <option key={index} value={v.ID}>{v.Name}</option>
                                ))
                            ) : ''}
                        </select>
                    </div>
                    <div className="form-group text-capitalize">
                        <label className='text-uppercase font-weight-bolder'>{placeholderText}</label>
                        <input type="text" name="EntityID" placeholder={'Enter ' + placeholderText} className='form-control required' value={(postFilter.Type === 1) ? postFilter.EntityID : ((postFilter.Type === 2 || postFilter.Type === 3 || postFilter.Type === 4) ? postFilter.EntityName : "") } onChange={(e) => handlerEntityID(e.target.value)} />
                        <small className="text-danger">{messageError}</small>
                    </div>

                    <div className="btn-group">
                        <button className="btn btn-primary" type='submit'>Search</button>
                    </div>

                </form>
            </div>
        </div>
    )
}
