import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import AvatarBoy from "../../../../media/avatars/001-boy.svg";
import AvatarGirl from "../../../../media/avatars/002-girl.svg";
import AxiosLibWithHeader2 from "../../../Library/AxiosLibWithHeader2";
import LoadingBtn from "../../Widgets/LoadingBtn";
import { AlertNotif } from "../../../Library/AlertNotif";
import { StudentIDFormat } from "../../../Library/GlobalHelper";

export default function CardProfile({ studentid }) {
  var AuthToken = localStorage.getItem("AuthToken");
  const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

  const [deptName, setDeptName] = useState("-");
  const [stdProfile, setStdProfile] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const GET_STUDENT_DATA_M = () => {
    setStdProfile({ ...stdProfile, loading: true, data: [], message: "" });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: {
        studentid: studentid,
      },
      target_data: "GET_STUDENT_DATA_M",
    };
    const postData = {
      keys: keys,
      body: body,
    };
    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setStdProfile({
          ...stdProfile,
          loading: false,
          data: [],
          message: response.error,
        });
      } else if ("message" in response.response) {
        var msg = response.response.message.user;
        setStdProfile({
          ...stdProfile,
          loading: false,
          data: [],
          message: msg,
        });
      } else {
        var results = response.response.data;
        if (results) {
          setStdProfile({
            ...stdProfile,
            loading: false,
            data: results,
            message: "",
          });
        } else {
          setStdProfile({
            ...stdProfile,
            loading: false,
            data: [],
            message: "No record found",
          });
        }
      }
    });
  };

  const [stdStatus, setStdStatus] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const GET_STUDENT_STATUS = () => {
    setStdStatus({ ...stdStatus, loading: true, data: [], message: "" });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: {
        studentid: studentid,
      },
      target_data: "GET_STUDENT_STATUS",
    };
    const postData = {
      keys: keys,
      body: body,
    };
    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setStdStatus({
          ...stdStatus,
          loading: false,
          data: [],
          message: response.error,
        });
      } else if ("message" in response.response) {
        var msg = response.response.message.user;
        setStdStatus({ ...stdStatus, loading: false, data: [], message: msg });
      } else {
        var results = response.response.data;
        if (results) {
          setStdStatus({
            ...stdStatus,
            loading: false,
            data: results,
            message: "",
          });
          let deptArr = results.clu.name.split("-");
          let deptName = deptArr[Object.values(deptArr).length - 1] ? deptArr[Object.values(deptArr).length - 1] : "-"
          setDeptName(deptName);
          const objParam = {
            studentid: results.studentid,
            atpid: results.atp_term.id
          }
          GET_STUDENT_COURSE(objParam);
        } else {
          setStdStatus({
            ...stdStatus,
            loading: false,
            data: [],
            message: "No record found",
          });
        }
      }
    });
  };

  const [fpcf, setFpcf] = useState({ loading: false, data: [], message: "" });
  const [sumSKS, setSumSKS] = useState(0);
  const GET_STUDENT_COURSE = (param) => {
      setFpcf({ loading: true, data: [], message: "" });
      var keys = {
          AppId: AuthDecode.AppID,
          AppEnv: AuthDecode.AppEnv,
          Secretkey: AuthDecode.secretkey,
      };
      var body = {
          parameters: param,
          target_data: "GET_STUDENT_COURSE",
      };
      const postData = {
          keys: keys,
          body: body,
      };

      AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
          if (response.error) {
              setFpcf({ loading: false, data: [], message: response.error });
          } else if ("message" in response.response) {
              setFpcf({ loading: false, data: [], message: response.response.message.user });
          } else {
              var results = response.response.data;
              if (results) {
                  var total = results.reduce((a, v) => a = a + ((v.status === 'ACTIVE' && v.course.course_type !== "asc.course.type.optional") ? v.credit : 0), 0);
                  setSumSKS(total);
                  setFpcf({ loading: false, data: results, message: "" });
              } else {
                  setFpcf({ loading: false, data: results, message: "No record found" });
              }
          }
      });
  }

  useEffect(() => {
    GET_STUDENT_DATA_M();
    GET_STUDENT_STATUS();
  }, []);


  return (
    stdProfile.loading || stdStatus.loading ? (
      <LoadingBtn />
    ) : stdProfile.message || stdStatus.message ? (
      <AlertNotif
        message={stdProfile.message || stdStatus.message}
        color={"danger"}
      />
    ) : Object.values(stdProfile.data).length > 0 &&
      Object.values(stdStatus.data).length > 0 ? (
      <div className="student-informasi">
        <div className="d-flex mb-9">
          <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
            <div className="symbol symbol-150 symbol-primary">
              <img
                src={stdProfile.data.gender === "p" ? AvatarBoy : AvatarGirl}
                alt={stdProfile.data.fullname}
                className="bg-light-primary"
              />
            </div>
          </div>

          <div className="flex-grow-1">
            {/* TITLE */}
            <div className="d-flex justify-content-between flex-wrap mt-1">
              <div className="d-flex mr-3">
                <p className="text-dark-75 font-size-h1 font-weight-bold mr-3 mb-0">
                  {stdProfile.data.fullname}
                </p>
                <span className="">
                  <i
                    className={
                      "font-size-h5 " +
                      (Object.values(stdStatus.data).length > 0
                        ? stdStatus.data.status.id ===
                          "asc.student.status.notactive"
                          ? "flaticon2-cancel text-danger"
                          : "flaticon2-correct  text-success"
                        : "flaticon2-correct text-succes")
                    }
                  ></i>
                </span>
              </div>
              <div className="my-lg-0 my-3">
                <button className="btn btn-light btn-sm text-uppercase text-primary font-weight-bolder" type="button">
                  Status {stdStatus.data.status ? stdStatus.data.status.name : "-"}
                </button>
              </div>
            </div>
            {/* CONTENT */}
            <div className="mt-1">
              <div className="d-flex flex-wrap mb-2">
                <span className="text-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                  <i className="flaticon2-calendar-3 mr-1 font-size-lg text-primary"></i>{" "}
                  <span className="font-size-lg">
                    {stdStatus.data.studentid && StudentIDFormat(stdStatus.data.studentid)}
                  </span>
                </span>
                <span className="text-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                  <i className="flaticon2-new-email mr-1 font-size-lg text-primary"></i>{" "}
                  <span className="font-size-lg">
                    {stdProfile.data.sgumail}
                  </span>
                </span>
                <span className="text-primary font-weight-bold">
                  <i className="socicon-whatsapp mr-1 font-size-lg text-primary"></i>{" "}
                  <span className="font-size-lg">
                    {stdProfile.data.cellular ? stdProfile.data.cellular : "-"}
                  </span>
                </span>
              </div>

              <div className="d-flex flex-wrap justify-content-between mt-1">
                <div className="d-flex flex-grow-1 pr-8">
                  <BoxDashed title={stdStatus.data.program.name} value={deptName} />
                  <BoxDashed title={"Current GPA"} value={stdStatus.data.cgpa ? stdStatus.data.cgpa : "0"} selector={"mx-2"} />
                  <BoxDashed title={"Current Semester"} value={(stdStatus.data.semester ? stdStatus.data.semester : "0")} selector={"mx-2"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      ""
    )
  );
}

const BoxDashed = ({ value, title, selector="" }) => {
  return (
    <div className={"rounded py-3 px-3 min-w-80px " + selector} style={{ border: "1px dashed #EBEDF3" }}>
      <div className="h4 font-weight-bolder text-gray-700 text-center">
        <span className="w-75px ">{value}</span>
      </div>
      <div className="font-weight-bold text-muted">{title}</div>
    </div>
  )
}