import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import {
  Dashboard,
  MADashboard,
  AtdClassList,
  DetailClassList,
  Reporting,
  StudentProfile,
  ThesisScore,
  ThesisSchema,
  ThesisActivityLCT,
  ThesisActivityLCTDetail,
  BrowseStudent,
} from "../Page/Lecturer";
import Profiles from "../Page/Profiles";
import RemoteUser from "../Page/Staff/Remote-User-New";
import Regulations from "../Page/Staff/RegulationsForm";
import FormRegulations from "../Page/Staff/RegulationsForm/Form/FormRegulations";
import ServiceRequest from "../Page/Widgets/ServiceRequest/ServiceRequest";
import {
  CounselingLect,
  DetailStudent,
  CAcadStd,
  CBioStd,
  CCPStd,
  CActivityStd,
  DetailActivity,
} from "../Page/Lecturer/Counseling-new";
import { Announcements, DetailAnnouncement } from "../Page/Staff/Announcements";
import {
  SubmitScoring,
  ReportScore,
  SchemaScore,
} from "../Page/Lecturer/ScoreandGrade";
import { CalendarTimetables } from "../Page/Staff/CalendarTimetables/CalendarTimetables";

export function RouteLecturer(props) {
  const routers = props.data;
  const COMPONENT_MAP = {
    Dashboard: Dashboard,
    Profiles: Profiles,
    MADashboard: MADashboard,

    AtdClassList: AtdClassList,
    DetailClassList: DetailClassList,
    Reporting: Reporting,

    SubmitScoring: SubmitScoring,
    ReportScore: ReportScore,
    SchemaScore: SchemaScore,

    ThesisScore: ThesisScore,
    ThesisSchema: ThesisSchema,
    ThesisActivityLCT: ThesisActivityLCT,
    ThesisActivityLCTDetail: ThesisActivityLCTDetail,

    RemoteUser: RemoteUser,
    Regulations: Regulations,
    FormRegulations: FormRegulations,
    ServiceRequest: ServiceRequest,

    CounselingLect: CounselingLect,
    DetailStudent: DetailStudent,
    CAcadStd: CAcadStd,
    CBioStd: CBioStd,
    CCPStd: CCPStd,
    CActivityStd: CActivityStd,
    DetailActivity: DetailActivity,

    Announcements: Announcements,
    DetailAnnouncement: DetailAnnouncement,

    BrowseStudent: BrowseStudent,
    StudentProfile: StudentProfile,
  };
  const routeList = [];
  if (Object.keys(routers).length > 0) {
    for (let index = 0; index < routers.Academic.length; index++) {
      routeList.push(routers.Academic[index]);
    }
    for (let index = 0; index < routers.Config.length; index++) {
      routeList.push(routers.Config[index]);
    }
  }
  return (
    <Suspense fallback={<h3>Loading..</h3>}>
      <Route path="/" component={COMPONENT_MAP["Dashboard"]} exact />
      <Route path="/dashboard" component={COMPONENT_MAP["Dashboard"]} />
      <Route path="/profile" component={COMPONENT_MAP["Profiles"]} />

      <Route
        path="/detail/attendance/:token"
        component={COMPONENT_MAP["DetailClassList"]}
      />

      <Route
        path="/regulations-form/:token"
        component={COMPONENT_MAP["FormRegulations"]}
      />


      {/* NEW FEBRY */}
      <Route
        path="/calendar-timetable"
        component={CalendarTimetables}
      />
      <Route
        path="/find-student"
        component={COMPONENT_MAP["BrowseStudent"]}
      />
      <Route
        path="/student/:stdid"
        component={COMPONENT_MAP["StudentProfile"]}
      />
      {/* END FEBRY */}

      {/* Ammar */}
      <Route path="/thesis/activity-detail" component={COMPONENT_MAP["ThesisActivityLCTDetail"]} />
      {/* End Ammar */}

      {/* TEDY */}
      {/* <Route path="/thesis-defense" component={COMPONENT_MAP["ThesisScore"]} /> */}
      <Route path="/thesis/thesis-scheme" component={COMPONENT_MAP["ThesisSchema"]} />
      {/* END TEDY */}

      <Route
        path="/counseling"
        render={({ match: { url } }) => (
          <>
            <Route
              path={`${url}/`}
              component={COMPONENT_MAP["CounselingLect"]}
              exact
            />
            <Route
              path={`${url}/student/:stdid`}
              component={COMPONENT_MAP["DetailStudent"]}
            />
            <Route
              path={`${url}/student/:stdid/academic`}
              component={COMPONENT_MAP["CAcadStd"]}
            />
            <Route
              path={`${url}/student/:stdid/biodata`}
              component={COMPONENT_MAP["CBioStd"]}
            />
            <Route
              path={`${url}/student/:stdid/course-plan`}
              component={COMPONENT_MAP["CCPStd"]}
            />
            <Route
              path={`${url}/student/:stdid/activity`}
              component={COMPONENT_MAP["CActivityStd"]}
              exact
            />
            <Route
              path={`${url}/student/:stdid/activity/:token`}
              component={COMPONENT_MAP["DetailActivity"]}
            />
          </>
        )}
      />
      <Route
        path="/score-and-grade"
        render={({ match: { url } }) => (
          <>
            <Route
              path={`${url}/scoring-sheet/:token`}
              component={COMPONENT_MAP["SchemaScore"]}
            />
          </>
        )}
      />

      <Route
        path="/announcements"
        render={({ match: { url } }) => (
          <>
            <Route
              path={`${url}/`}
              component={COMPONENT_MAP["Announcements"]}
              exact
            />
            <Route
              path={`${url}/detail/:id`}
              component={COMPONENT_MAP["DetailAnnouncement"]}
            />
          </>
        )}
      />

      {Object.keys(routeList).length > 0
        ? routeList.map((v) =>
          Object.keys(v.Child).length > 0 ? (
            <Route
              key={v.ID}
              path={v.PathURI}
              render={({ match: { url } }) => (
                <>
                  <Route
                    key={v.ID}
                    path={`${url}/`}
                    component={COMPONENT_MAP[v.Slug]}
                    exact
                  />
                  {v.Child.map((k) => (
                    <>
                      <Route
                        key={v.ID}
                        path={`${url + k.URI}`}
                        component={COMPONENT_MAP[k.Slug]}
                      />
                    </>
                  ))}
                </>
              )}
            />
          ) : (
            <Route
              key={v.ID}
              path={v.PathURI}
              component={COMPONENT_MAP[v.Slug]}
            />
          )
        )
        : ""}
    </Suspense>
  );
}

export default RouteLecturer;
