import React, { useEffect, useState } from 'react'
import jwt_decode from "jwt-decode";
import Moment from "moment";
import LoadingBtn from '../../../../Widgets/LoadingBtn';
import { AlertNotif } from '../../../../../Library/AlertNotif';
import AxiosLibWithHeader2 from '../../../../../Library/AxiosLibWithHeader2';

export default function DetailSchedule(props) {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

    const data = (props.data) ? props.data : null;
    const groupData = JSON.parse(data.groupId);

    const [currSection, setCurrSection] = useState({
        data: [],
        message: "",
        loading: false
    });

    const GET_SECTION = (section_id) => {
        setCurrSection({ ...currSection, loading: true, data: [], message: "" });
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };
        var body = {
            parameters: {
                section_id: section_id,
            },
            target_data: "GET_SECTION",
        };
        const postData = {
            keys: keys,
            body: body,
        };
        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                setCurrSection({ ...currSection, message: response.error, loading: false, data: [] });
            } else if ("message" in response.response) {
                var msg = response.response.message.user;
                setCurrSection({ ...currSection, message: msg, loading: false, data: [] });
            } else {
                var results = response.response.data;
                if (results) {
                    setCurrSection({ ...currSection, message: "", loading: false, data: results });
                } else {
                    setCurrSection({ ...currSection, message: "No record found", loading: false, data: [] });
                }
            }
        });
    };

    useEffect(() => {
        if (!groupData.grouptype) {
            GET_SECTION(data.id);
        }
    }, [])

    return (
        <div className="info">
            <div className="table-responsive">
                <table className="table table-head-custom table-head-bg table-borderless table-vertical-start">
                    <thead>
                        <tr className='text-left text-uppercase'>
                            <th colSpan={2}>
                                <span className="text-dark">Detail Schedule</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {groupData.is_cancel ? (
                            <tr>
                                <td colSpan={3} className='text-center bg-danger text-white text-uppercase'>
                                    Class is CANCEL
                                </td>
                            </tr>
                        ) : ""}
                        {!groupData.grouptype && (
                            <tr>
                                <td className='font-weight-bolder'>
                                    Section ID
                                </td>
                                <td>
                                    {data.id}
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td className='font-weight-bolder'>
                                {!groupData.grouptype ? "Course":"Event Name"}
                            </td>
                            <td>
                                {data.title}
                            </td>
                        </tr>
                        {!groupData.grouptype && (
                            <tr>
                                <td className='font-weight-bolder'>
                                    Room
                                </td>
                                <td>
                                    {(groupData.event_room) ? (
                                        groupData.event_room.map((v, index) => (
                                            <span key={index}>{v.room ? v.room.g_resourceName : "-"}</span>
                                        ))
                                    ) : ""}
                                </td>
                            </tr>
                        )}

                        <tr>
                            <td className='font-weight-bolder'>
                                Date and Time
                            </td>
                            <td>
                                {Moment(data.start).format('D MMMM YYYY')},
                                {Moment(data.start).format('hh:mm a')} - {Moment(data.end).format('hh:mm a')}
                            </td>
                        </tr>
                        {!groupData.grouptype && (
                            !groupData.is_cancel ? (
                                <>
                                    <tr>
                                        <td className='font-weight-bolder'>Class Room</td>
                                        <td>
                                            {currSection.loading ? <LoadingBtn /> : (
                                                currSection.message ? <AlertNotif message={currSection.message} color={"danger"} title={"Information"} /> : (
                                                    Object.values(currSection.data).length > 0 ? (
                                                        currSection.data.map((v, index) => (
                                                            (v.g_calendarId ? (
                                                                <p className='mb-0' key={index}>
                                                                    <a href={v.g_alternateLink} target='_blank'>Click here to visit Google Class Room</a>
                                                                </p>
                                                            ) : "")
                                                        ))
                                                    ) : "-"
                                                )
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            {currSection.loading ? <LoadingBtn /> : (
                                                currSection.message ? <AlertNotif message={currSection.message} color={"danger"} title={"Information"} /> : (
                                                    Object.values(currSection.data).length > 0 ? (
                                                        currSection.data.map((v, index) => (
                                                            (v.g_calendarId ? (
                                                                <div key={index} className='bg-light-primary p-3 rounded'>
                                                                    <p className="mb-0 font-weight-bolder">Notes:</p>
                                                                    <p className="mb-0">
                                                                        To display this course schedule in your Google Calendar, please subscribe by copying this <span className="font-weight-bolder">Calendar ID </span>
                                                                        (<span className="font-weight-bolder text-primary mx-2">{v.g_calendarId}</span>), and then follow the instructions provided <a href='https://support.google.com/calendar/answer/37100?hl=en&co=GENIE.Platform%3DDesktop' target='_blank'>here</a>.
                                                                    </p>
                                                                </div>
                                                            ) : "")
                                                        ))
                                                    ) : "-"
                                                )
                                            )}
                                        </td>
                                    </tr>
                                </>
                            ) : ""
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
