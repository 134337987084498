import React, { useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import "./detail.css";
import { useHistory, useLocation } from "react-router-dom";
import Tooltip from "../components/Tooltip";
import Card from "../components/Card";
import CardCircular from "../components/CardCircular";
import AdvisorNote from "../components/AdvisorNote";
import { dataFetcher, formatDate, safeDecode } from "../components/helper";
import ApprovalModal from "../components/ApprovalModal";
import NoData from "../components/NoData";
import Loading from "../components/Loading";

const Detail = () => {
  const navigation = useHistory();
  const location = useLocation();
  const { id, approved_act_cadv } = location.state.state;

  const [logDatas, setLogDatas] = useState({});
  const [progressCircularCount, setProgressCircularCount] = useState({
    adv: 0,
    co: 0,
  });
  const [openDialogNote, setOpenDialogNote] = useState(-1);
  const [dialogApproval, setDialogApproval] = useState({
    state: false,
    id: -1,
  });
  const [state, setTheState] = useState({});
  const [logParam, setLogParam] = useState({});

  const Action = ({ title, id }) => {
    return (
      <div className="flex-shrink-0">
        {title === "SUBMITTED" ? (
          <div
            class="label label-xl label-primary text-white cursor-pointer label-inline w-100"
            onClick={() => setDialogApproval({ state: true, id: id })}
          >
            Approval
          </div>
        ) : (
          <div
            class={`label label-xl label-inline w-100 text-capitalize
            ${title === "APPROVED" && "label-light-success"} ${
              title === "REJECTED" && "label-light-danger"
            }`}
          >
            {title === "APPROVED" ? "Approved" : "Rejected"}
          </div>
        )}
      </div>
    );
  };

  // get advisor
  const getDetailStudent = async () => {
    setTheState({ loading: true, data: [], message: "" });

    const body = {
      target_data: "GET_THS_BY_ADVISOR",
    };
    const response = await dataFetcher(body);
    console.log("respon GET_THS_BY_ADVISOR ", response);
    if (response.message === "") {
      const [z] = response.data.filter((x) => x.studentid === id);
      console.log("zzz ", z);
      setTheState({ loading: response.loading, data: z, message: "" });
      setLogParam({
        student: z.studentdata.sgumail,
        adv: z.advisor.email,
        co: z.coadvisor?.email ?? null,
      });
      getLog(
        z.studentdata.sgumail,
        z.advisor.email,
        z.coadvisor?.email ?? null
      );
      setProgressCircularCount({
        adv: z.approved_act_adv,
        co: z.approved_act_cadv,
      });
    } else {
      setTheState({
        loading: response.loading,
        data: [],
        message: response.message,
      });
    }
  };

  // get log student
  const getLog = async (sgumail, advEmail, coEmail) => {
    if (coEmail === undefined) coEmail = logParam.co;
    setLogDatas({ loading: true, data: [], message: "" });

    const body = {
      parameters: {
        type: "act.type.thesis.activity",
        owner: sgumail ?? logParam.student,
      },
      target_data: "GET_ACTIVITY_BY_APPROVER",
    };
    const response = await dataFetcher(body);
    console.log("log ", response);
    const reverse = response.data.reverse();
    setLogDatas({
      loading: response.loading,
      data: reverse,
      message: response.message,
    });
  };

  useEffect(() => {
    getDetailStudent();
    // getLog();
  }, []);

  return (
    <div>
      <div className="d-flex align-items-baseline mb-10">
        <div title="back" onClick={() => navigation.push("/thesis/activity")}>
          <i className="icon-md text-dark flaticon2-left-arrow-1 cursor-pointer"></i>
        </div>
        <h5 className="ml-5">Detail Student</h5>
      </div>

      {/* student */}
      {state.loading ? (
        <Loading />
      ) : (
        <>
          <div className="row mb-5">
            <div className="col">
              <div className="h-md bg-white p-5 rounded">
                <div className="card-body p-0">
                  <div className="card-p">
                    <div className="d-flex">
                      <div
                        className="bg-light-primary rounded d-flex justify-content-center align-items-center mr-5"
                        style={{ height: 120, width: 120 }}
                      >
                        <p style={{ fontSize: 48, fontWeight: 500 }}>
                          {state?.data?.studentdata?.fullname[0]}
                        </p>
                      </div>
                      <div className="d-flex flex-column justify-content-between">
                        <h5
                          className="p-0 m-0 flex-shrink-0"
                          style={{ fontSize: 24, fontWeight: 500 }}
                        >
                          {state?.data?.studentdata?.fullname}
                        </h5>
                        <div className="d-flex">
                          <p
                            className="p-0 m-0"
                            style={{ fontSize: 16, fontWeight: 500 }}
                          >
                            {state?.data?.studentdata?.clu_spec?.name ?? "-"}
                          </p>
                          <p
                            className="py-0 px-10 m-0"
                            style={{ fontSize: 16, fontWeight: 500 }}
                          >
                            {state?.data?.studentdata?.sgumail}
                          </p>
                          <p
                            className="p-0 m-0"
                            style={{ fontSize: 16, fontWeight: 500 }}
                          >
                            {state?.data?.user?.cellular}
                          </p>
                        </div>
                        <p
                          className="p-0 m-0"
                          style={{ fontSize: 16, fontWeight: 500 }}
                        >
                          {state?.data?.studentdata?.clu?.name}
                        </p>
                        <p
                          className="p-0 m-0"
                          style={{ fontSize: 16, fontWeight: 500 }}
                        >
                          {state?.data?.studentid}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-xl-6 mb-sm-5">
              <div className="row h-100">
                <div className="col-12 mb-5">
                  <Card
                    title="Thesis title"
                    name={state?.data?.final_title}
                    icon="t"
                  />
                </div>
                <div className="col-6">
                  <Card
                    title="Advisor"
                    name={state?.data?.advisor?.fullname}
                    icon="a"
                  />
                </div>
                <div className="col-6">
                  <Card
                    title="Co-Advisor"
                    name={
                      state?.data?.coadvisor?.fullname === undefined
                        ? "-"
                        : state?.data?.coadvisor?.fullname
                    }
                    icon="a"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="row h-100">
                <div className="col-6">
                  <CardCircular
                    isCoExist={state?.data?.coadvisor?.fullname !== undefined}
                    title="Advisory Meetings with Advisor"
                    meet={progressCircularCount.adv}
                  />
                </div>
                <div className="col-6">
                  <CardCircular
                    title="Advisory Meetings with Co-Advisor"
                    meet={
                      state?.data?.coadvisor?.fullname === undefined
                        ? -1
                        : progressCircularCount.co
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          {/* advisory log */}

          <section className=" mb-10 bg-white mx-1 p-5 rounded">
            <div className="d-flex justify-content-between align-items-center my-5">
              <h2 style={{ fontFamily: "'Roboto', sans-serif" }}>
                Advisory Log
              </h2>
            </div>
            {logDatas.loading ? (
              <Loading />
            ) : logDatas.message !== "" ? (
              <NoData msg={logDatas.message} sbj="" />
            ) : (
              <div className="table-responsive px-5 border-bottom border-b-2">
                <table
                  className="table table-row-bordered table-row-gray-300"
                  style={{ fontFamily: "'Roboto', sans-serif" }}
                >
                  <thead>
                    <tr className="text-gray-800">
                      <th
                        className="text-muted ps-5"
                        style={{ letterSpacing: "0.05rem" }}
                      >
                        NO
                      </th>
                      <th
                        className="text-muted"
                        style={{ letterSpacing: "0.05rem" }}
                      >
                        DATE
                      </th>
                      <th
                        className="text-muted"
                        style={{ letterSpacing: "0.05rem" }}
                      >
                        ACTIVITY DESCRIPTION
                      </th>
                      <th
                        className="text-muted text-center"
                        style={{ letterSpacing: "0.05rem" }}
                      >
                        ATTACHMENT
                      </th>
                      <th
                        className="text-muted text-center"
                        style={{ letterSpacing: "0.05rem" }}
                      >
                        ADVISOR
                      </th>
                      <th
                        className="text-muted text-center"
                        style={{ letterSpacing: "0.05rem" }}
                      >
                        ACTION
                      </th>
                      <th
                        className="text-muted text-center"
                        style={{ letterSpacing: "0.05rem" }}
                      >
                        NOTE
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {logDatas?.data?.map((log, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            fontSize: 14,
                            fontFamily: "'Roboto', sans-serif",
                            // paddingRight: 50,
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          className="text-nowrap"
                          style={{
                            fontSize: 14,
                            paddingRight: 50,
                            width: 100
                          }}
                        >
                          {formatDate(log.act_date)}
                        </td>
                        <td
                          style={{
                            fontSize: 14,
                            whiteSpace: "pre-line",
                            fontFamily: "'Roboto', sans-serif",
                            // paddingRight: 50,
                            width: 600,
                          }}
                        >
                          {log.descr}
                        </td>
                        <td
                          className="text-center"
                          style={{ paddingLeft: 25, paddingRight: 25 }}
                        >
                          {log.file_ref !== null ? (
                            <Tooltip title="See attachment">
                              {/* <ScrollText className="text-primary" /> */}
                              <i className="icon-xl text-primary flaticon2-paper"></i>
                            </Tooltip>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td
                          className="text-nowrap text-center"
                          style={{
                            fontSize: 14,
                            fontFamily: "'Roboto', sans-serif",
                            paddingLeft: 25,
                            paddingRight: 25,
                          }}
                        >
                          {log.target_user.fullname}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            fontSize: 14,
                            fontFamily: "'Roboto', sans-serif",
                            paddingLeft: 25,
                            paddingRight: 25,
                          }}
                        >
                          <Action title={log.status} id={log.id} />
                        </td>
                        <td
                          className="text-center"
                          style={{
                            fontSize: 14,
                            fontFamily: "'Roboto', sans-serif",
                            paddingLeft: 25,
                            paddingRight: 25,
                          }}
                        >
                          {log.remark !== "" && log.remark !== null ? (
                            <>
                              <Tooltip title="See Notes">
                                <i
                                  className="icon-lg text-primary flaticon2-paper cursor-pointer "
                                  onClick={() => setOpenDialogNote(index)}
                                ></i>
                              </Tooltip>
                              {openDialogNote === index && (
                                <AdvisorNote
                                  note={log.remark}
                                  close={setOpenDialogNote}
                                />
                              )}
                            </>
                          ) : (
                            <p>-</p>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            {/* Modal */}
            {dialogApproval.state && (
              <ApprovalModal
                id={dialogApproval.id}
                closeState={setDialogApproval}
                refetchLog={getDetailStudent}
              />
            )}
          </section>
        </>
      )}
    </div>
  );
};

export default Detail;
