import React from "react";

const Rejected = () => {
  return (
    <span class="label label-xl label-light-danger label-inline w-100 font-weight-bold">
      Rejected
    </span>
  );
};

export default Rejected;
