import React, { useEffect, useState } from 'react'
import jwt_decode from "jwt-decode";
import AxiosLibWithHeader2 from '../../../Library/AxiosLibWithHeader2';
import { AlertNotif } from '../../../Library/AlertNotif';
import LoadingBtn from '../../Widgets/LoadingBtn';
import { openModal } from '../../../Library/ModalPopUp';
import MyDetailAtd from './MyDetailAtd';

export default function MyAttendance({ semester_status, studentid }) {

    const objSemes = { atpid: semester_status[ (Object.values(semester_status).length -1) ].atp_term ? semester_status[(Object.values(semester_status).length -1)].atp_term.id : 0, data: semester_status[(Object.values(semester_status).length -1)] };
    const [selectedSemes, setSemester] = useState(objSemes);

    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

    //const studentid = AuthDecode.currentData.entityid;

    const [attd, setAttd] = useState({ loading: false, data: [], message: "" });
    const GET_ATD_STUDENT_BY_ATP = (param) => {
        setAttd({ loading: true, data: [], message: "" });
        var keys = {
            "AppId": AuthDecode.AppID,
            "AppEnv": AuthDecode.AppEnv,
            "Secretkey": AuthDecode.secretkey
        };
        var body = {
            "parameters": param,
            "target_data": "GET_ATD_STUDENT_BY_ATP"
        };
        const postData = {
            keys: keys,
            body: body
        };

        AxiosLibWithHeader2(postData, 'app/api/req-module')
            .then(
                response => {
                    if ("message" in response.response) {
                        setAttd({ loading: false, data: [], message: response.response.message.user });
                    } else {
                        var param = response.response.data;
                        if (Object.values(param).length > 0) {
                            param.sort((a, b) => a.semester - b.semester);
                            setAttd({ loading: false, data: param, message: "" });
                        } else {
                            setAttd({ loading: false, data: [], message: "No record found" });
                        }
                    }
                }
            );
    }
    useEffect(() => {
        var param = {
            "studentid": studentid,
            "atpid": selectedSemes.atpid
        }
        GET_ATD_STUDENT_BY_ATP(param);
    }, []);

    const HandlerSemester = (value) => {
        var value = parseInt(value);
        var findData = semester_status.find(item => item.atp_term.id === value);
        setSemester({ atpid: value, data: findData });
        var param = {
            "studentid": studentid,
            "atpid": value
        }
        GET_ATD_STUDENT_BY_ATP(param);
    }

    const OpenAtdDetail = (course) => {
        var param = {
            studentid: studentid,
            atpid: selectedSemes.atpid,
            courseid: course.id
        };

        openModal({ header: "Detail Attendance - " + course.coursetitle, message: <MyDetailAtd param={param} />, size: "xl" })
    }

    return (
        <div>
            <div className="card card-custom gutter-b">
                <div className="card-header align-items-center border-0 mt-4">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="font-weight-bolder text-dark">My Attendance</span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">Attendance base on semester chosen</span>
                    </h3>
                    <div className="card-toolbar">
                        <select name="semester" className="form-control form-control-sm" defaultValue={selectedSemes.atpid} onChange={(e) => HandlerSemester(e.target.value)}>
                            <option value="0">Choose Semester</option>
                            {Object.values(semester_status).length > 0 ? (
                                semester_status.map((v, index) => (
                                    <option value={(v.atp_term) ? v.atp_term.id : ""} key={index}>
                                        Semester {(v.semester) ? v.semester : ""}
                                    </option>
                                ))
                            ) : ""}
                        </select>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-row-dashed align-middle fs-6 gy-4 my-0 pb-3 dataTable no-footer">
                            <thead>
                                <tr className="text-uppercase text-primary">
                                    <th className="font-weight-bolder">No</th>
                                    <th className="font-weight-bolder">Course Name</th>
                                    <th className="font-weight-bolder">Credit</th>
                                    <th className="font-weight-bolder">Lecturer</th>
                                    <th className="font-weight-bolder">Class Progress</th>
                                    <th className="font-weight-bolder">Absent</th>
                                    <th className="font-weight-bolder">Detail</th>
                                </tr>
                            </thead>
                            <tbody>
                                {attd.loading ? (
                                    <tr>
                                        <td colSpan={8}><LoadingBtn /></td>
                                    </tr>
                                ) : attd.message ? (
                                    <tr>
                                        <td colSpan={8}><AlertNotif message={attd.message} color={"danger"} title={"Information"} /></td>
                                    </tr>
                                ) : (
                                    Object.values(attd.data).length > 0 ? (
                                        attd.data.map((v, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <span className="d-block font-weight-bolder">{(v.course) ? v.course.coursetitle : ""}</span>
                                                    <span className="text-muted">{(v.course) ? v.course.code : ""}</span>
                                                </td>
                                                <td>
                                                    <span className="d-block font-weight-bolder">{(v.course) ? v.course.credit : ""}</span>
                                                </td>
                                                <td>
                                                    {Object.values(v.emp).length > 0 ? (
                                                        v.emp.map((e, index) => (
                                                            <div key={index} className={"my-2 " + (((index + 1) < Object.values(v.emp).length) ? "border-bottom" : "")}>
                                                                <span className="font-weight-bolder font-size-7">{e.fullname}</span>
                                                            </div>
                                                        ))
                                                    ) : "-"}
                                                </td>
                                                <td>
                                                    <span className="d-block font-weight-bolder">{v.t_session}</span>
                                                </td>
                                                <td>
                                                    <span className="d-block font-weight-bolder">{v.t_absent}%</span>
                                                </td>
                                                <td className="text-center">
                                                    <button className="btn btn-icon btn-sm btn-light-primary" type="button" onClick={() => OpenAtdDetail(v.course)}>
                                                        <span className="svg-icon svg-icon-1">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="currentColor"></rect><path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor"></path></svg>
                                                        </span>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={8}>No record found</td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    )
}
