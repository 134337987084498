import React from 'react'

export default function TablePCFOriginal({ pcf }) {
    var objSubjectUniq = pcf.reduce((accumulator, currentValue) => {
        if (currentValue.course_type !== "asc.course.type.optional") {
            const { course_id, credit } = currentValue;
            if (!accumulator.hasOwnProperty(course_id)) {
                accumulator[course_id] = credit;
            } else if (accumulator[course_id] !== credit) {
                accumulator[course_id] += credit;
            }
        }
        return accumulator;
    }, {});
    var tsks = 0;
    if (objSubjectUniq) {
        Object.keys(objSubjectUniq).map((k) => {
            tsks = tsks + objSubjectUniq[k];
        });
    }
    return (
        <div className="table-responsive">
            <table className="table table-striped">
                <thead>
                    <tr className="font-weight-boldest h-65px" style={{ backgroundColor: "#474bcf" }}>
                        <th className="align-middle font-size-h4 text-white border-0" width={"5%"}>No</th>
                        <th className="align-middle font-size-h4 text-white border-0">Course</th>
                        <th className="align-middle font-size-h4 text-white border-0">Type/Credit</th>
                        <th className="align-middle font-size-h4 text-white border-0">Section ID</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.values(pcf).length > 0 ? (
                        pcf.map((v, index) => {
                            var c_type = "";
                            if (v.course_type) {
                                let typeArr = v.course_type.split(".");
                                c_type = typeArr[typeArr.length - 1];
                            }
                            return (
                                <tr key={index} className="font-size-lg font-weight-bolder h-65px">
                                    <td>{index + 1}</td>
                                    <td>
                                        <span className="d-block font-weight-bolder">
                                            {v?.coursetitle}
                                        </span>
                                        <span className="text-muted font-weight-bolder">
                                            {v?.code}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="d-block text-capitalize font-weight-bolder">
                                            Credit {v?.credit} SKS
                                        </span>
                                        <span className="text-muted text-capitalize font-weight-bolder">
                                            {c_type}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="font-weight-bolder">
                                            {v?.section_id}
                                        </span>
                                    </td>
                                </tr>
                            )
                        }
                        )
                    ) : (
                        <tr>
                            <td colSpan={5}>No record found</td>
                        </tr>
                    )}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={2} className="font-weight-bolder text-right">Total Credit:</td>
                        <td className="font-weight-bolder">{tsks} SKS</td>
                        <td colSpan={2}></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}
