import jwt_decode from "jwt-decode";
import AxiosLibWithHeader2 from "./AxiosLibWithHeader2";

var AuthToken = localStorage.getItem("AuthToken");
const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

const REQUEST_TARGET_MODULES = async (body) => {
  var keys = {
    AppId: AuthDecode.AppID,
    AppEnv: AuthDecode.AppEnv,
    Secretkey: AuthDecode.secretkey,
  };
  
  const postData = {
    keys: keys,
    body: body,
  };

  const result = AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
    if (response.error) {
      return { loading: false, data: [], message: response.error };
    } else if ("message" in response.response) {
      return{
        loading: false,
        data: [],
        message: response.response.message.user,
      };
    } else {
      var results = response.response.data;
      if (results) {
        return {
            loading: false,
            data:  results,
            message: "",
        };
      } else {
        return { loading: false, data: [], message: "No record found" };
      }
    }
  });

  return result;
};

export { REQUEST_TARGET_MODULES };
