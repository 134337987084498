import React, { useEffect, useState } from 'react'
import { REQUEST_TARGET_MODULES } from '../../../../../Library/APIsTarget';
import { handlePaste, handlerRepeatMatch, isValidEmail, TextHandler } from '../../Components/PCFHandler';
import { openToast } from '../../../../../Library/ToastPopUp';
import CourseOvering from '../FormOvering/CourseOvering';
import CourseCatalog from '../FormOvering/CourseCatalog';
import MyPCF from '../FormOvering/MyPCF';
import { ButtonPrimary, InputTextPCF } from '../../Components/PCFUI';

export default function FormPCF({ data, student, ref_acad, setPrevPCF }) {
    const selectedAcad = ref_acad?.id;
    const isbachelorReg = (student.program.id === 1 ? true : false);

    const [totalsks, setTotalSKS] = useState(0);
    const [maxSKS, setMaxSKS] = useState(24);
    const [mypcf, setMypcf] = useState([]);
    const [mobile, setMobile] = useState({
        phone: "",
        rephone: "",
        match: "",
        message: "",
    });
    const [parent_email, setParentEmail] = useState({
        mail: "",
        message: "",
        match: false,
    });
    const [counselor, setCounselor] = useState({
        loading: false,
        data: [],
        message: "",
    });

    useEffect(() => {
        if (student && student.corporate && student.corporate.id !== 3) {
            setMaxSKS(totalsks);
        } else {
            setMaxSKS(24);
        }
    }, [totalsks])

    useEffect(() => {
        GetCounselor(setCounselor, student.studentid);
    }, [])

    let objparamPCF = {
        cellular: mobile.phone,
        studentid: student.studentid,
        email: parent_email.mail,
    };

    const handlerRepeat = (value) => {
        const result = handlerRepeatMatch(mobile.phone, value);
        setMobile({ ...mobile, rephone: result.value, match: result.match });
    };

    const handlerActionCourse = (value) => {
        if (value.action_type === "add") {
            //check existing course
            var isSame = mypcf.filter(
                (prev) =>
                    prev.section_id === value.section_id ||
                    prev.detail.course_code === value.detail.course_code
            );
            if (Object.values(isSame).length > 0) {
                const msg = TextHandler.find(item => item.id === 2);
                openToast({
                    header: "Information",
                    message: msg.name,
                });
            } else {
                setMypcf([...mypcf, value]);
            }
        } else {
            var clone = [...mypcf];
            clone.splice(value.index, 1);
            setMypcf(clone);
        }
    };

    const [submit, setSubmit] = useState({ loading: false, data: [], message: "" });
    const handlerSubmitPCF = async (e) => {
        e.preventDefault();

        if (totalsks > maxSKS) {
            openToast({
                header: "Information",
                message: "Maximum total Credit is " + maxSKS + " SKS",
            });
        } else {
            if (Object.values(mypcf).length > 0 && objparamPCF.cellular) {
                if (isbachelorReg) {
                    if (!parent_email.match) {
                        openToast({ header: "Information", message: "Email parent is empty" });
                        return;
                    }
                }

                const courses = mypcf.map((c) => {
                    var obj = {};
                    obj.action_type = c.action_type;
                    obj.course_id = c.course_id;
                    obj.section_id = c.section_id;
                    return obj;
                });

                const paramPost = {
                    cellular: objparamPCF.cellular,
                    courses: courses,
                    studentid: student.studentid.toString(),
                    term_id: ref_acad.id,
                    email: parent_email.mail
                };
                console.log(paramPost);

                setSubmit({ loading: true, message: "", data: [] });
                if (student.term_intake.id === selectedAcad ||
                    student.enrollment_type_id === "mkt.enrollment.type.cooperation" ||
                    student.enrollment_type_id === "mkt.enrollment.type.exchange"
                ) {
                    setPrevPCF({ pcf: mypcf, data: [], param: paramPost, totalsks: totalsks, open: true })
                    window.scrollTo(0, 0);
                } else {
                    const param_amount = {
                        parameters: paramPost,
                        target_data: "GET_PCF_AMOUNT",
                    }

                    const results = await REQUEST_TARGET_MODULES(param_amount);
                    if (results.data && Object.values(results.data).length > 0) {
                        setSubmit({ loading: false, message: "", data: results.data });
                        setPrevPCF({ pcf: mypcf, data: results.data, open: true, param: paramPost, totalsks: totalsks });
                        window.scrollTo(0, 0);
                    } else {
                        setSubmit(results);
                    }
                }


            } else {
                const msg = TextHandler.find(item => item.id === 1);
                openToast({
                    header: "Information",
                    message: msg.name,
                });
            }
        }
    }

    return (
        <div id='form-pcf-first'>
            <div className="row">
                <div className="col-lg-4">
                    <CourseOvering
                        data={data}
                        handlerActionCourse={handlerActionCourse}
                    />
                </div>
                <div className="col-lg-4">
                    {selectedAcad && (
                        <CourseCatalog
                            handlerActionCourse={handlerActionCourse}
                            student={student}
                            selectedAcad={selectedAcad}
                        />
                    )}
                </div>
                <div className="col-lg-4">
                    <MyPCF
                        maxSKS={maxSKS}
                        mypcf={mypcf}
                        totalsks={totalsks}
                        setTotalSKS={setTotalSKS}
                        handlerActionCourse={handlerActionCourse}
                    />
                </div>
            </div>

            <div className="card card-custom gutter-b">
                <div className="card-body">
                    <form
                        method="post"
                        autoComplete="off"
                        onSubmit={(e) => handlerSubmitPCF(e)}
                    >
                        <div className="row justify-content-between align-items-start">
                            <div className="col-lg-3">
                                <InputTextPCF
                                    title={"Student WhatsApp Number"}
                                    required={true}
                                    msgerror={(!mobile.phone && "Fill up this field") || mobile.message}
                                    value={mobile.phone}
                                    name="phone"
                                    minLength={10}
                                    className={
                                        "form-control " +
                                        (Object.values(mobile.phone).length > 10
                                            ? "is-valid"
                                            : "is-invalid")
                                    }
                                    placeholder="081xxx"
                                    onChange={(e) =>
                                        setMobile({
                                            ...mobile,
                                            phone: e.target.value.replace(/\D/g, ""),
                                        })
                                    }
                                />
                            </div>
                            <div className="col-lg-3">
                                <InputTextPCF
                                    title={"Confirm Student WhatsApp Number"}
                                    required={true}
                                    msgerror={mobile.message}
                                    onPaste={handlePaste}
                                    minLength={10}
                                    name="repeat-phone"
                                    placeholder="081xxx"
                                    className={
                                        "form-control " +
                                        (mobile.match === "match" ? "is-valid" : "is-invalid")
                                    }
                                    onChange={(e) =>
                                        handlerRepeat(e.target.value.replace(/\D/g, ""))
                                    }
                                    value={mobile.rephone}
                                />
                            </div>
                            {isbachelorReg && (
                                <div className="col-lg-3">
                                    <InputTextPCF
                                        title={"Parent Email Address"}
                                        required={true}
                                        msgerror={(!parent_email.mail && "Fill up this field") || parent_email.message}
                                        className={
                                            "form-control " +
                                            (!parent_email.match ? "is-invalid" : "is-valid")
                                        }
                                        onChange={(e) => setParentEmail(isValidEmail(e.target.value))}
                                        value={parent_email.mail}
                                    />
                                </div>
                            )}

                            {Object.values(counselor.data).length > 0 && (
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label className="font-weight-bolder">Counselor</label>
                                        {counselor.data.map((v, index) => (
                                            <div className="list-counselor form-control form-control-solid" key={index}>
                                                <span className="font-weight-bold">
                                                    {v.emp ? v.emp.fullname : ""}
                                                </span>
                                                {index < Object.values(counselor.data).length - 1 && (
                                                    <span className="mr-1">, </span>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="text-center">
                            <ButtonPrimary
                                type="submit"
                                className="font-weight-bolder text-uppercase px-9 py-4 btn-submit"
                                disabled={
                                    submit.loading ? submit.loading :
                                        mobile.rephone
                                            ? mobile.phone === mobile.rephone
                                                ? false
                                                : true
                                            : true
                                }>
                                {submit.loading ? "Processing" : "Next and Preview"}
                            </ButtonPrimary>
                        </div>
                    </form >
                </div >
            </div >
        </div>
    )
}


const GetCounselor = async (setCounselor, studentid) => {
    setCounselor({ loading: true, data: [], message: "" });
    const param = {
        parameters: {
            studentid: studentid,
        },
        target_data: "GET_COUNSELOR",
    };
    const results = await REQUEST_TARGET_MODULES(param);
    if (results.data && Object.values(results.data).length > 0) {
        setCounselor(results);
    } else {
        setCounselor({
            loading: false,
            data: [],
            message: "You do not have a counselor.",
        });
    }
};