import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import AxiosLibWithHeader2 from "../../../../../Library/AxiosLibWithHeader2";
import LoadingBtn from "../../../../Widgets/LoadingBtn";
import { useMemo } from "react";
import "./PCFStyle.css"
import { AlertNotifRetry } from "../../../../../Library/AlertNotif";

export default function CourseCatalog(props) {
  var AuthToken = localStorage.getItem("AuthToken");
  const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];
  const reff_atp_id_term = props.selectedAcad ? props.selectedAcad : 0;
  const [openFilter, setOpenFilter] = useState(true);
  const [costcenter, setCostCenter] = useState({
    loading: false,
    message: "",
    data: [],
    iserror: false,
  });

  const LOOK_UP_COST_CENTER = () => {
    setCostCenter({ loading: true, message: "", data: [] });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: {
        search: "",
      },
      target_data: "LOOK_UP_COST_CENTER",
    };
    const postData = {
      keys: keys,
      body: body,
    };

    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setCostCenter({
          data: [],
          message: response.error,
          loading: false,
        });
      } else if ("message" in response.response) {
        var msg = response.response.message.user;
        setCostCenter({ data: [], message: msg, loading: false });
      } else {
        var results = response.response.data;
        if (results) {
          setCostCenter({ message: "", data: results, loading: false });
        } else {
          setCostCenter({
            message: "No record found",
            loading: false,
            data: [],
          });
        }
      }
    });
  };

  const paramCourse = {
    //studentid:AuthDecode.currentData.entityid,
    target_dept: props.student.clu.id,
    term_id: reff_atp_id_term,
    offset: 1,
    limit: 1000,
  };
  const [courses, setCourses] = useState(paramCourse);
  const [courseSection, setCoursesSection] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const [listData, setListData] = useState([]);
  const GET_SECTION_BY_STUDENT = (param) => {
    setCoursesSection({ message: "", loading: true, data: [] });
    setListData([]);
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: param,
      target_data: "GET_SECTION_BY_STUDENT",
    };
    const postData = {
      keys: keys,
      body: body,
    };

    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setCoursesSection({
          data: [],
          message: response.error,
          loading: false,
        });
        setListData([]);
      } else if ("message" in response.response) {
        var msg = response.response.message.user;
        setCoursesSection({ data: [], message: msg, loading: false });
        setListData([]);
      } else {
        var results = response.response.data;
        if (results) {
          setCoursesSection({ message: "", data: results, loading: false });
          setListData(results);
        } else {
          setCoursesSection({
            message: "No record found",
            loading: false,
            data: [],
          });
          setListData([]);
        }
      }
    });
  };

  const [filterType, setFilterType] = useState(1);
  const [filterValue, setFilterValue] = useState("");

  const ReloadData = () => {
    LOOK_UP_COST_CENTER();
    GET_SECTION_BY_STUDENT(courses);
  }

  useEffect(() => {
    ReloadData();
  }, []);

  const handlerFindCourses = (e) => {
    e.preventDefault();
    let paramCourse = {
      target_dept: props.student.clu.id,
      term_id: reff_atp_id_term,
      offset: 1,
      limit: 1000,
    };

    if (filterType === 1) {
      paramCourse.coursetitle = encodeURIComponent(filterValue);
    } else if (filterType === 2) {
      paramCourse.coursecode = filterValue.toString();
    } else if (filterType === 3) {
      paramCourse.section_id = filterValue.toString();
    } else if (filterType === 4) {
      paramCourse.name = filterValue.toString();
    }
    GET_SECTION_BY_STUDENT(paramCourse);
  };

  const handlerPrev = () => {
    window.scrollTo(0, 0);
    let coffset = courses.offset - 1;
    courses.offset = coffset;
    GET_SECTION_BY_STUDENT(courses);
    setCourses({ ...courses, offset: coffset });
  };

  const handlerNext = () => {
    window.scrollTo(0, 0);
    let coffset = courses.offset + 1;
    courses.offset = coffset;
    GET_SECTION_BY_STUDENT(courses);
    setCourses({ ...courses, offset: coffset });
  };

  /*==DATATABELS CONFIG==*/
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ResultData = useMemo(() => {
    let computedData = listData;
    if (Object.values(listData).length > 0) {
      computedData = courseSection.data.map((c) => {
        let type = "-";
        if (c.course) {
          let typeArr = c.course.course_type.split(".");
          type = typeArr[typeArr.length - 1];
        }
        var obj = {};
        obj.coursetitle = c.course ? c.course.coursetitle : "-";
        obj.course_code = c.course ? c.course.code : "-";
        obj.course_credit = c.course ? c.course.credit : 0;
        obj.course_type = type;
        obj.course_id = c.course ? c.course.id : 0;
        obj.section_id = c.section_id;
        obj.lecturer = c.lecturer ? c.lecturer : "";
        obj.is_add = c.is_add ? c.is_add : false;
        return obj;
      });
    }
    computedData.sort((a, b) => (a.coursetitle > b.coursetitle ? 1 : -1));

    if (search) {
      computedData = computedData.filter((listData) => {
        return String(listData.coursetitle.toLowerCase()).includes(
          search.toLowerCase()
        );
      });
    }
    setTotalItems(computedData.length);

    //Sorting listDatas
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort((a, b) => {
        if (!isNaN(parseInt(a[sorting.field]))) {
          return sorting.order === "asc"
            ? a[sorting.field] - b[sorting.field]
            : b[sorting.field] - a[sorting.field];
        } else {
          return reversed * a[sorting.field].localeCompare(b[sorting.field]);
        }
      });
    }
    return computedData;
  }, [listData, currentPage, search, sorting]);
  /*==END DATATABELS CONFIG==*/

  const handleAddCourse = (data) => {
    setListData((prevListData) => {
      const cloneData = [...prevListData];
      return cloneData.map((item) => {
        if (item.section_id === data.section_id) {
          item.is_add = true;
        }
        return item;
      });
    });
    props.handlerActionCourse({
      action_type: "add",
      course_id: data.course_id,
      section_id: data.section_id,
      detail: data,
    });
  };

  return (
    <div className="card card-custom card-stretch gutter-b">
      <div className="card-header align-items-center border-0 mt-4">
        <h3 className="card-title align-items-start flex-column">
          <span className="font-weight-bolder text-dark">Course Offering</span>
          <span className="text-muted mt-3 font-weight-bold font-size-sm">
            Find your specific course
          </span>
        </h3>
        <div className="card-toolbar">
          <button
            className="btn btn-sm btn-light-primary"
            type="button"
            onClick={() => setOpenFilter(openFilter ? false : true)}
          >
            <i className="fa fa-filter"></i>
            <i className={"fa fa-caret-" + (openFilter ? "up" : "down")}></i>
          </button>
        </div>
      </div>

      <div className="card-body py-0">
        <div className="filter-course">
          <form
            method="post"
            autoComplete="off"
            onSubmit={(e) => handlerFindCourses(e)}
            className={"mb-5 " + (openFilter ? "" : "d-none")}
          >
            <div className="border p-3 rounded bg-light">
              <div className="row mb-3">
                <div className="col-lg-12">
                  <div className="form-group mb-2">
                    <label className="required font-weight-bold">Search By</label>
                    <select
                      name="type"
                      className="form-control"
                      value={filterType}
                      onChange={(e) => { setFilterType(parseInt(e.target.value)); setFilterValue(""); }}
                    >
                      <option value="1">Course Title</option>
                      <option value="2">Course Code</option>
                      <option value="3">Section ID</option>
                      <option value="4">Section Name</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mb-0">
                    <label className="required font-weight-bold">Keyword</label>
                    <input
                      type="text"
                      name="value"
                      placeholder={
                        filterType === 1
                          ? "enter course title"
                          : filterType === 2
                            ? "enter course code"
                            : filterType === 4 ?
                             "enter section name": "enter ID"
                      }
                      className="form-control"
                      value={filterValue}
                      onChange={(e) => setFilterValue(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="d-grid">
                <button
                  className="btn btn-sm btn-primary font-weight-bold font-size-lg btn-block"
                  type="submit"
                >
                  Search
                </button>
              </div>
              
            </div>
          </form>
          <div className="input-icon input-icon-right mb-3 d-none">
            <input
              type="text"
              className="form-control"
              placeholder="Search..."
              onChange={(e) => setSearch(e.target.value)}
            />
            <span>
              <i className="flaticon2-search-1 icon-md"></i>
            </span>
          </div>
          <div
            className="my-course"
            style={{ maxHeight: "700px", overflowY: "auto" }}
          >
            <div className="table-responsive">
              <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                <thead>
                  <tr>
                    <th>
                      <span className="text-dark">COURSE ({totalItems})</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {courseSection.loading ? (
                    <tr>
                      <td colSpan={2}>
                        <LoadingBtn />
                      </td>
                    </tr>
                  ) : courseSection.message ? (
                    <tr>
                      <td colSpan={2}>
                        <AlertNotifRetry message={courseSection.message} color="danger" ReloadData={ReloadData} />
                      </td>
                    </tr>
                  ) : ResultData.length > 0 ? (
                    ResultData.map((v) => {
                      return (
                        <tr key={v.course_id}>
                          <td className="px-0 py-1">
                            <div className="border p-3 rounded">
                              <div className="d-flex justify-content-start align-items-start">
                                <div className="my-course w-100 ">
                                  <span className="font-weight-bolder mb-1">
                                    {v.coursetitle}
                                  </span>
                                  <div>
                                    <span className="text-muted font-weight-bold">
                                      {v.course_code}
                                    </span>
                                  </div>
                                </div>
                                <div className="info-course mr-8">
                                  <span className="font-weight-bolder d-block">
                                    Credit
                                    <span className="ml-2 text-dark font-weight-bold">
                                      {v.course_credit}
                                    </span>
                                  </span>
                                  <span className="text-muted text-capitalize">
                                    {v.course_type}
                                  </span>
                                </div>
                                <button
                                  className={"btn btn-sm btn-icon btn-success p-5 " + (v.is_add && "shake-button")}
                                  type="button"
                                  title="Add to your PCF"
                                  onClick={() => handleAddCourse(v)}
                                >
                                  <i className="fas fa-plus"></i>
                                </button>

                              </div>
                              <div className="cost_owner mt-5">
                                <span className="font-weight-bolder d-block">
                                  {v.section_id}
                                </span>
                                <span className="font-weight-bolder text-muted">
                                  Section ID
                                </span>
                              </div>
                              <div className="lecturer mt-5">
                                {Object.values(v.lecturer).length > 0 ? (
                                  v.lecturer.map((l, index) => (
                                    <span
                                      key={index}
                                      className="font-weight-bolder d-block"
                                    >
                                      {l.emp ? l.emp.fullname : "-"}
                                    </span>
                                  ))
                                ) : (
                                  <span className="font-weight-bolder d-block">
                                    -
                                  </span>
                                )}
                                <span className="font-weight-bolder text-muted">
                                  Lecturer
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="2">No record found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
