import React, { useState, useMemo } from "react";
import jwt_decode from "jwt-decode";

export default function CourseOvering(props) {
  const listData = props.data;
  const student = props.student ? props.student : [];
  var csemester = 0;

  var AuthToken = localStorage.getItem("AuthToken");
  const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

  /*==DATATABELS CONFIG==*/
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ResultData = useMemo(() => {
    let computedData = listData;
    if (Object.values(listData).length > 0) {
      computedData = listData.map((c) => {
        let typeArr = c.course_type.split(".");
        let type = typeArr[typeArr.length - 1];
        var obj = {};
        obj.coursetitle = c.coursetitle;
        obj.course_code = c.code;
        obj.course_credit = c.credit;
        obj.course_type = type;
        obj.course_id = c.course_id;
        obj.grade_fin = c.grade_fin;
        obj.semester = c.semester;
        obj.cost_owner = c.major;
        obj.section_id = c.section_id;
        obj.in_semester = c.in_semester;
        return obj;
      });
    }

    computedData.sort((a, b) => (a.semester < b.semester ? 1 : -1));

    if (search) {
      computedData = computedData.filter((listData) => {
        return String(listData.coursetitle.toLowerCase()).includes(
          search.toLowerCase()
        );
      });
    }
    setTotalItems(computedData.length);

    //Sorting listDatas
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort((a, b) => {
        if (!isNaN(parseInt(a[sorting.field]))) {
          return sorting.order === "asc"
            ? a[sorting.field] - b[sorting.field]
            : b[sorting.field] - a[sorting.field];
        } else {
          return reversed * a[sorting.field].localeCompare(b[sorting.field]);
        }
      });
    }
    return computedData;
  }, [listData, currentPage, search, sorting]);
  /*==END DATATABELS CONFIG==*/
  
  return (
    <div className="card card-custom card-stretch gutter-b">
      <div className="card-header align-items-center border-0 mt-4">
        <h3 className="card-title align-items-start flex-column">
          <span className="font-weight-bolder text-dark">
            {student ? student.firstname + "'s" : ""} Course History
          </span>
        </h3>
      </div>
      <div className="card-body pt-0">
        <div className="filtering">
          <div className="input-icon input-icon-right mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search..."
              onChange={(e) => setSearch(e.target.value)}
            />
            <span>
              <i className="flaticon2-search-1 icon-md"></i>
            </span>
          </div>
        </div>
        <div
          className="table-list"
          style={{ maxHeight: "800px", overflowY: "auto" }}
        >
          <div className="table-responsive">
            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
              <thead className="d-none">
                <tr className="text-uppercase">
                  <th>
                    <span className="text-dark">Course</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={4} className="p-1"></td>
                </tr>
                {ResultData.length > 0 ? (
                  ResultData.map((v, index) => {
                    let elementTR = "";
                    if (csemester !== v.semester) {
                      elementTR = (
                        <tr className={v.in_semester === 1 ? "bg-primary" : "bg-light"} key={v.semester}>
                          <th colSpan="7">
                            <span className={v.in_semester === 1 ? "text-white" : ""}>Semester {v.semester}</span>
                          </th>
                        </tr>
                      );
                    }
                    csemester = v.semester;
                    return (
                      <>
                        {elementTR}
                        <tr key={index}>
                          <td colSpan={3} className="py-1 px-0">
                            <div className={"border p-3 " + (v.in_semester === 1 ? "border-primary bg-light-primary" : "")}>
                              <div className="d-flex justify-content-start align-items-start">
                                <div className="my-course w-100 ">
                                  <span className="font-weight-bolder mb-1">
                                    {v.coursetitle}
                                  </span>
                                  <div>
                                    <span className="text-muted font-weight-bold">
                                      {v.course_code}
                                    </span>
                                  </div>
                                </div>
                                <div className="info-course w-100px">
                                  <span className="font-weight-bolder d-block">
                                    Credit
                                    <span className="ml-2 text-dark font-weight-bold">
                                      {v.course_credit}
                                    </span>
                                  </span>
                                  <span className="text-muted text-capitalize">
                                    {v.course_type}
                                  </span>
                                </div>
                                <div className="grade-act mx-5">
                                  <span
                                    className={
                                      "font-weight-bolder font-size-lg badge " +
                                      (v.grade_fin !== "A" &&
                                        v.grade_fin !== "B" &&
                                        v.grade_fin !== "C"
                                        ? "badge-danger"
                                        : "badge-info")
                                    }
                                    title={"Grade " + v.grade_fin}
                                  >
                                    {v.grade_fin}
                                  </span>
                                  <div className="d-none">
                                    {v.grade_fin !== "A" &&
                                      v.grade_fin !== "B" &&
                                      v.grade_fin !== "C" ? (
                                      <button
                                        className="btn btn-sm btn-icon btn-success mr-3"
                                        type="button"
                                        title="Add to your PCF"
                                        onClick={() =>
                                          props.handlerActionCourse({
                                            action_type: "add",
                                            course_id: v.course_id,
                                            detail: v,
                                          })
                                        }
                                      >
                                        <i className="fas fa-plus"></i>
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                              
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={3}>No record found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="card-footer">
          <div className="d-flex justify-content-center align-items-center">
            <a href='https://drive.google.com/drive/u/0/folders/1fxdSxrtCHuwJRDwniiilHna87qg7Mmiv' target='_blank' className="btn btn-sm btn-success mr-3">
              <i className="flaticon2-open-text-book"></i> View Curriculum
            </a>
          </div>
      </div>
    </div>
  );
}
