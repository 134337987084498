import React, { useEffect, useState } from "react";
import Moment from "moment";
import jwt_decode from "jwt-decode";
import LoadingBtn from "../../../../Widgets/LoadingBtn";
import AxiosLibWithHeader2 from "../../../../../Library/AxiosLibWithHeader2";
import FullCalendarSchd from "./FullCalendarSchd";

var AuthToken = localStorage.getItem("AuthToken");
const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

export default function CalendarSchedule({ stdid }) {
  const studentid = (stdid) ? stdid : AuthDecode.currentData.entityid
  const [schd, setSchd] = useState({
    loading: false,
    message: "",
    data: []
  });
  const [eventSchd, setEventSchd] = useState([]);

  const startOfMonth = Moment().startOf("month").format("YYYY-MM-DD");
  const endOfMonth = Moment().endOf("month").format("YYYY-MM-DD");

  const paramPost = {
    studentid: studentid,
    tgl: startOfMonth,
    tglend: endOfMonth,
  };
  const [paramDate, setParamDate] = useState(paramPost);

  const GET_SCHD_CLASS_STD_NEXT = (param) => {
    setEventSchd([]);
    setSchd({ message: "", loading: true, data: [] });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: param,
      target_data: "GET_SCHD_CLASS_STD_NEXT",
    };
    const postData = {
      keys: keys,
      body: body,
    };
    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setSchd({
          data: [],
          message: response.error,
          loading: false,
        });
      } else if ("message" in response.response) {
        var msg = response.response.message.user;
        setSchd({
          data: [],
          message: msg,
          loading: false,
        });
      } else {
        var results = response.response.data;
        if (results) {
          const eventItem = convertEventItem(results);
          setEventSchd((prevEventSchd) => [...prevEventSchd, ...eventItem]);

          //GET_ACAD_MSTONE_BY_ACAD();

          setSchd({
            data: eventItem,
            message: "",
            loading: false,
          });
        } else {
          setSchd({
            data: [],
            message: "No record found",
            loading: false,
          });
        }
      }
    });
  };

  useEffect(() => {
    GET_SCHD_CLASS_STD_NEXT(paramDate);
  }, []);


  const [milestone, setMilestone] = useState({
    loading: false,
    message: "",
    data: []
  });

  const GET_ACAD_MSTONE_BY_ACAD = () => {
    setMilestone({ message: "", loading: true, data: [] });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: { view_all: 0 },
      target_data: "GET_ACAD_MSTONE_BY_ACAD",
    };
    const postData = {
      keys: keys,
      body: body,
    };
    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setMilestone({
          data: [],
          message: response.error,
          loading: false,
        });
      } else if ("message" in response.response) {
        var msg = response.response.message.user;
        setMilestone({
          data: [],
          message: msg,
          loading: false,
        });
      } else {
        var results = response.response.data;
        if (results) {
          var eventItem = ConvertSchdItem(results);
            
          setEventSchd((prevEventSchd) => [...prevEventSchd, ...eventItem]);
          setMilestone({
            data: eventItem,
            message: "",
            loading: false,
          });
        } else {
          setMilestone({
            data: [],
            message: "No record found",
            loading: false,
          });
        }
      }
    });
  };

  const handlerPrevNextMonth = (type) => {
    if (type === 1) {
      //prev
      const startOfMonth = Moment(paramDate.tgl)
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      const endOfMonth = Moment(paramDate.tgl)
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD");
      setParamDate({ ...paramDate, tgl: startOfMonth, tglend: endOfMonth });
      var paramSchd = {
        studentid: paramDate.studentid,
        tgl: startOfMonth,
        tglend: endOfMonth,
      };
      GET_SCHD_CLASS_STD_NEXT(paramSchd);
    } else if (type === 2) {
      //next
      const startOfMonth = Moment(paramDate.tgl)
        .add(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      const endOfMonth = Moment(paramDate.tgl)
        .add(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD");
      setParamDate({ ...paramDate, tgl: startOfMonth, tglend: endOfMonth });
      var paramSchd = {
        studentid: paramDate.studentid,
        tgl: startOfMonth,
        tglend: endOfMonth,
      };
      GET_SCHD_CLASS_STD_NEXT(paramSchd);
    } else {
      //today
      const startOfMonth = Moment(new Date())
        .startOf("month")
        .format("YYYY-MM-DD");
      const endOfMonth = Moment(new Date()).endOf("month").format("YYYY-MM-DD");
      setParamDate({ ...paramDate, tgl: startOfMonth, tglend: endOfMonth });
      var paramSchd = {
        studentid: paramDate.studentid,
        tgl: startOfMonth,
        tglend: endOfMonth,
      };
      GET_SCHD_CLASS_STD_NEXT(paramSchd);
    }
  };

  return (
    <div className="card card-custom gutter-b">
      <div className="card-body pt-4">
        <div className="pt-6 mb-6 d-flex justify-content-between align-items-center">
          <h3 className="text-capitalize">Timetables</h3>
          <div className="btn-group">
            <button
              className="btn btn-sm btn-light"
              type="button"
              onClick={() => handlerPrevNextMonth(1)}
            >
              <i className="icon-1x flaticon2-left-arrow"></i>
            </button>
            <button
              className="btn btn-sm btn-light text-dark"
              type="button"
              onClick={() => handlerPrevNextMonth(3)}
            >
              <span className="h4">{Moment(paramDate.tgl).format("MMMM YYYY")}</span>
            </button>
            <button
              className="btn btn-sm btn-light"
              type="button"
              onClick={() => handlerPrevNextMonth(2)}
            >
              <i className="icon-1x flaticon2-right-arrow"></i>
            </button>
          </div>
        </div>
        {(schd.message || milestone.message) && (
          <div className="alert alert-danger d-flex justify-content-between align-items-center">
            <span className="fw-boldest">{schd.message || milestone.message}</span>
            <button className="btn btn-sm btn-light-primary" type="button" onClick={()=>GET_SCHD_CLASS_STD_NEXT(paramDate)}>
              Try again here
            </button>
          </div>
        )}
        {schd.loading || milestone.loading ? <LoadingBtn /> :
          (
            <FullCalendarSchd
              data={Object.values(eventSchd).length > 0 ? eventSchd : []}
              initialDate={paramDate.tgl}
              GET_SCHD_CLASS_STD_NEXT={GET_SCHD_CLASS_STD_NEXT}
              paramDate={paramDate}
            />
          )}

      </div>
    </div>
  );
}

const getDate = (dayString) => {
  const today = new Date();
  const year = today.getFullYear().toString();
  let month = (today.getMonth() + 1).toString();

  if (month.length === 1) {
    month = "0" + month;
  }

  return dayString.replace("YEAR", year).replace("MONTH", month);
};

const convertEventItem = (data) => {
  let events = [];
  if (data) {
    //const sortdata = data.sort((a, b) => (a.start_dt < b.start_dt ? 1 : -1));
    data.map((v) => {
      var objItem = {};
      var blue = "#F9F9F9";
      var colorStatus = blue;
      if (v.is_cancel) {
        colorStatus = "#F9F9F9";
      }
      objItem.title = v.section_id ? (v.course ? v.course.coursetitle : "-") : v.label;
      objItem.id = v.section_id ? v.section_id : "";
      objItem.start = getDate(v.start_dt);
      objItem.end = getDate(v.end_dt);
      objItem.groupId = JSON.stringify(v);
      objItem.background = colorStatus;
      objItem.border = colorStatus;
      events.push(objItem);
    });
  }

  return events;
};

const ConvertSchdItem = (data) => {
  let events = [];
  let blue = "#0359fb82";
  let green = "#03b898ba";
  let red = "#ef05624f";
  if (data) {
    data.map((v) => {
      let colorStatus = "";
      if (v.status_id === 1) {
        colorStatus = red;
      } else if (v.status_id === 2) {
        colorStatus = green;
      } else if (v.status_id === 3) {
        colorStatus = blue;
      } else {
        colorStatus = "#f9f9f9";
      }
      var objItem = {};
      objItem.title = v.name || "";
      objItem.id = v.id;
      //objItem.allDay= true;
      objItem.start = Moment(v.start_dt).format("YYYY-MM-DD");
      objItem.end = Moment(v.end_dt).format("YYYY-MM-DD");
      objItem.groupId = JSON.stringify(v);
      objItem.background = colorStatus;
      objItem.border = colorStatus;
      events.push(objItem);
    })
  }

  return events;
}