import React from "react";

const Waiting = () => {
  return (
    <span class="label label-xl label-light-warning label-inline w-100 font-weight-bold">
      Waiting
    </span>
  );
};

export default Waiting;
