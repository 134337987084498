import React, { Component } from 'react'
import Moment from "moment"
import jwt_decode from "jwt-decode"
import AxiosLibWithHeader2 from "../../../Library/AxiosLibWithHeader2"
import LoadingBtn from '../../Widgets/LoadingBtn';
import AvaBoy from "../../../../media/avatars/001-boy.svg"
import AvaGirl from "../../../../media/avatars/002-girl.svg"
import CalendarSchedule from './Widget/CalendarSchedule';
import CalendarDeptIndex from './Widget/CalendarDeptIndex';
import { AlertNotif, AlertNotifRetry } from '../../../Library/AlertNotif';
import WCounseling from '../Counseling-new/WCounseling';
import { NotifAnnouncement } from '../../Student/Dashboard/NotifAnnouncements';
import AxiosLib from '../../../Library/AxiosLib';

export class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.fetchingAnnouncements = this.fetchingAnnouncements.bind(this);
        this.getProfile = this.getProfile.bind(this);
        var AuthToken = localStorage.getItem("AuthToken");
        const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
        this.state = {
            Profile: { loading: false, message: "", data: [] },
            AuthDecode: AuthDecode,
            announcements: { loading: false, message: "", data: [] }
        };
    }

    fetchingAnnouncements(param) {
        this.setState({ announcements: { loading: true, message: "", data: [] } })
        AxiosLib(param, "mc/api/notifications/fetch").then(
            (response) => {
                if (response.error) {
                    this.setState({ announcements: { loading: false, message: response.error, data: [] } })
                } else {
                    if (response.data) {
                        this.setState({ announcements: { loading: false, message: "", data: response.data } })
                    } else {
                        this.setState({ announcements: { loading: false, message: "No record found", data: [] } })
                    }
                }
            }
        );
    }

    getProfile() {
        this.setState({ LoadingPage: true });
        const reqModule = () => {
            var keys = {
                "AppId": this.state.AuthDecode.AppID,
                "AppEnv": this.state.AuthDecode.AppEnv,
                "Secretkey": this.state.AuthDecode.secretkey
            };
            var body = {
                "target_data": "GET_PROFILE"
            };
            const postData = {
                keys: keys,
                body: body
            };

            AxiosLibWithHeader2(postData, 'app/api/req-module')
                .then(
                    response => {
                        if (response.error) {
                            this.setState({ Profile: { loading: false, message: response.error, data: [] } })
                        } else if ("message" in response.response) {
                            let message = "";
                            if(response.response.code === "00000"){
                                message = "A connection error occurred with the server. Please wait a moment or click the button below to retry.";
                            }else{
                                message = response.response.message.user;
                            }
                            this.setState({ Profile: { loading: false, message: message, data: [] } })
                        } else {
                            var result = response.response.data;
                            if (result) {
                                this.setState({ Profile: { loading: false, message: "", data: result } })
                            } else {
                                this.setState({ Profile: { loading: false, message: "No record found", data: [] } })
                            }

                        }
                    }
                );
        }
        reqModule();
    }

    componentDidMount() {
        this.getProfile();
        this.fetchingAnnouncements({ "RoleID": this.state.AuthDecode.currentData.roleid, "CurrentDate": Moment().format("YYYY-MM-DD") });
    }

    render() {

        return (
            <div id="dashboard">
                {Object.values(this.state.announcements.data).length > 0 ? (
                    <NotifAnnouncement data={this.state.announcements.data} />
                ) : ""}
                <div className="row">

                    <div className="col-lg-12 col-xxl-12">
                        <div className="row">
                            <div className="col-md-12 col-lg-8 col-xxl-8">
                                <CalendarSchedule />
                            </div>
                            <div className="col-md-12 col-lg-4 col-xxl-4">
                                <WCounseling />
                                <CalendarDeptIndex />

                                {this.state.Profile.loading ? <LoadingBtn /> : this.state.Profile.message ? (
                                    <AlertNotifRetry message={this.state.Profile.message} color={"danger"} title={"Information"} />
                                ) : (
                                    Object.values(this.state.Profile.data).length > 0 ? (
                                        <div className="card card-custom gutter-b">
                                            <div className="card-body">
                                                <div className="profile">
                                                    <div className="d-flex flex-wrap flex-sm-nowrap">
                                                        <div className="mr-3">
                                                            <div className="symbol symbol-50px symbol-circle mb-7">
                                                                <img src={this.state.Profile.data.gender === "M" ? AvaBoy : AvaGirl} alt="pic" className='bg-light-info p-1' />
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <div className="d-flex flex-column">
                                                                <h2 className="text-gray-900 fs-2 fw-bold me-1">
                                                                    {this.state.Profile.data.fullname}
                                                                </h2>
                                                                <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                                                    <span className="d-flex align-items-center text-gray-400 me-5 mb-2">
                                                                        {this.state.Profile.data.sgumail}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="info">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="emp text-center">
                                                            <p className="text-muted mb-0">ID</p>
                                                            <p className="mb-0">{this.state.Profile.data.id}</p>
                                                        </div>
                                                        <div className="emp text-center">
                                                            <p className="text-muted mb-0">Employee ID</p>
                                                            <p className="mb-0">{this.state.Profile.data.employee_id}</p>
                                                        </div>
                                                        <div className="emp text-center">
                                                            <p className="text-muted mb-0">Join</p>
                                                            <p className="mb-0">{(this.state.Profile.data.join_date) ? Moment(this.state.Profile.data.join_date).format('D MMMM YYYY') : '-'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="session mt-5">
                                                    <div className="border border-warning bg-light-warning rounded p-2">
                                                        Your session will expired on: <b>{Moment(this.state.AuthDecode.ExpiredSession).format('Do MMMM YYYY HH:mm')}</b>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : ""
                                )}

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Dashboard;