import React, { useEffect, useState } from 'react'
import jwt_decode from "jwt-decode";
import LoadingBtn from '../../../Widgets/LoadingBtn';
import { CardMessage, CardTitle } from '../Components/PCFUI';
import { REQUEST_TARGET_MODULES } from '../../../../Library/APIsTarget';
import CardProfilePCF from '../Components/CardProfilePCF';
import FormRevisiPCF from './FormRevisiPCF';
import PCFRPreview from './PCFRPreview';

var AuthToken = localStorage.getItem("AuthToken");
const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

export function PCFRevisionCom() {
    const studentid = AuthDecode.currentData.entityid;
    const acad_type_short_semes = "asc.atp.type.term.short";

    const [stdStatus, setStdStatus] = useState({
        loading: false,
        data: [],
        message: "",
    });
    const [stdBio, setStdBio] = useState({
        loading: false,
        data: [],
        message: "",
    });
    const [stdAcad, setStdAcad] = useState({
        loading: false,
        data: [],
        message: "",
    });
    const [refAcad, setRefAcad] = useState();

    const [rereg, setRereg] = useState({ loading: false, data: [], message: [] });

    const GetSelectedAcad = async (acadType) => {
        setRefAcad(null);
        setStdAcad({ loading: true, data: [], message: "" });
        const results = await REQUEST_TARGET_MODULES({
            target_data: "GET_ACTIVE_ACAD",
        });
        if (results.data && Object.values(results.data).length > 0) {
            const findNotNull = results.data.filter(
                (item) => item.atp_group_type !== null
            );
            const selectIndex = findNotNull.findIndex(
                (v) => v.atp_group_type.id === acadType
            );
            setRefAcad(
                findNotNull[selectIndex]
                    ? findNotNull[selectIndex]?.reff_atp_term
                    : null
            );
            setStdAcad(results);
        } else {
            setRefAcad(null);
            setStdAcad({
                loading: false,
                data: [],
                message: "No active Academic Year found",
            });
        }
    };

    const GetDataStudentStatus = async () => {
        setStdStatus({ loading: true, data: [], message: "" });
        const param = {
            parameters: {
                studentid: studentid,
            },
            target_data: "GET_STUDENT_STATUS",
        };
        const results = await REQUEST_TARGET_MODULES(param);
        if (results.data && Object.values(results.data).length > 0) {
            const atp_group_type = results.data?.atp_group_type || "";
            GetSelectedAcad(atp_group_type);
            setStdStatus(results);
        } else {
            setStdStatus({
                loading: false,
                data: [],
                message: "No record found",
            });
        }
    };

    const GetDataStudentBio = async () => {
        setStdBio({ loading: true, data: [], message: "" });
        const param = {
            parameters: {
                studentid: studentid,
            },
            target_data: "GET_STUDENT_DATA_M",
        };
        const results = await REQUEST_TARGET_MODULES(param);
        if (results.data && Object.values(results.data).length > 0) {
            setStdBio(results);
        } else {
            setStdBio({
                loading: false,
                data: [],
                message: "No record found",
            });
        }
    };

    const GetReregActive = async () => {
        setRereg({ loading: true, data: [], message: "" });
        const param = {
            parameters: { studentid: studentid },
            target_data: "GET_REREG_STUDENT_REVISION",
        };
        const results = await REQUEST_TARGET_MODULES(param);
        if (results.data && Object.values(results.data).length > 0) {
            setRereg(results);
        } else {
            setRereg(results);
        }
    };

    useEffect(() => {
        GetDataStudentStatus();
        GetDataStudentBio();
        GetReregActive();
    }, []);

    if (stdStatus.loading || stdBio.loading || stdAcad.loading) {
        return <LoadingBtn />;
    } else if (stdStatus.message || stdBio.message || stdAcad.message) {
        return <CardMessage message={stdStatus.message || stdAcad.message} icon={"info"} />;
    } else if (Object.values(stdAcad.data).length > 0 && refAcad) {
        //check rereg is open for this student
        if (Object.values(rereg.data).length > 0) {
            return <RevisionPCF studentid={studentid} student_status={stdStatus.data} student_bio={stdBio.data} current_acad={stdAcad.data} ref_acad={refAcad} rereg_data={rereg} />;
        } else {
            return (
                <>
                    <CardProfilePCF studentid={studentid} student_status={stdStatus.data} student_bio={stdBio.data} />
                    <CardTitle icon={"jangkar"} message={"Plan Course Form Revision Term " + (refAcad?.name || "-")} />
                    <CardMessage message={rereg.message} icon={"box"} />
                </>
            );
        }
        //end check
    } else {
        return <CardMessage message={"No record found"} icon={"box"} />;
    }
}

const RevisionPCF = ({ studentid, student_status, student_bio, current_acad, ref_acad, rereg_data }) => {
    const [prevPCF, setPrevPCF] = useState({ pcf: [], data: [], param: [], totalsks: 0, open: false });
    return (
        <div id="revision-pcf">
            <div className={prevPCF.open ? "d-none" : "d-block"}>
                <CardProfilePCF studentid={studentid} student_status={student_status} student_bio={student_bio} />
                <CardTitle icon={"jangkar"} message={"Plan Course Form Revision Term " + (ref_acad?.name || "-")} />
                <FormRevisiPCF studentid={studentid} student_status={student_status} ref_acad={ref_acad} rereg_data={rereg_data.data} setPrevPCF={setPrevPCF} />
            </div>
            <div className={prevPCF.open ? "d-block" : "d-none"}>
                <PCFRPreview data_prev={prevPCF} setPrevPCF={setPrevPCF} student_bio={student_bio} studentid={studentid} rereg_data={rereg_data.data} />
            </div>
        </div>
    )
}
