import React from "react";
import empty from "../assets/empty-box.png";
import signal from "../assets/signal.png";

const NoData = ({ msg, sbj }) => {
  return (
    <div className="d-flex flex-column align-items-center">
      {msg !== "No record found" ? (
        <img
          src={signal}
          alt="server error"
          style={{ width: 100, aspectRatio: 1, marginTop: 5, marginBottom: 5 }}
        />
      ) : (
        <img
          src={empty}
          alt="emty box"
          style={{ width: 100, aspectRatio: 1, marginTop: 5, marginBottom: 5 }}
        />
      )}
      <p className="mt-5 mb-10">
        {msg}{sbj !== "" && " for "}
        <span className="font-weight-bold text-primary">
          {sbj}
        </span>
      </p>
    </div>
  );
};

export default NoData;
