import React, { useMemo, useState } from "react";
import Pdf from "react-to-pdf";
import jwt_decode from "jwt-decode";
import Moment from "moment";
import parse from "html-react-parser";
import Logo1 from "../../../../../media/logos/logo-sgu.png";
import Logo2 from "../../../../../media/logos/logo-sgu-name.png";
import "./Transcript.css";
import { StudentIDFormat, TypeFailIDX } from "../../../../Library/GlobalHelper";
import LoadingBtn from "../../../Widgets/LoadingBtn";

export default function Transcript(props) {
  var AuthToken = localStorage.getItem("AuthToken");
  const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

  const listdata = props.CoursePlan.data;

  //const [listOptional, setListOptional] = useState([]);
  const [tCredit, setTCredit] = useState(0);
  const [tMerit, setTMerit] = useState(0);
  const [tEcts, setTEcts] = useState(0);

  const [optional, setOptional] = useState([]);
  const ResultData = useMemo(() => {
    let computedData = listdata.map(v => {
      var obj = v;
      v.atd = v.atd ? v.atd : "";
      if (v.course) {
        if (v.course.score_type !== 1) {
          v.score = "-";
          v.grade_fin = "-";
          v.grade_point_fin = "-";
          v.merit_fin = 0;
          v.course_lesson = 0;
        }
      }
      return obj;
    });
    let computedOpt = listdata;
    if (computedData.length > 0) {
      computedData = computedData.filter(
        (prev) => prev.course.course_type !== "asc.course.type.optional"
      );
      computedOpt = computedOpt.filter(
        (prev) => prev.course.course_type === "asc.course.type.optional"
      );
      setOptional(computedOpt);
      const mandatory = computedData.filter(
        (prev) => prev.course.course_type !== "asc.course.type.optional"
      );
      let totalECTS = mandatory.reduce(function (prev, current) {
        return prev + +current.course.ects;
      }, 0);
      let totalMerit = mandatory.reduce(function (prev, current) {
        return prev + +current.merit_fin;
      }, 0);
      let totalCredit = mandatory.reduce(function (prev, current) {
        return prev + +current.credit;
      }, 0);
      setTEcts(totalECTS);
      setTMerit(totalMerit);
      setTCredit(totalCredit);

      // let cloneData = [...listdata];
      // let listOptional =  cloneData.filter((prev) => prev.course.course_type === "asc.course.type.optional")
      // setListOptional(listOptional);
    }

    return computedData;
  }, [listdata, tCredit, tEcts, tMerit]);

  const ref = React.createRef();
  var datetime = Moment().toDate();
  const numberName = (i) => {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return parse(i + "<sup>st</sup>");
    }
    if (j == 2 && k != 12) {
      return parse(i + "<sup>nd</sup>");
    }
    if (j == 3 && k != 13) {
      return parse(i + "<sup>rd</sup>");
    }
    return parse(i + "<sup>th</sup>");
  }

  return (
    <>
      <div className="card card-custom" ref={ref} id="card-transcript" style={{ width:"790px" }}>
        <div className="card-body">
          <div className="t-header">
            <table className="w-100">
              <tr>
                <td>
                  <img src={Logo1} alt="Logo SGU" width={150} height={50} />
                </td>
                <td className="text-right align-bottom">
                  <img src={Logo2} alt="Logo SGU" width={350} height={30} />
                </td>
              </tr>
            </table>
          </div>
          <div
            className="t-body pt-5 mt-2"
            style={{ borderTop: "1px solid #32499c" }}
          >
            <div className="profile border-bottom border-dark pb-3">
              <table className="w-100">
                <thead>
                  <tr>
                    <td width="15%">Student ID</td>
                    <th>{props.status ? StudentIDFormat(props.status.studentid) : "-"}</th>

                    <td width="15%">Date of Print</td>
                    <th>
                      {Moment(datetime).format("DD MMMM YYYY")}
                    </th>
                  </tr>
                  <tr>
                    <td>Name</td>
                    <th>{props.profile ? props.profile.fullname : "-"}</th>

                    <td>Study Program</td>
                    <th>{props.status ? props.status.program.name : "-"}</th>
                  </tr>
                  <tr>
                    <td>Place of Birth</td>
                    <th>{props.profile ? props.profile.birthplace : "-"}</th>
                    <td className="align-top">Faculty</td>
                    <th>
                      <span className="d-block">
                        {props.status
                          ? props.status.clu.faculty
                            ? props.status.clu.faculty.name
                            : "-"
                          : "-"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <td>Date of Birth</td>
                    <th>
                      {props.profile
                        ? Moment(props.profile.birthdate).format("DD MMMM YYYY")
                        : "-"}
                    </th>
                    <td className="align-top">Department</td>
                    <th>
                      <span className="d-block">
                        {props.status ? props.status.clu.name : "-"}
                      </span>
                      {props.status ? (
                        <span className="d-block">
                          {props.status.clu_spec
                            ? props.status.clu_spec.name
                            : ""}
                        </span>
                      ) : (
                        ""
                      )}
                    </th>
                  </tr>
                </thead>
              </table>
            </div>

            <div
              className="transcript py-2"
              style={{ maxHeight: "500px", overflow: "auto" }}
            >
              <h5 className="text-center text-dark mb-3">
                Result of {props.selected ? numberName(props.selected) : 0}{" "}
                Semester
              </h5>
              <table className="table-transcript w-100" border={1}>
                <thead>
                  <tr className="bg-secondary text-center">
                    <th width={"4%"}>No</th>
                    <th>Subject</th>
                    <th>Points</th>
                    <th>Grade</th>
                    <th>Grade Point</th>
                    <th>Credit</th>
                    <th>ECTS</th>
                    <th>Merit</th>
                    <th>Lessons</th>
                    <th>Absence %</th>
                    <th>Final Result</th>
                  </tr>
                </thead>
                {props.CoursePlan.loading ? (
                  <tr>
                    <td colSpan={12}>
                      <LoadingBtn />
                    </td>
                  </tr>
                ) : props.CoursePlan.message ? (
                  <tr>
                    <td colSpan={12} className="text-center">
                      {props.CoursePlan.message.includes("02016") ? (
                        <span>
                          Your semester score will be available after the semester grade release date marked on your <a href="https://sgu.ac.id/academic-services/academic-calendar/" className="alert-link" target="_blank">Academic Calendar</a>
                        </span>
                      ) : props.CoursePlan.message}
                    </td>
                  </tr>
                ) : AuthDecode.currentData.roleid === 1 ? (
                  ResultData.length > 15 ? (
                    <tr>
                      <td colSpan={11}>
                        <h3 className="text-center">
                          Please contact AAO to generate Manually for your Transcript
                        </h3>
                      </td>
                    </tr>
                  ) : ResultData.length > 0 ? (
                    <tbody>
                      {ResultData.map((m, index) => {
                        //var lableremark = TypeFailIDX(m.fail_idx);
                        return (
                          <tr key={index}>
                            <td className="text-center">{index + 1}</td>
                            <td>{m.course ? m.course.coursetitle : ""} </td>
                            <td>{m.score ? m.score : 0}</td>
                            <td>{m.grade_fin ? m.grade_fin : "F"}</td>
                            <td>{m.grade_point_fin ? m.grade_point_fin : 0}</td>
                            <td>{m.credit ? m.credit : 0}</td>
                            <td>{m.course ? m.course.ects : 0}</td>
                            <td>{m.merit_fin ? m.merit_fin : "-"}</td>
                            <td>{m.course_lesson ? m.course_lesson : "-"}</td>
                            <td>{m.course ? ((m.course.is_atd_calc) ? (m.course.is_atd_calc === 1 ? (m.atd ? (m.atd + "%") : "") : "-") : "-") : "-"}</td>
                            <td>{m.course_status}</td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td colSpan={2}>
                          <span className="text-dark font-weight-bolder text-center">
                            Total
                          </span>
                        </td>
                        <td colSpan={3}></td>
                        <td>
                          <span className="text-dark font-weight-bolder text-center">
                            {tCredit}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark font-weight-bolder text-center">
                            {tEcts}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark font-weight-bolder text-center">
                            {tMerit.toFixed(2)}
                          </span>
                        </td>
                        <td colSpan={3}></td>
                      </tr>
                      <tr>
                        <td colSpan="10">
                          <span className="text-dark font-weight-bolder text-center">
                            Grade Point Average
                          </span>
                        </td>
                        <td className="text-center">
                          <span className="text-dark font-weight-bolder text-center">
                            {props.CurrentSemesStatus.gpa}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="10">
                          <span className="text-dark font-weight-bolder text-center">
                            Cumulative GPA
                          </span>
                        </td>
                        <td className="text-center">
                          <span className="text-dark font-weight-bolder text-center">
                            {props.CurrentSemesStatus.cgpa}
                          </span>
                        </td>
                      </tr>
                      {Object.values(optional).length > 0
                        ? optional.map((m, index) => {
                          var lableremark = TypeFailIDX(m.fail_idx);
                          var num = 0;
                          return (
                            <>
                              <tr>
                                <td colSpan={13}>
                                  <span className="font-weight-bolder">
                                    Extracuricullar
                                  </span>
                                </td>
                              </tr>
                              <tr key={index}>
                                <td className="text-center">{num + 1}</td>
                                <td>
                                  {m.course ? m.course.coursetitle : ""}{" "}
                                </td>
                                <td>{m.score ? m.score : 0}</td>
                                <td>{m.grade_fin ? m.grade_fin : "F"}</td>
                                <td>
                                  {m.grade_point_fin ? m.grade_point_fin : 0}
                                </td>
                                <td>{m.credit ? m.credit : 0}</td>
                                <td>{m.course ? m.course.ects : 0}</td>
                                <td>{m.merit_fin ? m.merit_fin : 0}</td>
                                <td>
                                  {m.course_lesson ? m.course_lesson : 0}
                                </td>
                                <td>{m.course ? ((m.course.is_atd_calc) ? (m.course.is_atd_calc === 1 ? m.atd : "-") : "-") : "-"}</td>
                                <td>
                                  <span className="d-block">{m.remark}</span>
                                  <span>{lableremark}</span>
                                </td>
                              </tr>
                            </>
                          );
                        })
                        : ""}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={12}>No result found</td>
                      </tr>
                    </tbody>
                  )
                ) : ResultData.length > 0 ? (
                  <tbody>
                    {ResultData.map((m, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-center">{index + 1}</td>
                          <td>{m.course ? m.course.coursetitle : ""} </td>
                          <td>{m.score ? m.score : 0}</td>
                          <td>{m.grade_fin ? m.grade_fin : "F"}</td>
                          <td>{m.grade_point_fin ? m.grade_point_fin : 0}</td>
                          <td>{m.credit ? m.credit : 0}</td>
                          <td>{m.course ? m.course.ects : 0}</td>
                          <td>{m.merit_fin ? m.merit_fin : 0}</td>
                          <td>{m.course_lesson ? m.course_lesson : 0}</td>
                          <td>{m.course ? ((m.course.is_atd_calc) ? (m.course.is_atd_calc === 1 ? m.atd : "-") : "-") : "-"}</td>
                          <td>{m.course_status}</td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td colSpan={2}>
                        <span className="text-dark font-weight-bolder text-center">
                          Total
                        </span>
                      </td>
                      <td colSpan={3}></td>
                      <td>
                        <span className="text-dark font-weight-bolder text-center">
                          {tCredit}
                        </span>
                      </td>
                      <td>
                        <span className="text-dark font-weight-bolder text-center">
                          {tEcts}
                        </span>
                      </td>
                      <td colSpan="4">
                        <span className="text-dark font-weight-bolder text-center">
                          {tMerit.toFixed(2)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <span className="text-dark font-weight-bolder text-center">
                          Grade Point Average
                        </span>
                      </td>
                      <td colSpan="8">
                        <span className="text-dark font-weight-bolder text-center">
                          {props.CurrentSemesStatus.gpa}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <span className="text-dark font-weight-bolder text-center">
                          Cumulative GPA
                        </span>
                      </td>
                      <td colSpan="8">
                        <span className="text-dark font-weight-bolder text-center">
                          {props.CurrentSemesStatus.cgpa}
                        </span>
                      </td>
                    </tr>
                    {Object.values(optional).length > 0
                      ? optional.map((m, index) => {
                        var lableremark = TypeFailIDX(m.fail_idx);
                        var num = 0;
                        return (
                          <>
                            <tr>
                              <td colSpan={13}>
                                <span className="font-weight-bolder">
                                  Extracuricullar
                                </span>
                              </td>
                            </tr>
                            <tr key={index}>
                              <td className="text-center">{num + 1}</td>
                              <td>{m.course ? m.course.coursetitle : ""} </td>
                              <td>{m.score ? m.score : 0}</td>
                              <td>{m.grade_fin ? m.grade_fin : "F"}</td>
                              <td>
                                {m.grade_point_fin ? m.grade_point_fin : 0}
                              </td>
                              <td>{m.credit ? m.credit : 0}</td>
                              <td>{m.course ? m.course.ects : 0}</td>
                              <td>{m.merit_fin ? m.merit_fin : 0}</td>
                              <td>{m.course_lesson ? m.course_lesson : 0}</td>
                              <td>{m.course ? ((m.course.is_atd_calc) ? (m.course.is_atd_calc === 1 ? (m.atd ? (m.atd + "%") : "") : "-") : "-") : "-"}</td>
                              <td>{m.course_status}</td>
                            </tr>
                          </>
                        );
                      })
                      : ""}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={13}>No result found</td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>

            <div className="footer py-2">
              <div className="expl border-top border-dark py-2">
                <h6 className="text-dark m-0">Explanations</h6>
                <table className="w-100">
                  <tr>
                    {/* <td width="30%" className="align-top">
                      <p className="m-0">1. Semester Result</p>
                      <p className="m-0">40% Quizzes and/or assignments</p>
                      <p className="m-0">60% Final Examinations</p>
                    </td> */}
                    <td className="align-top">
                      <p className="m-0">1. Grading Scale</p>
                      <GradingScore program_id={props.status && props.status.program.id} />
                    </td>
                    <td></td>
                  </tr>
                </table>
              </div>

              <div className="notes py-2 border-top border-dark">
                <h6 className="m-0 font-italic text-dark">
                  This is a computer-generated report and no authorized
                  signature is required
                </h6>
              </div>

              <div
                className="address py-2"
                style={{ borderTop: "1px solid #32499c" }}
              >
                <table className="w-100">
                  <tr>
                    <th>SWISS GERMAN UNIVERSITY</th>
                    <td align="right">
                      <b>Tel.</b> +62 21 2977 9596/9597
                    </td>
                  </tr>
                  <tr>
                    <td>The Prominence Tower</td>
                    <td align="right">
                      <b>Fax.</b> +62 21 2977 9598
                    </td>
                  </tr>
                  <tr>
                    <td>Jalan Jalur Sutera Barat No.15, Alam Sutera</td>
                    <td align="right">
                      <ins className="text-primary">academic@sgu.ac.id</ins>
                    </td>
                  </tr>
                  <tr>
                    <td>Tangerang, Banten 15143 - Indonesia</td>
                    <td align="right">
                      <ins className="text-primary">www.sgu.ac.id</ins>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {Object.values(props.CoursePlan).length > 0 &&
        Object.values(props.CoursePlan).length < 15 ? (
        <div className="text-center py-5">
          <Pdf
            targetRef={ref}
            filename={
              "Transcript-Semester-" +
              (props.selected ? props.selected : 0) +
              "-" +
              (props.status ? props.status.studentid : "0") +
              ".pdf"
            }
          >
            {({ toPdf }) => (
              <button
                className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
                type="button"
                onClick={toPdf}
              >
                <span className="mr-3">Download</span>
                <i className="far fa-file-pdf"></i>
              </button>
            )}
          </Pdf>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

const GradingScore = ({ program_id }) => {
  if (program_id === 1) {
    return (
      <table width="90%">
        <tr>
          <td width="20%">90-100</td>
          <td width="15%">4</td>
          <td width="5%">A</td>
          <td>Excellent</td>
        </tr>
        <tr>
          <td>75-89</td>
          <td>3.0-3.9</td>
          <td>B</td>
          <td>Good</td>
        </tr>
        <tr>
          <td>60-74</td>
          <td>2.0-2.9</td>
          <td>C</td>
          <td>Satisfactory</td>
        </tr>
        <tr>
          <td>50-59</td>
          <td>1.0-1.9</td>
          <td>D</td>
          <td>Poor</td>
        </tr>
        <tr>
          <td>Bellow 50</td>
          <td>0</td>
          <td>F</td>
          <td>Fail</td>
        </tr>
      </table>
    )
  } else {
    return (
      <table width="90%">
        <tr>
          <td width="20%">90-100</td>
          <td width="15%">4</td>
          <td width="5%">A</td>
          <td>Excellent</td>
        </tr>
        <tr>
          <td width="20%">85-89</td>
          <td width="15%">3.7-3.9</td>
          <td width="5%">A-</td>
          <td>Excellent</td>
        </tr>
        <tr>
          <td>82-84</td>
          <td>3.5-3.6</td>
          <td>B+</td>
          <td>Good</td>
        </tr>
        <tr>
          <td>79-81</td>
          <td>3.0-3.4</td>
          <td>B</td>
          <td>Good</td>
        </tr>
        <tr>
          <td>75-78</td>
          <td>2.7-2.9</td>
          <td>B-</td>
          <td>Good</td>
        </tr>
        <tr>
          <td>70-74</td>
          <td>2.5-2.6</td>
          <td>C+</td>
          <td>Satisfactory</td>
        </tr>
        <tr>
          <td>65-69</td>
          <td>2.0-2.4</td>
          <td>C</td>
          <td>Satisfactory</td>
        </tr>
        <tr>
          <td>60-64</td>
          <td>1.7-1.9</td>
          <td>C-</td>
          <td>Satisfactory</td>
        </tr>
        <tr>
          <td>50-59</td>
          <td>1.0-1.6</td>
          <td>D</td>
          <td>Poor</td>
        </tr>
        <tr>
          <td>Bellow 50</td>
          <td>0</td>
          <td>F</td>
          <td>Fail</td>
        </tr>
      </table>
    )
  }
}