import React, { useEffect, useRef, useState } from "react";
import "./pdf.css";
import Logo from "../../../../../../media/logos/logo-sgu.png";
import Name from "../../../../../../media/logos/logo-sgu-name.png";

import html2pdf from "html2pdf.js";
import moment from "moment";
import jsPDF from "jspdf";
import { StudentIDFormat } from "../../../../../Library/GlobalHelper";

const ExportPDF = ({ data, bio }) => {
  // generate pdf
  console.log("export pdf ", data, bio);
  const iframeRef = useRef();
  const [pdfUrl, setPdfUrl] = useState("");
  const refTable = useRef(null);

  const generatePdf = async () => {
    // Menginisialisasi jsPDF dengan ukuran A4
    const content = refTable.current;
    
    const opt = {
      margin: 1,
      filename: "example.pdf",
      html2canvas: { scale: 5 }, // Skala tinggi untuk hasil yang lebih baik
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      pagebreak: { mode: ['css', 'legacy'] }
    };

    const pdfBlob = await html2pdf().from(content).set(opt).outputPdf("blob");

    const url = URL.createObjectURL(pdfBlob);
    setPdfUrl(url);
  };

  const generateAndDownloadPdf = async () => {
    const content = refTable.current;

    // Konfigurasi html2pdf
    const options = {
      margin: 1,
      filename: "example.pdf",
      html2canvas: { scale: 5 }, // Skala tinggi untuk hasil yang lebih baik
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      pagebreak: { mode: ['css', 'legacy'] }
    };

    // Menghasilkan PDF sebagai Blob dan memicu unduhan file
    html2pdf()
      .from(content)
      .set(options)
      .outputPdf("blob")
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "example.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      });
  };

  useEffect(() => {
    generatePdf();
  }, []);

  const marginLeft = 90;

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  return (
    <>
      {pdfUrl && (
        <iframe ref={iframeRef} src={pdfUrl} width="100%" height="400px" />
      )}
      <div className="d-flex" style={{ gap: 10 }}>
        <p onClick={() => generatePdf()}>printPDFCanvas</p>
        <p onClick={() => generateAndDownloadPdf()}>download</p>
      </div>
      <div
        className="bg-white pt-10 pr-20 pl-5 position-relative d-flex flex-column a4"
        style={{ height: 1100 }} //1100
        ref={refTable}
      >
        {/* header */}
        <div>
          <div
            className="d-flex justify-content-between align-items-end"
            style={{ marginLeft: marginLeft }}
          >
            <img
              src={Logo}
              alt="logo"
              style={{ width: 150, height: 60, aspectRatio: 1 }}
              className="flex-shrink-0"
            />
            <img
              src={Name}
              alt="logo-name"
              style={{ width: 228, height: 25, aspectRatio: 1 }}
              className="flex-shrink-0"
            />
          </div>
          <div
            className="my-5"
            style={{ marginLeft: marginLeft, borderBottom: "1px solid black" }}
          />
          {/* student */}
          <div className="mt-5">
            <div
              style={{ marginLeft: marginLeft }}
              className="d-flex align-items-center"
            >
              <h2 style={{ color: "black", fontSize: 14 }}>
                Thesis Activity Log Sheet
              </h2>
              <p
                className="p-0 m-0 pl-2"
                style={{ color: "black", fontSize: 7 }}
              >
                (should be filled and signed each time the student meets and
                consults the Advisor)
              </p>
            </div>
            {/* table */}
            <div>
              <div>
                <table
                  style={{
                    width: "100%",
                    tableLayout: "fixed",
                  }}
                >
                  <thead>
                    <tr>
                      <td
                        style={{
                          padding: 5,
                          border: "1px solid black",
                          borderRight: "none",
                          borderBottom: "none",
                          width: marginLeft,
                          whiteSpace: "nowrap",
                          color: "black",
                          fontSize: 10,
                        }}
                      >
                        Student's Name
                      </td>
                      <td
                        style={{
                          padding: 5,
                          border: "1px solid black",
                          borderBottom: "none",
                          width: "100%",
                          color: "black",
                          fontSize: 10,
                        }}
                      >
                        {bio.studentdata.fullname}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          padding: 5,
                          border: "1px solid black",
                          borderRight: "none",
                          borderBottom: "none",
                          width: marginLeft,
                          color: "black",
                          fontSize: 10,
                        }}
                      >
                        Student ID
                      </td>
                      <td
                        style={{
                          padding: 5,
                          border: "1px solid black",
                          borderBottom: "none",
                          color: "black",
                          fontSize: 10,
                        }}
                      >
                        {StudentIDFormat(bio.studentid)}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          padding: 5,
                          border: "1px solid black",
                          borderRight: "none",
                          borderBottom: "none",
                          width: marginLeft,
                          color: "black",
                          fontSize: 10,
                        }}
                      >
                        Faculty
                      </td>
                      <td
                        style={{
                          padding: 5,
                          border: "1px solid black",
                          borderBottom: "none",
                          color: "black",
                          fontSize: 10,
                        }}
                      >
                        {bio.studentdata.clu.name}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          padding: 5,
                          border: "1px solid black",
                          borderRight: "none",
                          borderBottom: "none",
                          width: marginLeft,
                          color: "black",
                          fontSize: 10,
                        }}
                      >
                        Advisor
                      </td>
                      <td
                        style={{
                          padding: 5,
                          border: "1px solid black",
                          color: "black",
                          borderBottom: "none",
                          fontSize: 10,
                        }}
                      >
                        {bio.advisor.fullname}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          padding: 5,
                          border: "1px solid black",
                          borderRight: "none",                          
                          width: marginLeft,
                          color: "black",
                          fontSize: 10,
                        }}
                      >
                        Co-Advisor
                      </td>
                      <td
                        style={{
                          padding: 5,
                          border: "1px solid black",
                          color: "black",
                          fontSize: 10,
                        }}
                      >
                        {bio.coadvisor ? bio.coadvisor.fullname : "-"}
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* body */}
        <div
          className="position-relative"
          style={{ flex: "1 1 auto", marginLeft: marginLeft }}
        >
          {/* thesis title */}
          <h2 className="mt-5" style={{ color: "black", fontSize: 12 }}>
            Thesis Title : {bio.submit_title}
          </h2>
          <div className="my-5" style={{ borderBottom: "1px solid black" }} />
          {/* table */}
          <div>
            <table
              style={{
                width: "100%",
                tableLayout: "fixed",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      padding: 5,
                      border: "1px solid black",
                      borderRight: "none",
                      width: 30,
                      color: "black",
                      fontSize: 12,
                      textAlign: "center",
                    }}
                  >
                    No
                  </th>
                  <th
                    style={{
                      padding: 5,
                      border: "1px solid black",
                      borderRight: "none",
                      width: 90,
                      color: "black",
                      fontSize: 12,
                      textAlign: "center",
                    }}
                  >
                    Advisory Date
                  </th>
                  <th
                    style={{
                      padding: 5,
                      border: "1px solid black",
                      borderRight: "none",
                      color: "black",
                      width: 380,
                      fontSize: 12,
                      textAlign: "center",
                    }}
                  >
                    Activity Description
                  </th>
                  <th
                    style={{
                      padding: 5,
                      border: "1px solid black",
                      borderRight: "none",
                      color: "black",
                      fontSize: 12,
                      textAlign: "center",
                    }}
                  >
                    Status
                  </th>
                  <th
                    style={{
                      padding: 5,
                      border: "1px solid black",
                      color: "black",
                      fontSize: 12,
                      textAlign: "center",
                    }}
                  >
                    Approver
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((x, index) => (
                  <tr
                    key={index}
                  >
                    <td
                      style={{
                        padding: 5,
                        border: "1px solid black",
                        borderRight: "none",
                        borderTop: "none",
                        color: "black",
                        fontSize: 11,
                        textAlign: "center",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        padding: 5,
                        border: "1px solid black",
                        borderRight: "none",
                        borderTop: "none",
                        color: "black",
                        fontSize: 11,
                        textAlign: "center",
                      }}
                    >
                      {moment(x.act_date).format("DD-MM-YYYY")}
                    </td>
                    <td
                      style={{
                        padding: 5,
                        border: "1px solid black",
                        borderRight: "none",
                        borderTop: "none",
                        color: "black",
                        fontSize: 11,
                        whiteSpace: "pre-line"
                      }}
                    >
                      {x.descr}
                    </td>
                    <td
                      style={{
                        padding: 5,
                        border: "1px solid black",
                        borderTop: "none",
                        borderRight: "none",
                        color: "black",
                        fontSize: 11,
                        textAlign: "center",
                      }}
                    >
                      {capitalizeFirstLetter(x.status)}
                    </td>
                    <td
                      style={{
                        padding: 5,
                        border: "1px solid black",
                        borderTop: "none",
                        color: "black",
                        fontSize: 11,
                        textAlign: "center",
                      }}
                    >
                      {x.approvedby === bio.advisor.email
                        ? "Advisor"
                        : "Co-Advisor"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* footer */}
          <div>
            <div className="mt-10">
              <div
                style={{ marginLeft: marginLeft }}
                className="d-flex align-items-center"
              ></div>
            </div>

            <div
              className="position-absolute bottom-0"
              style={{ width: "100%" }}
            >
              <div
                className="my-2"
                style={{ borderBottom: "1px solid black" }}
              />
              <div className="d-flex justify-content-between">
                <p style={{ color: "black", fontSize: 10 }}>
                  <strong>Swiss German University</strong>
                </p>
                <p style={{ color: "black", fontSize: 10 }}>
                  <strong>Tel.</strong> +62 21 2977 9596/9597
                </p>
              </div>
              <div
                className="d-flex justify-content-between w-100"
                style={{ marginTop: -15, fontSize: 10 }}
              >
                <p style={{ color: "black" }}>The Prominence Tower</p>
                <p style={{ color: "black" }}>
                  <strong>Fax.</strong> +62 21 2977 9598
                </p>
              </div>
              <div
                className="d-flex justify-content-between w-100"
                style={{ marginTop: -15, fontSize: 10 }}
              >
                <p style={{ color: "black" }}>
                  Jalan Jalur Sutera Barat No. 15, Alam Sutera
                </p>
                <p style={{ color: "blue" }}>marketing@sgu.ac.id</p>
              </div>
              <div
                className="d-flex justify-content-between w-100"
                style={{ marginTop: -15, fontSize: 10 }}
              >
                <p style={{ color: "black" }}>
                  Tangerang, Banten – 15143, Indonesia
                </p>
                <p style={{ color: "blue" }}>www.sgu.ac.id</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExportPDF;
