const TextHandler = [
    {id:1, name:"Please fill up the form with correctly"},
    {id:2, name:"This course has already been added to the 'MY PCF'"},
    {id:3, name:"You cannot enroll in this course because it has not been taken previously"},
    {id:4, name:"You cannot enroll in this course because your grade is A"},
    {id:5, name:"You cannot enroll in this course because the final grade is not yet available."},
]
const handlePaste = (e) => {
    e.preventDefault();
    return false;
};

const isValidEmail = (val) => {
    let message = "",
        match = false;
    let regEmail =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(val)) {
        match = false;
        message = "Invalid Email Address";
    } else {
        match = true;
        message = "";
    }

    return { mail: val, message: message, match: match };
};

const handlerRepeatMatch = (original, value) => {
    var message = "";
    if (value) {
      if (value === original) {
        message = "match";
      } else {
        message = "not match";
      }
    }

    return { original: original, value:value, match: message };
  };

export { TextHandler, handlePaste, isValidEmail, handlerRepeatMatch }