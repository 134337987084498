export {Dashboard} from "./Dashboard";
export {MADashboard} from "./Attendance/MADashboard";
export {AtdClassList} from "./Attendance/AtdClassList";
export {DetailClassList} from "./Attendance/AtdClassList/DetailClassList";
export {Reporting} from "./Attendance/ReportingNew/Reporting";

export {BrowseStudent} from "./Students/BrowseStudent"
export {StudentProfile} from "./Students/StudentProfile"

export {ThesisScore} from "./Thesis/Defense";
export {ThesisSchema} from "./Thesis/Defense/ThesisSchema"

export {ThesisActivityLCT} from "./Thesis/Activity/ThesisActivityLCT"
export {ThesisActivityLCTDetail} from "./Thesis/Activity/ThesisActivityLCTDetail"