import React from 'react'
import Moment from "moment"
import { StudentIDFormat } from '../../../Library/GlobalHelper';

export function Academic(props) {
    const profile = (props.profile) ? props.profile : {};
    const status = (props.status) ? props.status : {};
    
    var enrollType = '-';
    if(status){
        var splitEnroll = status.enrollment_type_id.split('.');
        var totalEnroll = splitEnroll.length;
        enrollType = splitEnroll[totalEnroll-1];
    }

    return (
        <div class='academic mb-10'>
            <div className="row mb-7">
                <label className="col-lg-4 font-weight-bold text-muted">Student ID</label>
                <div className="col-lg-8">
                    <span className="font-weight-bold font-size-lg text-dark text-capitalize">{status.studentid && StudentIDFormat(status.studentid)}</span>
                </div>
            </div>
            <div className="row mb-7">
                <label className="col-lg-4 font-weight-bold text-muted">Program Study</label>
                <div className="col-lg-8">
                    <span className="font-weight-bold font-size-lg text-dark text-capitalize">{(status.program) ? status.program.name : '-'}</span>
                </div>
            </div>
            <div className="row mb-7">
                <label className="col-lg-4 font-weight-bold text-muted">Faculty</label>
                <div className="col-lg-8">
                    <span className="font-weight-bold font-size-lg text-dark text-capitalize">{(status.clu) ? ((status.clu.faculty) ? status.clu.faculty.name : '-' ) : '-'}</span>
                </div>
            </div>
            <div className="row mb-7">
                <label className="col-lg-4 font-weight-bold text-muted">Department</label>
                <div className="col-lg-8">
                    <span className="font-weight-bold font-size-lg text-dark text-capitalize">{(status.clu) ? ((status.clu.dept) ? status.clu.dept.name : '-' ) : '-'}</span>
                </div>
            </div>
            <div className="row mb-7">
                <label className="col-lg-4 font-weight-bold text-muted">Specialize</label>
                <div className="col-lg-8">
                    <span className="font-weight-bold font-size-lg text-dark text-capitalize">{(status.clu_spec) ? status.clu_spec.name : '-'}</span>
                </div>
            </div>

            <div className="row mb-7">
                <label className="col-lg-4 font-weight-bold text-muted">Enrollment</label>
                <div className="col-lg-8">
                    <span className="font-weight-bold font-size-lg text-dark text-capitalize">{enrollType}</span>
                </div>
            </div>

            <div className="row mb-7">
                <label className="col-lg-4 font-weight-bold text-muted">Intake</label>
                <div className="col-lg-8">
                    <span className="font-weight-bold font-size-lg text-dark text-capitalize">{(status.term_intake) ? status.term_intake.name : '-'}</span>
                </div>
            </div>

            <div className="row mb-7">
                <label className="col-lg-4 font-weight-bold text-muted">Email SGU</label>
                <div className="col-lg-8">
                    <span className="font-weight-bold font-size-lg text-dark">{(profile.sgumail) ? profile.sgumail : '-'}</span>
                </div>
            </div>
            
        </div>
    )
}

export default Academic
