import React, { useEffect, useState } from 'react'
import Pdf from "react-to-pdf"
import Moment from "moment"
import jwt_decode from "jwt-decode"
import AxiosLibWithHeader2 from '../../../../../Library/AxiosLibWithHeader2';
import LoadingBtn from '../../../../Widgets/LoadingBtn';
import { StudentIDFormat } from '../../../../../Library/GlobalHelper';

export default function FinalPCF(props) {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
    const studentsAtpId = (props.students) ? props.students.atp_term.id : 0;
    const ref = React.createRef();
    var datetime = Moment().format("YYYY-MM-D H:m:s");

    const objParam = {
        studentid: AuthDecode.currentData.entityid,
        atpid: studentsAtpId
    }
    const [fpcf, setFpcf] = useState({ loading: false, data: [], message: "" });
    const [sumall, setSumall] = useState(0);
    const GET_STUDENT_COURSE = () => {
        setFpcf({ loading: true, data: [], message: "" });
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };
        var body = {
            parameters: objParam,
            target_data: "GET_STUDENT_COURSE",
        };
        const postData = {
            keys: keys,
            body: body,
        };

        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                setFpcf({ loading: false, data: [], message: response.error });
            } else if ("message" in response.response) {
                setFpcf({ loading: false, data: [], message: response.response.message.user });
            } else {
                var results = response.response.data;
                if (results) {
                    var total = results.reduce((a, v) => a = a + ((v.status === 'ACTIVE') ? v.credit : 0), 0);
                    setSumall(total);
                    setFpcf({ loading: false, data: results, message: "" });
                } else {
                    setFpcf({ loading: false, data: results, message: "No record found" });
                }
            }
        });
    }

    useEffect(() => {
        GET_STUDENT_COURSE();
    }, [])

    return (
        <div id='final-pcf' className='w-800px m-auto'>
            <div className='table-responsive m-auto' ref={ref} style={{ padding: "40px", width: "100%" }}>
                <div className="information mb-10">
                    <table className='w-100'>
                        <tr>
                            <td>Student ID</td>
                            <th>{((props.students) ? StudentIDFormat(props.students.studentid) : '-')}</th>

                            <td>Date of Print ID</td>
                            <th>{Moment(datetime).format('DD MMMM YYYY')}</th>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <th>{((AuthDecode) ? AuthDecode.currentData.given_name + ' ' + AuthDecode.currentData.family_name : '-')}</th>

                            <td>Study Program</td>
                            <th>{((props.students) ? props.students.program.name : '-')}</th>
                        </tr>

                        <tr>
                            <td>Semester</td>
                            <th>{((props.students) ? props.students.semester : '-')}</th>

                            <td>Department</td>
                            <th>{((props.students) ? props.students.clu.name : '-')}</th>
                        </tr>
                    </table>
                </div>

                <h3 className='text-center'>Final Plan Course Form Academic {(props.students) ? props.students.atp_term.name : '-'}</h3>
                <table border={1} className="w-100">
                    <thead>
                        <tr>
                            <th width="2%">No</th>
                            <th>Code</th>
                            <th>Course</th>
                            <th>Credit</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fpcf.loading ? (
                            <tr>
                                <td colSpan={5}>
                                    <LoadingBtn />
                                </td>
                            </tr>
                        ) : fpcf.message ? (
                            <tr>
                                <td colSpan={5}>
                                    {fpcf.message}
                                </td>
                            </tr>
                        ) : (Object.values(fpcf.data).length > 0) ? (
                            fpcf.data.map((v, index) => {
                                return (
                                    <tr key={index} style={(v.status === "CANCEL") ? { backgroundColor: '#ff000040' } : {}}>
                                        <td>{(index + 1)}</td>
                                        <td>{v.course.code}</td>
                                        <td>{v.course.coursetitle}</td>
                                        <td className="text-center">{v.credit}</td>
                                        <td>{v.status}</td>
                                    </tr>
                                )
                            })
                        ) : ""}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={4} className="font-weight-bolder">Total Credit {sumall} (include optional)</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            {(Object.values(fpcf.data).length > 0) ? (
                <div className="text-center">
                    <Pdf targetRef={ref} filename={"Final-PCF-" + ((props.students) ? props.students.studentid : '0') + ".pdf"}>
                        {({ toPdf }) => (
                            <button className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4" type='button' onClick={toPdf}>
                                <span className="mr-3">Download</span>
                                <i className="far fa-file-pdf"></i>
                            </button>
                        )}
                    </Pdf>
                </div>
            ) : ""}
        </div>
    )
}
