import React, { useState } from "react";
import FormPCFShort from "./FormPCFShort";
import { CardMessage, CardTitle } from "../Components/PCFUI";
import CardProfilePCF from "../Components/CardProfilePCF";
import PCFPreview from "./PCFPreview";

export function ShortSemester({ student_status, student_bio, refAcad, data_pcf }) {
  const studentid = student_status.studentid;
  const [prevPCF, setPrevPCF] = useState({ pcf: [], data: [], param: [], totalsks: 0, open: false });
  const enroll_type_allowed = ["mkt.enrollment.type.reguler", "mkt.enrollment.type.scholarship", "mkt.enrollment.type.transfer.in.external"];
  return (
    <div id="short-semester">
      <div className={prevPCF.open ? "d-none" : "d-block"}>
        <CardProfilePCF studentid={studentid} student_status={student_status} student_bio={student_bio} />
        <CardTitle icon={"jangkar"} message={"Plan Course Form Term " + (refAcad?.name || "-")} />
        {enroll_type_allowed.includes(student_status.enrollment_type_id) ? (
          <div className="mt-8">
            <FormPCFShort data={data_pcf} student={student_status} ref_acad={refAcad} setPrevPCF={setPrevPCF} />
          </div>
        ) : (
          <CardMessage message={"You are not eligible to take the short semester"} icon={"box"} />
        )}
      </div>

      {enroll_type_allowed.includes(student_status.enrollment_type_id) && (
        <div className={prevPCF.open ? "d-block" : "d-none"}>
          <PCFPreview data_prev={prevPCF} setPrevPCF={setPrevPCF} student_bio={student_bio} studentid={studentid} />
        </div>
      )}
    </div>
  );
}
