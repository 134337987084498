import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import Select from "react-select";
import { AlertNotif } from "../../../../Library/AlertNotif";
import { REQUEST_TARGET_MODULES } from "../../../../Library/APIsTarget";
import { CleansingJSON } from "../../../../Library/GlobalHelper";
import AxiosLibWithHeader2 from "../../../../Library/AxiosLibWithHeader2";

var AuthToken = localStorage.getItem("AuthToken");
const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];


const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: 9999 })
};

const EmployeeSelect = ({ data, setData, required = false, disabled = false, title, showtitle = true }) => {

    const [options, setOptions] = useState([data]);
    const [inputValue, setInputValue] = useState('');
    const [selectedValue, setSelectedValue] = useState(data);

    const [result, setResult] = useState({
        loading: false,
        message: "",
        data: [],
    });

    const ReloadData = async (value) => {
        setResult({ loading: true, message: "", data: [] });
        setOptions([]);
        const param = { search: value };
        const param_estes = {
            parameters: param,
            target_data: "GET_EMPLOYEE_LOOK_UP",
        }

        const result = await REQUEST_TARGET_MODULES(param_estes);

        if (result.data && Object.values(result.data).length > 0) {
            const option_result = result.data.map((v) => ({
                value: v.id,
                label: v.name_related,
            }));
            setOptions(option_result);
            setResult(result);
        } else {
            setResult(result);
            setOptions([]);
        }
    };

    const FindData = (inputValue) => {

        if (inputValue.length >= 3) {
            ReloadData(inputValue);
        } else {
            setOptions([]);
        }
        setInputValue(inputValue);
    };

    const handleSelected = (selectedValue) => {
        if (selectedValue && Object.values(selectedValue).length > 0) {
            setData(selectedValue.value);
            setSelectedValue(selectedValue);
        } else {
            setSelectedValue([]);
            setData("");
        }
    };

    return (
        <>
            <div className="mb-0">
                {showtitle ? (
                    <div className="d-flex align-items-center">
                        <label className={"font-weight-bold mr-2 d-none " + (required && "required")}>
                            {title || "Employee"}
                        </label>
                        {result.loading && <SpinnerLoad />}
                    </div>
                ) : <span>{result.loading && <span><SpinnerLoad /><span className="text-muted fst-italic ms-1">Loading...</span></span>}</span>}
                <Select
                    placeholder="Type name min 3 character"
                    className="border-0"
                    options={options}
                    onInputChange={FindData}
                    inputValue={inputValue}
                    onChange={handleSelected}
                    value={selectedValue}
                    isDisabled={disabled}
                    styles={customStyles}
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    isClearable
                />
            </div>
            {result.message && <AlertNotif message={result.message} color="danger" />}
        </>
    )
}

const SpinnerLoad = () => {
    return (
        <div className="spinner spinner-primary"></div>
    )
}

const DeptSemesLookUp = ({ data, setData }) => {
    const [activeTerm, setActiveTerm] = useState("");
    const [semesSelect, setSemesSelect] = useState("");
    const [deptSelect, setDeptSelect] = useState([]);
    const [semes, setSemes] = useState({ loading: true, message: "", data: [] });
    const ReloadData = async () => {
        setSemes({ loading: true, message: "", data: [] });
        const param_estes = {
            target_data: "GET_ACTIVE_ACAD",
        }
        const result = await REQUEST_TARGET_MODULES(param_estes);
        if (result.data && Object.values(result.data).length > 0) {
            const typeIntake = result.data[0].atp_term_next.atp_type;
            const typeIntakeArr = typeIntake.split(".");
            const typeName = typeIntakeArr[Object.values(typeIntakeArr).length - 1].toLowerCase();
            setActiveTerm(typeName + " Term");
            const param_sys = { tipe: "std-representative", name: typeName };
            const result_sys = await GET_SYS_PARAM(param_sys);
            setSemes(result_sys);
        } else {
            setSemes(result);
        }
    };

    useEffect(() => {
        ReloadData();
    }, [])

    useEffect(()=>{
        const find_by_semes = semes.data.find(item => item.semes === semesSelect)
        if(find_by_semes && Object.values(find_by_semes).length > 0)
            setDeptSelect(find_by_semes.dept);
    },[semesSelect])

    if (semes.loading) {
        return <div className="text-center p-5"><SpinnerLoad /></div>
    } else if (semes.message) {
        return <AlertNotif message={semes.message} />
    } else if(Object.values(semes.data).length > 0){
        return (
            <div className="row">
                <div className="col-auto">
                    <div className="form-group mb-0  d-flex align-items-center justify-content-start border rounded p-3">
                        <i className="fa far fa-calendar-alt icon-1 text-dark-50 mr-2"></i>
                        <div className="text-capitalize">
                            {"Active Calendar: "+activeTerm}
                        </div>
                    </div>
                </div>
                <div className="col-auto px-0">
                    <div className="form-group mb-0  d-flex align-items-center justify-content-center border rounded px-3">
                        <i className="fas fa-graduation-cap icon-1 text-dark-50"></i>
                        <select
                            name="semes"
                            className="form-control border-0"
                            required
                            value={semesSelect}
                            onChange={(e) => setSemesSelect(parseInt(e.target.value))}
                        >
                            <option value="">Choose Semester</option>
                            {Object.values(semes.data).length > 0 &&
                                semes.data.map((s, index) => (
                                    <option key={index} value={s.semes}>
                                        Semester {s.semes}
                                    </option>
                                ))}
                        </select>
                    </div>
                </div>
                <div className="col-auto">
                    <div className="form-group mb-0  d-flex align-items-center justify-content-center border rounded px-3">
                        <i className="far fa-user-circle icon-1 text-dark-50"></i>
                        <select
                            name="dept"
                            className="form-control border-0"
                            required
                            value={data}
                            onChange={(e) => setData(e.target.value)}
                        >
                            <option value="">Choose Department</option>
                            {Object.values(deptSelect).length > 0 &&
                                deptSelect.map((s, index) => (
                                    <option key={index} value={s.stdid}>
                                        {s.code}
                                    </option>
                                ))}
                        </select>
                    </div>
                </div>
            </div>

        )
    }
}

const GET_SYS_PARAM = async (param) => {
    var keys = {
        AppId: AuthDecode.AppID,
        AppEnv: AuthDecode.AppEnv,
        Secretkey: AuthDecode.secretkey,
    };
    const postData = {
        keys: keys,
        body: param,
    };

    const result = await AxiosLibWithHeader2(postData, "mc/api/sys-param").then((response) => {
        if (response.data) {
            const data = response.data;
            const conv = CleansingJSON(data);
            return { message: "", loading: false, data: conv };
        } else if (response.error) {
            return { data: [], loading: false, message: response.error };
        } else {
            return { data: [], loading: false, message: "No record found" };
        }
    });

    return result;
};

export { EmployeeSelect, DeptSemesLookUp }