import React from 'react'

export default function TablePCFRevision({ pcf, totalsks, data }) {
    return (
        <div className="table-responsive">
            <table className="table table-striped">
                <thead>
                    <tr className="font-weight-boldest h-65px" style={{ backgroundColor: "#474bcf" }}>
                        <th className="align-middle font-size-h4 text-white border-0" width={"5%"}>No</th>
                        <th className="align-middle font-size-h4 text-white border-0">Course</th>
                        <th className="align-middle font-size-h4 text-white border-0">Type/Credit</th>
                        <th className="align-middle font-size-h4 text-white border-0">Section ID</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.values(pcf).length > 0 ? (
                        pcf.map((v, index) => (
                            <tr key={index} className="font-size-lg font-weight-bolder h-65px">
                                <td>{index + 1}</td>
                                <td>
                                    <span className="d-block font-weight-bolder">
                                        {v.detail.coursetitle ? v.detail.coursetitle : ""}
                                    </span>
                                    <span className="text-muted font-weight-bolder">
                                        {v.detail.course_code ? v.detail.course_code : "-"}
                                    </span>
                                </td>
                                <td>
                                    <span className="d-block text-capitalize font-weight-bolder">
                                        Credit {v.detail.course_credit ? v.detail.course_credit : ""} SKS
                                    </span>
                                    <span className="text-muted text-capitalize font-weight-bolder">
                                        {v.detail.course_type ? v.detail.course_type : ""}
                                    </span>
                                </td>
                                <td>
                                    <span className="font-weight-bolder">
                                        {v.detail.section_id ? v.detail.section_id : ""}
                                    </span>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={5}>No record found</td>
                        </tr>
                    )}
                </tbody>
                {totalsks > 0 && (
                    <tfoot>
                        <tr>
                            <td colSpan={2} className="font-weight-bolder text-center">Total Credit: {totalsks} SKS</td>
                            <td colSpan={2} className="font-weight-bolder text-center">
                                {(data && Object.values(data).length > 0) && (
                                    <span> Limit Credit: {data.max_sks} SKS </span>
                                )}
                            </td>
                        </tr>
                    </tfoot>
                )}
            </table>
        </div>
    )
}
