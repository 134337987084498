import jwt_decode from "jwt-decode";
import { staging_menu_lect, staging_menu_std } from "../Headers/Aside/ConstMenu";
import AxiosLib from "../../Library/AxiosLib";
import AxiosLibWithHeader2 from "../../Library/AxiosLibWithHeader2";


var AuthToken = localStorage.getItem("AuthToken");
const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

const FetchMenu = (setMenu) => {
    setMenu({ loading: true, message: "", data: [] })
    var dataParam = {
        UserID: AuthDecode.currentData.entityid,
        Type: 1,
        ParentID: 0,
        RoleID: AuthDecode.currentData.roleid,
    };
    if (AuthDecode.currentData.empid) {
        dataParam.EmpID = AuthDecode.currentData.empid;
    }
    AxiosLib(dataParam, "mc/api/action/fetch/navigation").then(
        (response) => {
            if (response.error) {
                setMenu({ loading: false, message: response.error, data: [] })
            } else {
                if (response.data) {
                    let results = response.data;
                    // let current_menu = results.Academic;
                    // if (AuthDecode.TypeCK === "development") {
                    //     let acadmenu = [...current_menu];
                    //     let acad_Menu = [];

                    //     if (AuthDecode.currentData.roleid === 1) {
                    //         staging_menu_std.map(item => {
                    //             acad_Menu.push(item)
                    //         })

                    //         if (Object.values(acad_Menu).length > 0) {
                    //             acad_Menu.forEach(element => {
                    //                 acadmenu.push(element);
                    //             });
                    //         }

                    //         results.Academic = acadmenu;
                    //     } else {
                    //         staging_menu_lect.map(item => {
                    //             acad_Menu.push(item)
                    //         })

                    //         if (Object.values(acad_Menu).length > 0) {
                    //             acad_Menu.forEach(element => {
                    //                 acadmenu.push(element);
                    //             });
                    //         }

                    //         results.Academic = acadmenu;
                    //     }
                    // }
                    setMenu({ loading: false, message: "", data: results })
                } else {
                    setMenu({ loading: false, message: "No record found", data: [] })
                }
            }
        }
    )
}

const CheckIsLais = (setLais, urlsurvey) => {
    setLais({ loading: true, data: 0, message: "" })
    var keys = {
        AppId: AuthDecode.AppID,
        AppEnv: AuthDecode.AppEnv,
        Secretkey: AuthDecode.secretkey,
    };
    var body = {
        parameters: {
            studentid: AuthDecode.currentData.entityid,
        },
        target_data: "ASC_IS_LAISREQUIRED",
    };
    const postData = {
        keys: keys,
        body: body,
    };

    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
        if (response.error) {
            setLais({ loading: false, data: 0, message: response.error })
        } else if ("message" in response.response) {
            setLais({ loading: false, data: 0, message: response.response.message.user })
        } else if (response.result === "success") {
            if (response.response.data > 0) {
                var value = response.response.data;
                if (value === 1) {
                    setLais({ loading: false, data: value, message: "" })
                    setTimeout(() => {
                        window.location.replace(urlsurvey);
                    }, 2000);
                }else{
                    setLais({ loading: false, data: 0, message: "" })
                }
            }else{
                setLais({ loading: false, data: 0, message: "" })
            }            
        }else{
            setLais({ loading: false, data: 0, message: "" })
        }
    });
};

const CheckSurvey = (setSurvey, urlsurvey, studentid) => {
    setSurvey({ loading: true, data: 0, message: "" })
    var keys = {
        AppId: AuthDecode.AppID,
        AppEnv: AuthDecode.AppEnv,
        Secretkey: AuthDecode.secretkey,
    };
    var body = {
        parameters: {
            studentid: AuthDecode.currentData.entityid,
        },
        target_data: "ASC_IS_INTERNSHIPSURVEYREQUIRED",
    };
    const postData = {
        keys: keys,
        body: body,
    };

    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
        //console.log(response);
        if (response.error) {
            setSurvey({ loading: false, data: 0, message: response.error })
        } else if ("message" in response.response) {
            setSurvey({ loading: false, data: 0, message: response.response.message.user })
        } else if (response.result === "success") {
            if (Object.values(response.response.data).length > 0) {
                let uri = urlsurvey;
                let res = response.response.data;
                if (res.required === 1) {
                    if (res.ddegree === 1) {
                        uri += "internship/internshipDouble?pg=" + studentid;
                    }
                    uri += "internship/internshipSingle?pg=" + studentid;

                    setSurvey({ loading: false, data: res.required , message: "" })
                    setTimeout(() => {
                        window.location.replace(uri);
                    }, 2000);
                } else {
                    setSurvey({ loading: false, data: 0, message: "" })
                }
            } else {
                setSurvey({ loading: false, data: 0, message: "" })
            }
        }else{
            setSurvey({ loading: false, data: 0, message: "" })
        }
    });
};

export { FetchMenu, CheckIsLais, CheckSurvey }