import React, { useState, useMemo } from "react";
import Moment from "moment";
import jwt_decode from "jwt-decode";
import { TableHeader } from "../../../../../Library/DataTables";
import { StudentIDFormat } from "../../../../../Library/GlobalHelper";

export default function TableData(props) {
    const listData = props.data;
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

    /*==DATATABELS CONFIG==*/
    const [ITEMS_PER_PAGE, setTotalShow] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const headers = [
        { name: "No", field: "session", sortable: false },
        { name: "Section ID", field: "start_dt", sortable: false },
        { name: "Student", field: "emp", sortable: true },
        { name: "Department", field: "status", sortable: false },
        { name: "Defense Date", field: "status", sortable: false },
        { name: "Advisor", field: "status", sortable: false },
        { name: "Co Advisor", field: "status", sortable: false },
        { name: "Action", field: "section_id", sortable: false },
    ];

    const ResultData = useMemo(() => {
        let computedData = listData;

        if (computedData.length > 0) {
            computedData = computedData.map((v, index) => {
                var obj = {};
                obj = v;
                obj.num = index + 1;
                return obj;
            })
        }

        if (search) {
            computedData = computedData.filter((listData) => {
                return Object.keys(listData).some((key) =>
                    listData[key].toLowerCase().includes(search.toLowerCase())
                );
            });
        }
        setTotalItems(computedData.length);

        //Sorting listDatas
        if (sorting.field) {
            const reversed = sorting.order === "desc" ? 1 : -1;
            computedData = computedData.sort(
                (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        // if (computedData.length > 0) {
        //   return computedData.slice(
        //     (currentPage - 1) * ITEMS_PER_PAGE,
        //     (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        //   );
        // } else {
        //   return [];
        // }

        return computedData;
    }, [listData, currentPage, search, sorting]);
    /*==END DATATABELS CONFIG==*/

    const momentTime = (time) => {
        const [hours, minutes, seconds] = time.split(':');
        const timeMoment = Moment({ hours, minutes, seconds });
        const formattedTime = timeMoment.format("h:mm a");
        return (
            formattedTime
        );
    };
    let currDate = Moment(new Date()).add(15, "minute");

    return (
        <div>
            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                <TableHeader
                    headers={headers}
                    onSorting={(field, order) => setSorting({ field, order })}
                />
                <tbody>
                    {ResultData.length > 0 ? (
                        ResultData.map((v, index) => (
                            <tr key={index}>
                                <td>
                                    <span className="text-dark-75 font-weight-bolder mb-1 font-size-lg">
                                        {v.num}
                                    </span>
                                </td>
                                <td>{v.section_id}</td>
                                <td>
                                    <span className="text-dark-75 font-weight-bolder mb-1 font-size-lg d-block">{v.studentdata.fullname}</span>
                                    <span className="text-dark-50 font-weight-bolder mb-1 font-size-sm d-block">
                                        {v.studentid && StudentIDFormat(v.studentid)}
                                    </span>
                                    <span className="text-dark-50 font-weight-bolder mb-1 font-size-sm d-block">
                                        {v.studentdata.sgumail}
                                    </span>
                                </td>
                                <td>
                                    <span className="text-dark-75 font-weight-bolder mb-1 font-size-lg d-block">{v.studentdata.clu.name}</span>
                                    
                                </td>
                                <td> <span className="text-dark-75 font-weight-bolder mb-1 font-size-lg d-block">{(v.defensedate) ? Moment(v.defensedate).format("DD MMM YYYY") : "-"}</span>
                                <span className="text-dark-50 font-weight-bolder mb-1 font-size-sm d-block">
                                       Time : {(v.defensetime) ? momentTime(v.defensetime) : "-"} 
                                    </span>
                                    <span className="text-dark-50 font-weight-bolder mb-1 font-size-sm d-block">
                                       Room : {(v.room) ? v.room.name : "-"}
                                    </span>
                                </td>
                                <td>
                                    <span className="text-dark-75 font-weight-bolder mb-1 font-size-lg d-block">{(v.advisor) ? v.advisor.fullname : "-"}</span>
                                    <span className="text-dark-50 font-weight-bolder mb-1 font-size-sm d-block">
                                        {(v.advisor) ? v.advisor.email : "-"}
                                    </span>
                                </td>
                                <td>
                                    <span className="text-dark-75 font-weight-bolder mb-1 font-size-lg d-block">{(v.coadvisor) ? v.coadvisor.fullname : "-"}</span>
                                    <span className="text-dark-50 font-weight-bolder mb-1 font-size-sm d-block">
                                        {(v.coadvisor) ? v.coadvisor.email : "-"}
                                    </span>
                                </td>
                               
                                <td width="15px" className="text-center">
                                    <div className="btn-group">
                                        <button
                                            className="btn btn-icon btn-light btn-sm"
                                            type="button"
                                            title="Detail"
                                            onClick={() => props.setSelectedSession(v)}
                                        >
                                            <span className="svg-icon svg-icon-md svg-icon-primary">
                                                <svg
                                                    width="24px"
                                                    height="24px"
                                                    viewBox="0 0 24 24"
                                                    version="1.1"
                                                >
                                                    <g
                                                        stroke="none"
                                                        strokeWidth="1"
                                                        fill="none"
                                                        fillRule="evenodd"
                                                    >
                                                        <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                        <rect
                                                            fill="#000000"
                                                            opacity="0.3"
                                                            transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)"
                                                            x="11"
                                                            y="5"
                                                            width="2"
                                                            height="14"
                                                            rx="1"
                                                        ></rect>
                                                        <path
                                                            d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                                                            fill="#000000"
                                                            fillRule="nonzero"
                                                            transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
                                                        ></path>
                                                    </g>
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="10">No record found</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}
