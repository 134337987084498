import React, { useEffect, useState, useRef } from "react";
import jwt_decode from "jwt-decode";
import Moment from "moment";
import AxiosLibWithHeader2 from "../../../../Library/AxiosLibWithHeader2";
import Logo1 from "../../../../../media/logos/logo-sgu.png";
import Logo2 from "../../../../../media/logos/logo-sgu-name.png";
import "./ThesisRevMulti.css";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { StudentIDFormat } from "../../../../Library/GlobalHelper";


export default function ThesisRevMulti(props) {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];
    const listdata = props.data;
    const stdid=(listdata.studentid) ? listdata.studentid : "";
    const selectedExaminer = props.selectedExaminer;
    const downloadRev = props.downloadRev;
    const setDownloadRev = props.setDownloadRev;
    const [stdThesis, setStdSThesis] = useState({
        loading: false,
        message: "",
        data: [],
    });
    const [thesisRev, setThesisRev] = useState([])
    const GET_THS_BY_STUDENT = () => {
        setStdSThesis({ ...stdThesis, loading: true, data: [], message: "" });
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };
        var body = {
            parameters: {
                studentid: AuthDecode.currentData.entityid,
            },
            target_data: "GET_THS_BY_STUDENT",
        };
        const postData = {
            keys: keys,
            body: body,
        };
        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                setStdSThesis({
                    data: [],
                    message: response.error,
                    loading: false,
                });
            } else if ("message" in response.response) {
                var msg = response.response.message.user;
                setStdSThesis({
                    data: [],
                    message: msg,
                    loading: false,
                });
            } else {
                var results = response.response.data;
                console.log(results);
                var ths_main_exam = (Object.values(results.ths_main_ex).length > 0 ? results.ths_main_ex : []);
                if (results) {
                    setStdSThesis({
                        data: results,
                        message: "",
                        loading: false,
                    });
                    const filteredArray = ths_main_exam.filter(item =>
                        item.revision !== null && item.revision !== "" && item.revision !== undefined
                    );
                    setThesisRev(filteredArray);
                    console.log(filteredArray);
                } else {
                    setStdSThesis({
                        data: [],
                        message: "No record found",
                        loading: false,
                    });
                }
            }
        });
    };

    useEffect(() => {
        GET_THS_BY_STUDENT();
    }, []);
    useEffect(() => {
        if (downloadRev) {
            handleDownloadPdf();
        }

    }, [downloadRev]);

    const [isGenerating, setIsGenerating] = useState(false);
    const printRef1 = useRef();
    const printRefLast = useRef();
    const printRef = useRef();

    const handleDownloadPdf = async () => {
        setIsGenerating(true);
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'pt',
            format: 'a4',
        });

        // Compression function
        const compressImage = async (canvas, quality) => {
            const imgData = canvas.toDataURL('image/jpeg', quality); // Adjust quality
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

            return { imgData, pdfWidth, pdfHeight };
        };

        if(thesisRev.length ===1){
            for (let i = 0; i < thesisRev.length; i++) {
                const thesisRevisi = thesisRev[i];
    
                // Update the content inside the pdf-container dynamically
                if (i === 0) {
                    printRef.current.innerHTML = `
                   <div class="card " id="card-revision">
                        <div class="card-body">
                            <div class="t-header">
                                <table class="w-100">
                                    <tr>
                                        <td>
                                            <img src=${Logo1} alt="Logo SGU" width=${150} height=${50} />
                                        </td>
                                        <td class="text-right align-bottom">
                                            <img src=${Logo2} alt="Logo SGU" width=${350} height=${30} />
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div
                                class="t-body pt-2 mt-2"
                                style="border-top: 1px solid #32499c"
                            ></div>
                            <div class=" border-bottom border-dark pb-3">
                                <div class="m-0 h2 z-index-2 font-weight-bolder ">
                                    Thesis Revision Page - ${(stdid[0]==='1') ? "Bachelor's Degree" : "Master's Degree"} 
                                </div>
                            </div>
    
                            <div class="profile border-bottom border-dark pt-3 pb-3" >
                                <table class="w-100 font-weight-bold">
                                    <thead>
                                        <tr>
                                            <td width="15%">Student's Name</td>
                                            <td width="1%">:</td>
                                            <td width="50%">${(listdata.studentdata) ? listdata.studentdata.fullname : ""}</td>
                                        </tr>
                                        <tr>
                                            <td width="15%">Student's ID</td>
                                            <td width="1%">:</td>
                                            <td width="50%">${(listdata.studentid) ? StudentIDFormat(listdata.studentid) : ""}</td>
                                        </tr>
                                        <tr>
                                            <td width="15%">Faculty/Study Program</td>
                                            <td width="1%">:</td>
                                            <td width="50%">${(listdata.studentdata) ? listdata.studentdata.clu.name : ""}</td>
                                        </tr>
                                        <tr>
                                            <td width="15%">Advisor</td>
                                            <td width="1%">:</td>
                                            <td width="50%">${(listdata.advisor) ? listdata.advisor.fullname : ""}</td>
                                        </tr>
                                        <tr>
                                            <td width="15%">Co-Advisor</td>
                                            <td width="1%">:</td>
                                            <td width="50%">${(listdata.coadvisor) ? listdata.coadvisor.fullname : ""}</td>
                                        </tr>
                                    </thead>
                                </table>
    
                            </div>
    
                            <div class=" border-bottom border-dark pb-3 pt-3">
                                <table>
                                    <tr><td width="15%">
                                        <div class="m-0 ttle  font-weight-bolder align-top">
                                            Thesis Title
                                        </div></td>
                                        <td width="1%"> <div class="m-0 ttle  font-weight-bolder align-top">
                                            :
                                        </div></td>
                                        <td width="50%">
                                            <div class="m-0 font-weight-bolder align-top">
                                                ${(listdata.final_title) ? listdata.final_title : ""}
                                            </div>
                                        </td>
                                    </tr>
    
                                </table>
    
                            </div>
                            <div
                                class="transcript py-2"
                                style="max-height: 700px; overflow: auto ;"
                            >
                                <div class="fw-bold text-dark mt-0">
                                    Date of Defense :  ${Moment(listdata.defensedate).format("DD-MMM-YYYY")}
                                </div>
                                <div class="fw-bold text-dark mt-0 ">
                                    Deadline Submission of Revised Thesis Work after Thesis Defense :  ${Moment(listdata.duedate).format("DD-MMM-YYYY")}
                                </div>
                                <div class="fw-bold text-dark mt-4 ">
                                    Please revise your final thesis work according to the notes from the Examiners (if any). You must submit your revised thesis work two weeks after your Thesis Defense, otherwise you are not yet eligible to graduate.
                                </div>
                                <div class="fw-bold text-dark mt-4 ">
                                    Points to be revised :
                                </div>
                                <div class="fw-bold text-dark mt-0 " id="rev-noted1">
                                    <p>${thesisRevisi.emp.fullname} :</p>
                                     ${decodeHtml(thesisRevisi.revision) ? thesisRevisi.revision : ""}
                                    
                                </div>
    
    
    
                            </div>
    
    
                            <div class="footer py-2">
                                <div
                                    class="address py-2"
                                    style="border-top: 1px solid #32499c"
                                >
                                    <table class="w-100">
                                        <tr>
                                            <th>SWISS GERMAN UNIVERSITY</th>
                                            <td align="right">
                                                <b>Tel.</b> +62 21 2977 9596/9597
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>The Prominence Tower</td>
                                            <td align="right">
                                                <b>Fax.</b> +62 21 2977 9598
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Jalan Jalur Sutera Barat No.15, Alam Sutera</td>
                                            <td align="right">
                                                <ins class="text-primary">academic@sgu.ac.id</ins>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Tangerang, Banten 15143 - Indonesia</td>
                                            <td align="right">
                                                <ins class="text-primary">www.sgu.ac.id</ins>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                `;
                }
                // Generate canvas
                const canvas = await html2canvas(printRef.current, { scale: 2 }); // Increase scale for better quality
    
                // Compress image
                const { imgData, pdfWidth, pdfHeight } = await compressImage(canvas, 0.8); // Adjust quality
                pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
    
                // Add a new page if it's not the last employee
                if (i < thesisRev.length - 2) {
                    pdf.addPage();
                }
    
    
            }
            
        }
        else{
        for (let i = 0; i < thesisRev.length - 1; i++) {
            const thesisRevisi = thesisRev[i];

            // Update the content inside the pdf-container dynamically
            if (i === 0) {
                printRef.current.innerHTML = `
               <div class="card " id="card-revision">
                    <div class="card-body">
                        <div class="t-header">
                            <table class="w-100">
                                <tr>
                                    <td>
                                        <img src=${Logo1} alt="Logo SGU" width=${150} height=${50} />
                                    </td>
                                    <td class="text-right align-bottom">
                                        <img src=${Logo2} alt="Logo SGU" width=${350} height=${30} />
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div
                            class="t-body pt-2 mt-2"
                            style="border-top: 1px solid #32499c"
                        ></div>
                        <div class=" border-bottom border-dark pb-3">
                            <div class="m-0 h2 z-index-2 font-weight-bolder ">
                                Thesis Revision Page - ${(stdid[0]==='1') ? "Bachelor's Degree" : "Master's Degree"} 
                            </div>
                        </div>

                        <div class="profile border-bottom border-dark pt-3 pb-3" >
                            <table class="w-100 font-weight-bold">
                                <thead>
                                    <tr>
                                        <td width="15%">Student's Name</td>
                                        <td width="1%">:</td>
                                        <td width="50%">${(listdata.studentdata) ? listdata.studentdata.fullname : ""}</td>
                                    </tr>
                                    <tr>
                                        <td width="15%">Student's ID</td>
                                        <td width="1%">:</td>
                                        <td width="50%">${(listdata.studentid) ? listdata.studentid : ""}</td>
                                    </tr>
                                    <tr>
                                        <td width="15%">Faculty/Study Program</td>
                                        <td width="1%">:</td>
                                        <td width="50%">${(listdata.studentdata) ? listdata.studentdata.clu.name : ""}</td>
                                    </tr>
                                    <tr>
                                        <td width="15%">Advisor</td>
                                        <td width="1%">:</td>
                                        <td width="50%">${(listdata.advisor) ? listdata.advisor.fullname : ""}</td>
                                    </tr>
                                    <tr>
                                        <td width="15%">Co-Advisor</td>
                                        <td width="1%">:</td>
                                        <td width="50%">${(listdata.coadvisor) ? listdata.coadvisor.fullname : ""}</td>
                                    </tr>
                                </thead>
                            </table>

                        </div>

                        <div class=" border-bottom border-dark pb-3 pt-3">
                            <table>
                                <tr><td width="15%">
                                    <div class="m-0 ttle  font-weight-bolder align-top">
                                        Thesis Title
                                    </div></td>
                                    <td width="1%"> <div class="m-0 ttle  font-weight-bolder align-top">
                                        :
                                    </div></td>
                                    <td width="50%">
                                        <div class="m-0 font-weight-bolder align-top">
                                            ${(listdata.final_title) ? listdata.final_title : ""}
                                        </div>
                                    </td>
                                </tr>

                            </table>

                        </div>
                        <div
                            class="transcript py-2"
                            style="max-height: 700px; overflow: auto ;"
                        >
                            <div class="fw-bold text-dark mt-0">
                                Date of Defense :  ${Moment(listdata.defensedate).format("DD-MMM-YYYY")}
                            </div>
                            <div class="fw-bold text-dark mt-0 ">
                                Deadline Submission of Revised Thesis Work after Thesis Defense :  ${Moment(listdata.duedate).format("DD-MMM-YYYY")}
                            </div>
                            <div class="fw-bold text-dark mt-4 ">
                                Please revise your final thesis work according to the notes from the Examiners (if any). You must submit your revised thesis work two weeks after your Thesis Defense, otherwise you are not yet eligible to graduate.
                            </div>
                            <div class="fw-bold text-dark mt-4 ">
                                Points to be revised :
                            </div>
                            <div class="fw-bold text-dark mt-0 " id="rev-noted1">
                                <p>${thesisRevisi.emp.fullname} :</p>
                                 ${decodeHtml(thesisRevisi.revision) ? thesisRevisi.revision : ""}
								
                            </div>



                        </div>


                        <div class="footer py-2">
                            <div
                                class="address py-2"
                                style="border-top: 1px solid #32499c"
                            >
                                <table class="w-100">
                                    <tr>
                                        <th>SWISS GERMAN UNIVERSITY</th>
                                        <td align="right">
                                            <b>Tel.</b> +62 21 2977 9596/9597
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>The Prominence Tower</td>
                                        <td align="right">
                                            <b>Fax.</b> +62 21 2977 9598
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Jalan Jalur Sutera Barat No.15, Alam Sutera</td>
                                        <td align="right">
                                            <ins class="text-primary">academic@sgu.ac.id</ins>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Tangerang, Banten 15143 - Indonesia</td>
                                        <td align="right">
                                            <ins class="text-primary">www.sgu.ac.id</ins>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            `;
            } else {

                printRef.current.innerHTML = `
            <div class="card " id="card-revision">
                 <div class="card-body">
                     <div class="t-header">
                         <table class="w-100">
                             <tr>
                                 <td>
                                     <img src=${Logo1} alt="Logo SGU" width=${150} height=${50} />
                                 </td>
                                 <td class="text-right align-bottom">
                                     <img src=${Logo2} alt="Logo SGU" width=${350} height=${30} />
                                 </td>
                             </tr>
                         </table>
                     </div>
                     <div
                         class="t-body pt-2 mt-2"
                         style="border-top: 1px solid #32499c"
                     ></div>

                     
                     <div
                         class="transcript py-2"
                         style="max-height: 800px; overflow: auto ;"
                     >
                         <div class="fw-bold text-dark mt-0">
                             Date of Defense :  ${Moment(listdata.defensedate).format("DD-MMM-YYYY")}
                         </div>
                         <div class="fw-bold text-dark mt-0 ">
                             Deadline Submission of Revised Thesis Work after Thesis Defense :  ${Moment(listdata.duedate).format("DD-MMM-YYYY")}
                         </div>
                         <div class="fw-bold text-dark mt-4 ">
                             Please revise your final thesis work according to the notes from the Examiners (if any). You must submit your revised thesis work two weeks after your Thesis Defense, otherwise you are not yet eligible to graduate.
                         </div>
                         <div class="fw-bold text-dark mt-4 ">
                             Points to be revised :
                         </div>
                         <div class="fw-bold text-dark mt-0 " id="rev-noted1">
                             <p>${thesisRevisi.emp.fullname} :</p>
                              ${decodeHtml(thesisRevisi.revision) ? thesisRevisi.revision : ""}
                             
                         </div>



                     </div>


                     <div class="footer py-2">
                         <div
                             class="address py-2"
                             style="border-top: 1px solid #32499c"
                         >
                             <table class="w-100">
                                 <tr>
                                     <th>SWISS GERMAN UNIVERSITY</th>
                                     <td align="right">
                                         <b>Tel.</b> +62 21 2977 9596/9597
                                     </td>
                                 </tr>
                                 <tr>
                                     <td>The Prominence Tower</td>
                                     <td align="right">
                                         <b>Fax.</b> +62 21 2977 9598
                                     </td>
                                 </tr>
                                 <tr>
                                     <td>Jalan Jalur Sutera Barat No.15, Alam Sutera</td>
                                     <td align="right">
                                         <ins class="text-primary">academic@sgu.ac.id</ins>
                                     </td>
                                 </tr>
                                 <tr>
                                     <td>Tangerang, Banten 15143 - Indonesia</td>
                                     <td align="right">
                                         <ins class="text-primary">www.sgu.ac.id</ins>
                                     </td>
                                 </tr>
                             </table>
                         </div>
                     </div>
                 </div>
             </div>
         `;
            }
            // Generate canvas
            const canvas = await html2canvas(printRef.current, { scale: 2 }); // Increase scale for better quality

            // Compress image
            const { imgData, pdfWidth, pdfHeight } = await compressImage(canvas, 0.8); // Adjust quality
            pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);

            // Add a new page if it's not the last employee
            if (i < thesisRev.length - 2) {
                pdf.addPage();
            }


        }
    }
        // Add a new page
        pdf.addPage();

        // Second Page
        const canvas2 = await html2canvas(printRefLast.current, { scale: 2 }); // Increase scale for better quality
        const { imgData: imgData2, pdfWidth: pdfWidth2, pdfHeight: pdfHeight2 } = await compressImage(canvas2, 0.8); // Adjust quality
        pdf.addImage(imgData2, 'JPEG', 0, 0, pdfWidth2, pdfHeight2);
        // Save the PDF
        pdf.save('Thesis-revision.pdf');
        setIsGenerating(false);
        setDownloadRev(false);
    };
    // Decode the revision HTML if needed
    const decodeHtml = (html) => {
        const txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    };

    return (
        <div className="row">
            <div className="col-12">
                <div className="pdf-container" ref={printRef}>
                    <div className="card " id="card-revision">
                        <div className="card-body">
                            <div className="t-header">
                                <table className="w-100">
                                    <tr>
                                        <td>
                                            <img src={Logo1} alt="Logo SGU" width={150} height={50} />
                                        </td>
                                        <td className="text-right align-bottom">
                                            <img src={Logo2} alt="Logo SGU" width={350} height={30} />
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div
                                className="t-body pt-2 mt-2"
                                style={{ borderTop: "1px solid #32499c" }}
                            ></div>

                            <div
                                className="transcript py-2"
                                style={{ maxHeight: "800px", overflow: "auto" }}
                            >

                                <div className="fw-bold text-dark mt-0">
                                The revision note will be automatically filled when the download button is pressed.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pdf-container" ref={printRefLast}>
                    <div className="card " id="card-revision2">
                        <div className="card-body">
                            <div className="t-header">
                                <table className="w-100">
                                    <tr>
                                        <td>
                                            <img src={Logo1} alt="Logo SGU" width={150} height={50} />
                                        </td>
                                        <td className="text-right align-bottom">
                                            <img src={Logo2} alt="Logo SGU" width={350} height={30} />
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div
                                className="t-body pt-2 mt-2"
                                style={{ borderTop: "1px solid #32499c" }}
                            ></div>

                            <div
                                className="transcript py-2"
                                style={{ maxHeight: "800px", overflow: "auto" }}
                            >

                                <div className="fw-bold text-dark mt-0">
                                    Date of Defense :  {Moment(listdata.defensedate).format("DD-MMM-YYYY")}
                                </div>
                                <div className="fw-bold text-dark mt-0 ">
                                    Deadline Submission of Revised Thesis Work after Thesis Defense :  {Moment(listdata.duedate).format("DD-MMM-YYYY")}
                                </div>
                                <div className="fw-bold text-dark mt-4 ">
                                    Please revise your final thesis work according to the notes from the Examiners (if any). You must submit your revised thesis work two weeks after your Thesis Defense, otherwise you are not yet eligible to graduate.
                                </div>
                                <div className="fw-bold text-dark mt-4 ">
                                    Points to be revised :
                                </div>
                                <div className="fw-bold text-dark mt-0 " id="rev-noted2">
                                    {Object.values(thesisRev).length > 0 ? (
                                        Object.values(thesisRev).map((v, index, array) => (
                                            ((index === array.length - 1)?
                                                <>
                                                    {(v.revision && (v.revision.length > 1)) ?
                                                        <React.Fragment key={index}>
                                                            <p>{v.emp.fullname} :</p>
                                                            <div dangerouslySetInnerHTML={{ __html: decodeHtml(v.revision || "") }} />
                                                        </React.Fragment>
                                                        : ("")}
                                                </>
                                                : (""))
                                        ))
                                    ) : ""}
                                </div>



                            </div>


                            <div className="footer py-2">
                                <div className="expl  pb-1 py-1">

                                    <table className="w-100">
                                        <tr>
                                            <td width="60%" className="align-top bd-pinggir">
                                                <h5 className="text-dark m-0">Important Notes :</h5>
                                                <ol>
                                                    <li className="m-0">For further revision regarding the content, please contact your Thesis Advisor.</li>
                                                    <li className="m-0">Your thesis abstract must have keywords. Please check the sample available on OTS website, and discuss with your Thesis Advisor for the most appropriate keywords.</li>
                                                    <li className="m-0">This page is NOT to be bound with your thesis work.</li>
                                                    <li className="m-0">In order to receive the Letter of Academic Completion (Surat Keterangan Lulus), you must submit this page, signed by your Thesis Advisor & Co-Advisor (if any), through OTS together with your Revised Thesis Work, Separate SBTA, Power Point Presentation and Latest Plagiarism Result in PDF format.</li>
                                                </ol>
                                            </td>

                                            <td width="20%" className="align-top bd-pinggir">
                                                <p className="centertd">Thesis Advisor’s  Signature</p>
                                            </td>
                                            <td width="20%" className="align-top bd-pinggir">
                                                <p className="centertd">Thesis Co-Advisor’s  Signature</p>
                                            </td>

                                        </tr>
                                    </table>
                                </div>
                                <div
                                    className="address py-2"
                                    style={{ borderTop: "1px solid #32499c" }}
                                >
                                    <table className="w-100">
                                        <tr>
                                            <th>SWISS GERMAN UNIVERSITY</th>
                                            <td align="right">
                                                <b>Tel.</b> +62 21 2977 9596/9597
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>The Prominence Tower</td>
                                            <td align="right">
                                                <b>Fax.</b> +62 21 2977 9598
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Jalan Jalur Sutera Barat No.15, Alam Sutera</td>
                                            <td align="right">
                                                <ins className="text-primary">academic@sgu.ac.id</ins>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Tangerang, Banten 15143 - Indonesia</td>
                                            <td align="right">
                                                <ins className="text-primary">www.sgu.ac.id</ins>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>


            </div>

        </div>
    );
}
