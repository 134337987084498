import React, { useMemo, useRef } from 'react'
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { openModal } from '../../../../Library/ModalPopUp';
import moment from 'moment';

export default function CalendarMonth(props) {
    const { data, initialDate, paramData, myschd } = props;
    const calendarRef = useRef();

    const combinedEvents = useMemo(() => {
        return [...data, ...myschd];
    }, [data, myschd]);

    return (
        <div>
            <FullCalendar
                ref={calendarRef}
                initialView="dayGridMonth"
                headerToolbar={{ start: '', center: '', end: '' }}
                plugins={[dayGridPlugin]}
                initialDate={initialDate}
                validRange={{ start: paramData.tgl, end: paramData.tgl_end }}
                events={combinedEvents}
                eventContent={(info) => eventRender(info)}
                eventDidMount={(info) => {
                    if (info.event.extendedProps.background) {
                        info.el.style.background = info.event.extendedProps.background;
                    }
                    if (info.event.extendedProps.border) {
                        info.el.style.borderColor = info.event.extendedProps.border;
                    }
                }}
            />
        </div>
    )
}

const eventRender = (data) => {
    let item = data.event;
    const start_dt = moment(item.start).format("hh:mm a");

    return (
        <div className={"fc-event-container cursor-pointer "} onClick={() => openModal({ header: item.title, message: <DetailEvent data={item.groupId} /> })} title={item.title}>
            <div className="fc-day-grid-event fc-start fc-end fc-event-solid-danger fc-event-light fc-draggable">
                <div className={"fc-content "}>
                    <span className="fc-title">
                        {start_dt}
                        <span className="font-weight-bold ml-1">
                            {item.title}
                        </span>
                    </span>
                </div>
            </div>
        </div>
    );
};

const DetailEvent = ({ data }) => {
    const item = JSON.parse(data);
    console.log(item);
    const start_dt = moment().format("D, MMM dd ⋅ hh:mm a");
    const end_dt = moment().format("hh:mm a");
    return (
        <div>
            {item.label && (
                <div className="form-group mb-5">
                    <h4>{item.label}</h4>
                </div>
            )}
            <div className="form-group mb-5">
                <span className="text-muted">Date and Time</span>
                <h4>{start_dt}-{end_dt}</h4>
            </div>
            <div className="form-group mb-5">
                <span className="text-muted">Section ID</span>
                <h4>{item.section_id}</h4>
            </div>
            <div className="form-group mb-5">
                <span className="text-muted">Rooms</span>
                <div>{item.event_room && Object.values(item.event_room).length > 0 ? (
                    <h4>{item.event_room.map((v, index) => (
                        <span key={index} className='mr-3'>{v?.room?.g_resourceName}({v?.room?.g_capacity})</span>
                    ))}</h4>
                ) : "-"}</div>
            </div>
            <div className="form-group mb-5">
                <span className="text-muted">Departments</span>
                <div>{item.section_pre && Object.values(item.section_pre).length > 0 ? (
                    <h4>{item.section_pre.map((v, index) => (
                        <span key={index} className='d-block'>{v?.clu?.code} {v.clu_spec && "(" + v.clu_spec?.name + ")"} Semester {v?.semester}</span>
                    ))}</h4>
                ) : "-"}</div>
            </div>
            <div className="form-group mb-5">
                <span className="text-muted">Lecturers</span>
                <div>{item.emp && Object.values(item.emp).length > 0 ? (
                    <h4>{item.emp.map((v, index) => (
                        <span key={index} className='d-block'>{v?.fullname}</span>
                    ))}</h4>
                ) : "-"}</div>
            </div>
        </div>
    )
};