import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import Moment from "moment";
import AxiosLibWithHeader2 from "../../../../Library/AxiosLibWithHeader2";
import { AlertNotif } from "../../../../Library/AlertNotif";
import LoadingBtn from "../../../Widgets/LoadingBtn";
import { StudentIDFormat } from "../../../../Library/GlobalHelper";

export default function ThesisScoreIndex(props) {
  var AuthToken = localStorage.getItem("AuthToken");
  const defenseData = props.defenseData;
  const setDt = props.setDt;
  const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];


  const [stdThesis, setStdSThesis] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const GET_THS_BY_STUDENT = () => {
    setStdSThesis({ ...stdThesis, loading: true, data: [], message: "" });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: {
        studentid: AuthDecode.currentData.entityid,
      },
      target_data: "GET_THS_BY_STUDENT",
    };
    const postData = {
      keys: keys,
      body: body,
    };
    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setStdSThesis({
          data: [],
          message: response.error,
          loading: false,
        });
      } else if ("message" in response.response) {
        var msg = response.response.message.user;
        setStdSThesis({
          data: [],
          message: msg,
          loading: false,
        });
      } else {
        var results = response.response.data;
        //console.log(results);
        if (results) {
          //console.log(results.defensedate);
          setDt(results.defensedate);
          setStdSThesis({
            data: results,
            message: "",
            loading: false,
          });



        } else {
          setStdSThesis({
            data: [],
            message: "No record found",
            loading: false,
          });
        }
      }
    });
  };

  useEffect(() => {
    GET_THS_BY_STUDENT();
  }, []);

  const examiner = (examine) => {
    return Object.values(examine).length > 0
      ? examine.map((v, index) => (
        <div className="row mb-2">
          <label className="col-lg-4 font-weight-bold h6">
            {index === 0 ? "Examiner" : ""}
          </label>
          <div className="col-lg-8">
            <span className="font-weight-bold text-gray-800 h6 text-capitalize">
              {v.emp.fullname}
            </span>
            <span className="font-weight-bold text-gray-800 h6 text-capitalize"></span>
          </div>
        </div>
      ))
      : "";
  };

  const momentTime = (time) => {
    if (time) {
      const [hours, minutes, seconds] = time.split(':');
      const timeMoment = Moment({ hours, minutes, seconds });
      const formattedTime = timeMoment.format("h:mm a");
      return (
        formattedTime
      );
    }
  };
  return (
    <div id="view-score-std">
      {stdThesis.loading ? (
        <LoadingBtn />
      ) : stdThesis.message ? (
        <AlertNotif
          message={stdThesis.message}
          color={"danger"}
          title={"Information"}
        />
      ) : Object.values(stdThesis.data).length > 0 ? (
        <div className="card card-custom gutter-b border-light">
          <div className="card-body">
            <div className="thesis-data">
              <h3 className="mb-6">Thesis Data</h3>
              <div className="row mb-3 ">
                <label className="col-lg-4 font-weight-bold h6">
                  Full Name
                </label>
                <div className="col-lg-8">
                  <span className="font-weight-bold text-gray-800 h6 text-capitalize">
                    {stdThesis.data.studentdata.fullname}
                  </span>
                </div>
              </div>
              <div className="row mb-3 ">
                <label className="col-lg-4 font-weight-bold h6">
                  Student ID
                </label>
                <div className="col-lg-8">
                  <span className="font-weight-bold text-gray-800 h6 text-capitalize">
                    {StudentIDFormat(stdThesis.data.studentid)}
                  </span>
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-lg-4 font-weight-bold h6">
                  Department
                </label>
                <div className="col-lg-8">
                  <span className="font-weight-bold text-gray-800 h6 text-capitalize">
                    {stdThesis.data.studentdata.clu.name}
                  </span>
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-lg-4 font-weight-bold h6">
                  Thesis Title
                </label>
                <div className="col-lg-8">
                  <span className="font-weight-bold text-gray-800 h6 text-capitalize">
                    {decodeURIComponent(stdThesis.data.final_title)}
                  </span>
                </div>
              </div>

              <div className="row mb-3">
                <label className="col-lg-4 font-weight-bold h6">
                  Defense Date
                </label>
                <div className="col-lg-8">
                  <span className="font-weight-bold text-gray-800 h6 text-capitalize">
                    {stdThesis.data.defensedate
                      ? Moment(stdThesis.data.defensedate).format(
                        "DD MMM YYYY"
                      )
                      : ""}
                  </span>
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-lg-4 font-weight-bold h6">
                  Time
                </label>
                <div className="col-lg-8">
                  <span className="font-weight-bold text-gray-800 h6 text-capitalize">
                    {stdThesis.data.defensetime
                      ? momentTime(stdThesis.data.defensetime)

                      : "-"}
                  </span>
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-lg-4 font-weight-bold h6">
                  Room
                </label>
                <div className="col-lg-8">
                  <span className="font-weight-bold text-gray-800 h6 text-capitalize">
                    {stdThesis.data.room
                      ? stdThesis.data.room.name

                      : "-"}
                  </span>
                </div>
              </div>

              <div className="row mb-3">
                <label className="col-lg-4 font-weight-bold h6">Term</label>
                <div className="col-lg-8">
                  <span className="font-weight-bold text-gray-800 h6 text-capitalize">
                    {
                      (stdThesis.data.course_detail && stdThesis.data.course_detail.length > 0)
                        ? (stdThesis.data.course_detail[stdThesis.data.course_detail.length - 1].atp_term
                          ? stdThesis.data.course_detail[stdThesis.data.course_detail.length - 1].atp_term.name || "-"
                          : "-")
                        : "-"
                    }
                  </span>
                  <span className="font-weight-bold text-gray-800 h6 text-capitalize">
                    {stdThesis.data.course_detail.score}
                  </span>
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-lg-4 font-weight-bold h6">
                  Semester
                </label>
                <div className="col-lg-8">
                  <span className="font-weight-bold text-gray-800 h6 text-capitalize">
                    {
                      (stdThesis.data.course_detail && stdThesis.data.course_detail.length > 0)
                        ? (stdThesis.data.course_detail[stdThesis.data.course_detail.length - 1].semester
                          ? stdThesis.data.course_detail[stdThesis.data.course_detail.length - 1].semester || "-"
                          : "-")
                        : "-"
                    }
                  </span>
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-lg-4 font-weight-bold h6">
                  Score / Grade
                </label>
                <div className="col-lg-8">
                  {(Moment(defenseData.dateNow).format("YYYY-MM-DD") > Moment(defenseData.defenseDate).format("YYYY-MM-DD"))
                    ? (
                      <>
                        <span className="font-weight-bold text-gray-800 h6 text-capitalize">
                          {
                            ((stdThesis.data.course_detail[
                              stdThesis.data.course_detail.length - 1
                            ].score) ? (stdThesis.data.course_detail[
                              stdThesis.data.course_detail.length - 1
                            ].score) : "-")
                          }{" "}
                          /{" "}
                        </span>
                        <span className="font-weight-bold text-gray-800 h6 text-capitalize">
                          {
                            ((stdThesis.data.course_detail[
                              stdThesis.data.course_detail.length - 1
                            ].grade_fin) ? (stdThesis.data.course_detail[
                              stdThesis.data.course_detail.length - 1
                            ].grade_fin) : "-")
                          }{" "}
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="font-weight-bold text-gray-800 h6 text-capitalize">
                          - / -
                        </span>
                      </>
                    )
                  }
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-lg-4 font-weight-bold h6">Status</label>
                <div className="col-lg-8">
                  {(Moment(defenseData.dateNow).format("YYYY-MM-DD") > Moment(defenseData.defenseDate).format("YYYY-MM-DD"))
                    ? (
                      <>
                        <span className="font-weight-bold text-gray-800 h6 text-capitalize">
                          {
                            stdThesis.data.course_detail[
                              stdThesis.data.course_detail.length - 1
                            ].course_status
                          }
                        </span>
                      </>
                    )
                    :
                    (
                      <span className="font-weight-bold text-gray-800 h6 text-capitalize">-</span>
                    )
                  }
                </div>
              </div>

              <div className="row mb-3">
                <label className="col-lg-4 font-weight-bold h6">
                  Advisor
                </label>
                <div className="col-lg-8">
                  <span className="font-weight-bold text-gray-800 h6 text-capitalize">
                    {(stdThesis.data.advisor ? (stdThesis.data.advisor.fullname ? stdThesis.data.advisor.fullname : "") : "")}
                  </span>
                  <span className="font-weight-bold text-gray-800 h6 text-capitalize"></span>
                </div>
              </div>

              <div className="row mb-3">
                <label className="col-lg-4 font-weight-bold h6">
                  Co Advisor
                </label>
                <div className="col-lg-8">
                  <span className="font-weight-bold text-gray-800 h6 text-capitalize">
                    {(stdThesis.data.coadvisor ? (stdThesis.data.coadvisor.fullname ? stdThesis.data.coadvisor.fullname : "") : "")}
                  </span>
                  <span className="font-weight-bold text-gray-800 h6 text-capitalize"></span>
                </div>
              </div>
              { /* examiner(stdThesis.data.ths_main_ex) */}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
