import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { DeptSemesLookUp, EmployeeSelect } from "./components/FormCalUI";
import moment from "moment";
import { REQUEST_TARGET_MODULES } from "../../../Library/APIsTarget";
import CalendarMonth from "./components/CalendarMonth";
import LoadingBtn from "../../Widgets/LoadingBtn";
import "./cal-style.css";
import { AlertNotif } from "../../../Library/AlertNotif";

var AuthToken = localStorage.getItem("AuthToken");
const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];
export function CalendarTimetables() {
  const empid = AuthDecode.identity.entityid;
  const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
  const endOfMonth = moment()
    .endOf("month")
    .add(7, "days")
    .format("YYYY-MM-DD");
  const paramPost = {
    empid: AuthDecode.currentData.entityid,
    tgl: startOfMonth,
    tglend: endOfMonth,
  };
  const [param_data, setParam_data] = useState(paramPost);
  const [typeSearch, setTypeSearch] = useState("");
  const [emp_id, setEmp_id] = useState("");
  const [stdRep, setStdRep] = useState("");
  const [myschd, setMyschd] = useState([]);
  const [myprofile, setMyProfile] = useState({ loading: false, data: [], message: "" });


  const LoadProfile = async () => {
    setMyProfile({ loading: true, data: [], message: "" })
    const results = await GET_EMP_BIO_BY_IDENID({ empid: empid });
    setMyProfile({ loading: false, data: results, message: "" });
  }

  useEffect(() => {
    LoadProfile();
  }, [])

  const HandlerType = (value) => {
    if (value === 1) {
      setStdRep("");
    } else if (value === 2) {
      setEmp_id("");
    } else {
      setStdRep("");
      setEmp_id("");
    }
    setTypeSearch(value);
  };

  const [loadSchedule, setLoadSchedule] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const RenderSchedule = async (param) => {
    setLoadSchedule({ loading: true, message: "", data: [] });
    if (typeSearch === 1) {
      param.empid = emp_id;
      const data = await GetSchdLecturer(param);
      setLoadSchedule({ loading: false, message: "", data: data });
      setParam_data(param);
    } else if (typeSearch === 2) {
      param.studentid = stdRep.toString();
      const data = await GetSchdStudent(param);
      setLoadSchedule({ loading: false, message: "", data: data });
      setParam_data(param);
    } else {
      param.empid = empid;
      const data = await GetSchdLecturer(param);
      setLoadSchedule({ loading: false, message: "", data: data });
      setParam_data(param);
      setMyschd(data);
    }
  };

  useEffect(() => {
    RenderSchedule(param_data);
  }, [emp_id, stdRep]);

  const handlerPrevNextMonth = (type) => {
    if (type === 1) {
      //prev
      const startOfMonth = moment(param_data.tgl)
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      const endOfMonth = moment(param_data.tgl)
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD");
      setParam_data({ ...param_data, tgl: startOfMonth, tglend: endOfMonth });
      const paramSchd = {
        empid: param_data.empid,
        tgl: startOfMonth,
        tglend: endOfMonth,
      };
      RenderSchedule(paramSchd);
    } else if (type === 2) {
      //next
      const startOfMonth = moment(param_data.tgl)
        .add(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      const endOfMonth = moment(param_data.tgl)
        .add(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD");
      setParam_data({ ...param_data, tgl: startOfMonth, tglend: endOfMonth });
      const paramSchd = {
        empid: param_data.empid,
        tgl: startOfMonth,
        tglend: endOfMonth,
      };
      RenderSchedule(paramSchd);
    } else {
      //today
      const startOfMonth = moment(new Date()).startOf("month").format("YYYY-MM-DD");
      const endOfMonth = moment(new Date()).endOf("month").format("YYYY-MM-DD");
      setParam_data({ ...param_data, tgl: startOfMonth, tglend: endOfMonth });
      var paramSchd = {
        empid: param_data.empid,
        tgl: startOfMonth,
        tglend: endOfMonth,
      };
      RenderSchedule(paramSchd);
    }
  };

  return (
    <>
      <div className="card card-custome mb-3">
        <div className="card-body py-2 px-2">
          <div className="filters">
            <div className="row">
              <div className="col-auto">
                <div
                  className="form-group mb-0 rounded d-flex align-items-center "
                  style={{ backgroundColor: "#004B8B" }}
                >
                  <div className="px-2">
                    <i className="fa fas fa-search icon-1 text-white me-2"></i>
                  </div>
                  <select
                    name="search_by"
                    style={{ backgroundColor: "#004B8B" }}
                    className="form-control text-white font-weight-bold border-0 mr-2 p-1"
                    defaultValue={typeSearch}
                    onChange={(e) => HandlerType(parseInt(e.target.value))}
                  >
                    <option value="">Search by:</option>
                    <option value="1">Lecturer</option>
                    <option value="2">Program Study</option>
                  </select>
                </div>
              </div>
              {typeSearch === 1 && (
                <div className="col-lg-3 px-0">
                  <div
                    className="d-flex align-items-center border rounded"
                    style={{ borderColor: "#ccc" }}
                  >
                    <div className="p-2">
                      <i className="fas fa-user-graduate icon-1 text-dark-50 me-2"></i>
                    </div>
                    <div id="select-emp" className="w-100">
                      <EmployeeSelect
                        data={emp_id}
                        setData={setEmp_id}
                        title={"Lecturer"}
                      />
                    </div>
                  </div>
                </div>
              )}
              {typeSearch === 2 && (
                <div className="col-auto px-0">
                  <DeptSemesLookUp data={stdRep} setData={setStdRep} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="calendars bg-white rounded border">
        <div className="p-2">
          <div className="d-flex align-items-center justify-content-between">
            <div className="color-info">
              <span className="text-muted">Color info:</span>
              <div className="d-flex">
                {myprofile.loading ? <LoadingBtn /> :
                  Object.values(myprofile.data).length > 0 ? (
                    <BoxColor color={"#0359fbba"} name={myprofile?.data?.fullname} />
                  ) : (
                    <BoxColor color={"#0359fbba"} name={AuthDecode.identity.given_name} />
                  )}
                <BoxColor color={"#0b8043"} name="Program Study" />
                <BoxColor color={"#FF9F40"} name="Other Lecturer" />
              </div>
            </div>
            <div className="btn-group">
              <button
                className="btn btn-sm btn-light"
                type="button"
                disabled={loadSchedule.loading}
                onClick={() => handlerPrevNextMonth(1)}
              >
                <i className="icon-1x flaticon2-left-arrow"></i>
              </button>
              <button
                className="btn btn-sm btn-light text-dark"
                type="button"
                disabled={loadSchedule.loading}
                onClick={() => handlerPrevNextMonth(3)}
              >
                <span className="h6">
                  {moment(param_data.tgl).format("MMMM YYYY")}
                </span>
              </button>
              <button
                className="btn btn-sm btn-light"
                type="button"
                disabled={loadSchedule.loading}
                onClick={() => handlerPrevNextMonth(2)}
              >
                <i className="icon-1x flaticon2-right-arrow"></i>
              </button>
            </div>
          </div>
        </div>

        {loadSchedule.loading ? (
          <LoadingBtn />
        ) : loadSchedule.message ? (
          <AlertNotif message={loadSchedule.message} />
        ) : (
          <CalendarMonth
            data={loadSchedule.data}
            myschd={myschd}
            initialDate={param_data.tgl}
            paramData={param_data}
          />
        )}
      </div>
    </>
  );
}

const BoxColor = ({ color, name }) => {
  return (
    <div className="d-flex mr-2">
      <div className="p-3 mr-1 rounded" style={{ backgroundColor: color }}></div>
      <span className="text-dark font-weight-bolder">{name}</span>
    </div>
  )
}

const GetSchdLecturer = async (param) => {
  const param_estes = {
    parameters: param,
    target_data: "GET_SCHD_CLASS_LEC_NEXT",
  };

  const result = await REQUEST_TARGET_MODULES(param_estes);
  if (result.data && Object.values(result.data).length > 0) {
    const data_schd = convertEventItem(result.data, param.empid);
    return data_schd;
  }

  return [];
};

const GetSchdStudent = async (param) => {
  const param_estes = {
    parameters: param,
    target_data: "GET_SCHD_CLASS_STD_NEXT",
  };

  const result = await REQUEST_TARGET_MODULES(param_estes);
  if (result.data && Object.values(result.data).length > 0) {
    const data_schd = convertEventItem(result.data);
    return data_schd;
  }

  return [];
};

const GET_EMP_BIO_BY_IDENID = async (param) => {
  const param_estes = {
    parameters: param,
    target_data: "GET_EMP_BIO_BY_IDENID",
  };

  const result = await REQUEST_TARGET_MODULES(param_estes);
  if (result.data && Object.values(result.data).length > 0) {
    return result.data;
  }

  return [];
};
const convertEventItem = (data, empid) => {
  let events = [];
  let d_color = "#FF9F40";
  const emp_id = AuthDecode.identity.entityid;
  if (empid) {
    if (emp_id == empid) {
      d_color = "#0359fbba";
    } else {
      d_color = "#FF9F40";
    }
  } else {
    d_color = "#0b8043"
  }
  if (data) {
    data.map((v) => {
      var objItem = {};
      objItem.title = v.section_id
        ? v.course
          ? v.course.coursetitle
          : "-"
        : v.label;
      objItem.id = v.section_id ? v.section_id : "";
      objItem.start = getDate(v.start_dt);
      objItem.end = getDate(v.end_dt);
      objItem.groupId = JSON.stringify(v);
      objItem.background = d_color;
      objItem.border = d_color;
      events.push(objItem);
    });
  }

  return events;
};

const getDate = (dayString) => {
  const today = new Date();
  const year = today.getFullYear().toString();
  let month = (today.getMonth() + 1).toString();

  if (month.length === 1) {
    month = "0" + month;
  }

  return dayString.replace("YEAR", year).replace("MONTH", month);
};
