import React, { useState } from "react";
import SuccessFailedDialog from "./SuccessFailedDialog";
import { approvalSubmition, safeBtoa } from "./helper";

const ApprovalModal = ({ id, closeState, refetchLog }) => {
  const maxLength = 1000;
  const [note, setNote] = useState("");
  const [submittedResult, setSubmittedResult] = useState(false);
  const [loadingApproval, setLoadingApproval] = useState({
    state: false,
    flag: 0,
    message: "",
  });

  const handleTextChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length > maxLength) {
      // Potong teks ke batas maksimal jika melebihi
      setNote(inputValue.slice(0, maxLength));
    } else {
      // Perbarui teks hanya jika masih di bawah batas maksimal
      setNote(inputValue);
    }
  };

  // handle approval
  const handleApproval = async (flag) => {
    setLoadingApproval({ state: true, flag: flag, message: "" });
    const body = {
      parameters: {
        act_id: id,
        flag: flag, // 1:approved, 2 rejected
        remark: safeBtoa(note),
      },
      target_data: "APPROVAL_ACTIVITY",
    };
    const approve = await approvalSubmition(body);
    // console.log("aprrov ", approve)
    setSubmittedResult(true);
    if (approve.message === "") {
      setLoadingApproval({
        state: approve.loading,
        flag: flag,
        message: "",
      });
      setTimeout(() => {
        setSubmittedResult(false);
        closeState({ state: false, id: -1 });
        refetchLog();
      }, 2000);
    } else {
      setLoadingApproval({
        state: approve.loading,
        flag: 0,
        message: approve.message,
      });
    }
  };

  return (
    <div
      className="modal fade show d-block"
      data-backdrop="static"
      style={{ backgroundColor: "rgba(63, 66, 84, 0.5)" }}
      tabIndex="-1"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div
          class="modal-content p-10 position-relative"
          style={{ width: 400 }}
        >
          {/* {loadingApproval.response === "" && ( */}
          <a
            title="close"
            className="btn btn-xs btn-icon btn-light btn-hover-danger position-absolute"
            style={{ top: 10, right: 10 }}
            onClick={() => closeState({ state: false, id: -1 })}
          >
            <i class="ki ki-close icon-sm text-muted"></i>
          </a>
          {/* )} */}
          {submittedResult ? (
            <div class="d-flex justify-content-center align-items-center">
              <div>
                {!loadingApproval.state ? (
                  loadingApproval.message === "" ? (
                    <SuccessFailedDialog
                      status={loadingApproval.flag}
                      res={loadingApproval.message}
                    />
                  ) : (
                    <SuccessFailedDialog
                      status={loadingApproval.flag}
                      res={loadingApproval.message}
                      retry={handleApproval}
                      back={setSubmittedResult}
                    />
                  )
                ) : (
                  "Loading..."
                )}
              </div>
            </div>
          ) : (
            <>
              <div class="">
                <h4 class="modal-title" id="exampleModalLabel">
                  Approve or Reject Log
                </h4>
              </div>
              <div className="my-10">
                <div className="w-100">
                  <p>Leave Note</p>
                  <textarea
                    placeholder="Leave any note here"
                    rows={3}
                    value={note}
                    onChange={handleTextChange}
                    className="w-100 p-2 rounded"
                    style={{ borderColor: "#E4E6EF", outlineColor: "#7E8299" }}
                  />
                  <p
                    style={{
                      fontSize: "12px",
                      color: "grey",
                      textAlign: "right",
                    }}
                  >
                    <span
                      className={`${
                        note.length > 950 &&
                        "text-danger font-weight-bolder font-size-lg"
                      }`}
                    >
                      {note.length}
                    </span>
                    /{maxLength}
                  </p>
                </div>
              </div>
              <div
                className="d-flex justify-content-between"
                style={{ gap: 15 }}
              >
                <button
                  type="button"
                  className="btn btn-light-danger font-weight-bold w-100"
                  disabled={loadingApproval.flag === 1}
                  onClick={() => !loadingApproval.state && handleApproval(2)}
                >
                  {loadingApproval.flag === 2
                    ? loadingApproval.state
                      ? "Loading..."
                      : ""
                    : "Reject"}
                </button>
                <button
                  type="button"
                  className="btn btn-primary font-weight-bold w-100"
                  disabled={loadingApproval.flag === 2}
                  onClick={() => !loadingApproval.state && handleApproval(1)}
                >
                  {loadingApproval.flag === 1
                    ? loadingApproval.state
                      ? "Loading..."
                      : ""
                    : "Approve"}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApprovalModal;
