import React from 'react'
import sign from 'jwt-encode';
import { Link } from 'react-router-dom'
import packageJson from "../../../../../package.json"
import { StudentIDFormat } from '../../../Library/GlobalHelper';

export default function TableActivity(props) {
    const data = props.data;
    return (
        <div className="card card-custom card-stretch gutter-b ">
            <div className="card-body">
                <div className='table-responsive'>
                    <table className="table table-head-custom table-head-bg  table-border table-vertical-center">
                        <thead>
                            <tr>
                                <th width="30%"><span className='text-dark font-weight-bold'>Student</span></th>
                                <th width="10%"><span className='text-dark font-weight-bold'>Activity Type</span></th>
                                <th width="10%"><span className='text-dark font-weight-bold'>Status</span></th>
                                <th><span className='text-dark font-weight-bold'>Request Date</span></th>
                                <th><span className='text-dark font-weight-bold'>Detail</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(data).length > 0 ? (
                                Object.keys(data).map((k, index) => (
                                    <>
                                        <tr key={index}>
                                            <td className='font-weight-bold font-size-lg' rowSpan={(Object.values(data[k]).length > 0) ? (Object.values(data[k]).length + 1) : 1} >
                                                <Link to={{ pathname: "/counseling/student/" + k+"/academic" }} >
                                                    {k && StudentIDFormat(k)} {data[k][0].studentstatusdata.fullname}
                                                </Link>
                                            </td>
                                        </tr>
                                        {Object.values(data[k]).length > 0 ? (
                                            data[k].map((v, index) => (
                                                (v.student_activity) ? (
                                                    <tr key={index}>
                                                        <td>
                                                            <span className={"font-size-lg badge badge-" + ((v.student_activity.id === "asc.student.activity.leave" || v.student_activity.id === "asc.student.activity.resign") ? "danger" : "success")}>{v.student_activity.name}</span>
                                                        </td>
                                                        <td>
                                                            {v.event_status ? (
                                                                <span className={"font-size-lg badge badge-" + (v.event_status === "PROCESSED" ? "primary" : "warning")}>{(v.event_status === "PROCESSED") ? "APPROVED" : "PENDING APPROVAL"}</span>
                                                            ) : ""}
                                                        </td>
                                                        <td><span className="font-size-lg">{v.submit_date_formatted}</span></td>
                                                        <td>
                                                            {v.event_status ? (
                                                                <Link to={{ pathname: "/counseling/student/" + v.studentid + "/activity/" + (sign({ type: v.student_activity.name, value: v }, packageJson.system_param.PublicKey)) }} className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                                                                    <i className="fa fa-arrow-right"></i>
                                                                </Link>
                                                            ) : ""}
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    <tr>
                                                        <td></td>
                                                        <td colSpan={4}>-</td>
                                                    </tr>
                                                )
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="5">-</td>
                                            </tr>
                                        )}
                                    </>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5">No record found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}

