export {Dashboard} from "./Dashboard/Dashboard"

export {AcademicSummary} from "./AcademicSummary"
export {SDashboard} from "./ScoreGrade/SDashboard"

export {ScoreViewIndex} from "./ScoreGrade/ScoreView-New"

export {PrintTranscript} from "./ScoreGrade/PrintTranscript/PrintTranscript"

export {LRDashboard} from "./FormLeaveResignation/LRDashboard"
export {LeaveSemester} from "./FormLeaveResignation/Leave/LeaveSemester"
export {Resignation} from "./FormLeaveResignation/Resignation/Resignation"

export {ThesisInfoSTD} from "./Thesis/Revisions"
export {ThesisActivitySTD} from "./Thesis/Activity"
export {ThesisActivityExportPDF} from "./Thesis/Activity/Export"
