import React from "react";
import sign from "jwt-encode";
import { Link } from "react-router-dom";
import packageJson from "../../../../../package.json";
import { StudentIDFormat } from "../../../Library/GlobalHelper";

export default function TableActivity(props) {
  const data = props.data;
  return (
    <div className="card card-custom card-stretch gutter-b ">
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-head-custom table-head-bg  table-border table-vertical-center">
            <thead>
              <tr>
                <th width="30%">
                  <span className="text-dark font-weight-bold">Student</span>
                </th>
                <th width="10%">
                  <span className="text-dark font-weight-bold">
                    Activity Type
                  </span>
                </th>
                <th width="10%">
                  <span className="text-dark font-weight-bold">Status</span>
                </th>
                <th>
                  <span className="text-dark font-weight-bold">
                    Request Date
                  </span>
                </th>
                <th>
                  <span className="text-dark font-weight-bold">Detail</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(data).length > 0 ? (
                Object.keys(data).map((k, index) => (
                  <>
                    <tr key={index}>
                      <td
                        className="font-weight-bold font-size-lg"
                        rowSpan={
                          Object.values(data[k]).length > 0
                            ? Object.values(data[k]).length + 1
                            : 1
                        }
                      >
                        <Link
                          to={{
                            pathname: "/counseling/student/" + k + "/academic",
                          }}
                        >
                          {k && StudentIDFormat(k)}{" "}
                          {data[k][0].studentstatusdata.fullname}
                        </Link>
                      </td>
                    </tr>
                    {Object.values(data[k]).length > 0 ? (
                      data[k].map((v, index) =>
                        v.student_activity ? (
                          <tr key={index}>
                            <td>
                              <span
                                className={
                                  "font-size-lg badge badge-" +
                                  (v.student_activity.id ===
                                    "asc.student.activity.leave" ||
                                  v.student_activity.id ===
                                    "asc.student.activity.resign"
                                    ? "danger"
                                    : "success")
                                }
                              >
                                {v.student_activity.name}
                              </span>
                            </td>
                            <td>
                              <StatusAct data={v} />
                            </td>
                            <td>
                              <span className="font-size-lg">
                                {v.submit_date_formatted}
                              </span>
                            </td>
                            <td>
                              <BtnActions data={v} />
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            <td></td>
                            <td colSpan={4}>-</td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td colSpan="5">-</td>
                      </tr>
                    )}
                  </>
                ))
              ) : (
                <tr>
                  <td colSpan="5">No record found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

const StatusAct = ({ data }) => {
  const act_type = data?.student_activity?.id || "";
  const event_status = data?.event_status || "";
  const badge_color = (event_status === "PROCESSED" ? "primary" : "warning");
  const event_name = event_status === "PROCESSED" ? "APPROVED" : "PENDING APPROVAL";
  if (act_type.includes("resign")) {
    return (
        <span className={"font-size-lg badge badge-" + badge_color}>
          {event_name} BY AAO
        </span>
      );
  } else {
    return (
      <span className={"font-size-lg badge badge-" + badge_color}>
        {event_name}
      </span>
    );
  }
};

const BtnActions = ({ data }) => {
  const act_type = data?.student_activity?.id || "";
  if (
    act_type.includes("pcf") ||
    act_type.includes("resign") ||
    act_type.includes("leave")
  ) {
    return (
      <Link
        to={{
          pathname:
            "/counseling/student/" +
            data.studentid +
            "/activity/" +
            sign(
              { type: data.student_activity.name, value: data },
              packageJson.system_param.PublicKey
            ),
        }}
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
      >
        <i className="fa fa-arrow-right"></i>
      </Link>
    );
  } else {
    return "";
  }
};
