import React, { useState, useRef, useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import {
  approvalSubmition,
  safeBtoa,
  yyyyMmDd,
} from "../../../../Lecturer/Thesis/Activity/components/helper";
import ResendSuccessDialog from "./ResendSuccessDialog";

const ModalNewActivity = ({ closeModal, optionsAdvisor, datas, getLog }) => {
  const [startDate, setStartDate] = useState(localStorage.getItem("startDate") ? new Date(localStorage.getItem("startDate")) : new Date());
  const minDate2Days = new Date();
  minDate2Days.setDate(minDate2Days.getDate() - 2);
  const [advisorChoosen, setAdvisorChoosen] = useState(
    JSON.parse(localStorage.getItem("advisor")) ?? ""
  );
  const [description, setDescription] = useState(
    localStorage.getItem("description") ?? ""
  );
  const [loadingSubmit, setLoadingSubmit] = useState({
    loading: false,
    data: {},
    message: "",
  });
  const [resultSubmit, setResultSubmit] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const maxLength = 1000;

  // uploaad file
  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    // const fileUploaded = event.target.files[0];
    // handleFile(fileUploaded);
  };

  // handle submit activity
  const handleSubmitActivity = async () => {
    setLoadingSubmit({ loading: true, res: "" });
    const body = {
      parameters: {
        type: "act.type.thesis.activity",
        subject: safeBtoa("Test"),
        act_date: yyyyMmDd(startDate),
        descr: safeBtoa(description),
        target_user_id: advisorChoosen.value.email,
        user_id: datas.data.studentdata.sgumail,
      },
      target_data: "CREATE_SUBMIT_ACTIVITY",
    };
    console.log(body);
    const submit = await approvalSubmition(body);

    console.log(submit);

    setLoadingSubmit(submit);
    setResultSubmit(true);
    if (submit.message === "") {
      getLog(datas.data);
      setTimeout(() => {
        localStorage.removeItem("description");
        localStorage.removeItem("startDate");
        localStorage.removeItem("advisor");
        closeModal(false);
      }, 3000);
    } else {
      localStorage.setItem("description", description);
      localStorage.setItem("startDate", startDate);
      localStorage.setItem("advisor", JSON.stringify(advisorChoosen));
    }
  };

  const handleTextAreaChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length > maxLength) {
      // Potong teks ke batas maksimal jika melebihi
      setDescription(inputValue.slice(0, maxLength));
    } else {
      // Perbarui teks hanya jika masih di bawah batas maksimal
      setDescription(inputValue);
    }
  };

  useEffect(() => {
    console.log(advisorChoosen);
    console.log(startDate);
  }, [advisorChoosen]);

  return (
    <>
      <div
        className="modal fade show d-block"
        data-backdrop="static"
        style={{ backgroundColor: "rgba(63, 66, 84, 0.5)" }}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content p-10 position-relative"
            style={{ width: 450 }}
          >
            <a
              title="close"
              className="btn btn-xs btn-icon btn-light btn-hover-danger position-absolute"
              style={{ top: 10, right: 10 }}
              onClick={() => {
                closeModal(false);
                setResultSubmit(false);
              }}
            >
              <i className="ki ki-close icon-sm text-muted"></i>
            </a>
            {resultSubmit ? (
              <div className="d-flex justify-content-center align-items-center">
                <div>
                  {loadingSubmit.message === "" ? (
                    <ResendSuccessDialog status={loadingSubmit.message} />
                  ) : (
                    <ResendSuccessDialog
                      status={loadingSubmit.message}
                      retry={handleSubmitActivity}
                      closeModal={setResultSubmit}
                    />
                  )}
                </div>
              </div>
            ) : (
              <>
                <h4 className="modal-title" id="exampleModalLabel">
                  Add New Advisory Log
                </h4>
                <div className="my-10">
                  <div className="w-100">
                    <div className="d-flex flex-column">
                      <div className="d-flex mb-5">
                        <div className="d-flex flex-column mr-5 w-100">
                          <p>Date</p>
                          <div className="d-flex align-items-center position-relative w-100">
                            <label className="position-relative w-100">
                              <ReactDatePicker
                                selected={startDate}
                                minDate={minDate2Days}
                                onChange={(date) => setStartDate(date)}
                                dateFormat="MMMM d, yyyy"
                                className="w-100 rounded border-1 border-light-primary p-2"
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  top: 8,
                                  right: 10,
                                }}
                              >
                                <i className="icon-md far fa-calendar-alt"></i>
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className="d-flex flex-column gap-2 w-100">
                          <p>
                            Advisor<span className="text-danger">*</span>
                          </p>
                          <div>
                            <Select
                              options={optionsAdvisor}
                              value={advisorChoosen}
                              onChange={(e) => setAdvisorChoosen(e)}
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: "#f3f4f6",
                                  outline: "none",
                                  boxShadow: "none",
                                  "&:hover": {
                                    borderColor: "#f3f4f6",
                                  },
                                }),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-column w-100">
                        <p>
                          Description<span className="text-danger">*</span>
                        </p>
                        <textarea
                          rows={4}
                          name="description"
                          placeholder="Describe your work"
                          className="border rounded p-2"
                          style={{
                            outline: "none",
                            borderColor: "#E4E6EF",
                            outlineColor: "#7E8299",
                          }}
                          value={description}
                          onChange={handleTextAreaChange}
                        />
                        <p
                          style={{
                            fontSize: "12px",
                            color: "grey",
                            textAlign: "right",
                          }}
                        >
                          <span
                            className={`${
                              description.length > 950 &&
                              "text-danger font-weight-bolder font-size-lg"
                            }`}
                          >
                            {description.length}
                          </span>
                          /{maxLength}
                        </p>
                      </div>
                      {/* attachment */}
                      {/* <div
                      className="d-flex w-50 mt-5 cursor-pointer"
                      onClick={handleClick}
                    >
                      <i className="icon-lg la la-paperclip mr-1"></i>
                      <p className="text-muted">Add Attachment</p>
                      <input
                        type="file"
                        onChange={handleChange}
                        ref={hiddenFileInput}
                        disabled
                        className="position-absolute"
                        style={{ display: "none" }}
                      />
                    </div> */}
                    </div>
                  </div>
                </div>
                <label class="checkbox d-flex align-items-start mb-10">
                  <input
                    type="checkbox"
                    checked={checkbox}
                    onChange={() => setCheckbox(!checkbox)}
                  />
                  <span></span>
                  <p className="p-0 m-0 pl-1">
                    I acknowledge that this record cannot be edited after
                    submission.
                  </p>
                </label>
                <button
                  type="button"
                  className={`btn ${
                    loadingSubmit.loading ? "btn-light-primary" : "btn-primary"
                  }`}
                  disabled={
                    description === "" || advisorChoosen === "" || !checkbox
                  }
                  onClick={() =>
                    !loadingSubmit.loading && handleSubmitActivity()
                  }
                >
                  {loadingSubmit.loading ? "Loading..." : "Submit"}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalNewActivity;
