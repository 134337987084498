import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import {
  Dashboard,
  AcademicSummary,
  SDashboard,
  PrintTranscript,
  LeaveSemester,
  Resignation,
  ThesisInfoSTD,
  ScoreViewIndex,
  ThesisActivitySTD,
  ThesisActivityExportPDF
} from "../Page/Student";
import Profiles from "../Page/Profiles";
import ServiceRequest from "../Page/Widgets/ServiceRequest/ServiceRequest";
import {
  CurrPCF,
  Leave,
  RevisionPCF,
} from "../Page/Student/Reregistration-New";
import { ThesisScoreIndex } from "../Page/Student/ScoreGrade/ThesisScore";

export function RouteStudent(props) {
  const routers = props.data;
  const COMPONENT_MAP = {
    Dashboard: Dashboard,
    Profiles: Profiles,
    AcademicSummary: AcademicSummary,
    SDashboard: SDashboard,

    ScoreViewIndex: ScoreViewIndex,
    ThesisScoreIndex: ThesisScoreIndex,
    PrintTranscript: PrintTranscript,

    ServiceRequest: ServiceRequest,
    LeaveSemester: LeaveSemester,
    Resignation: Resignation,

    ThesisInfoSTD: ThesisInfoSTD,
    ThesisActivitySTD: ThesisActivitySTD,
    ThesisActivityExportPDF: ThesisActivityExportPDF,

    CurrPCF: CurrPCF,
    Leave: Leave,
    RevisionPCF: RevisionPCF,


  };
  const routeList = [];
  if (Object.keys(routers).length > 0) {
    for (let index = 0; index < routers.Academic.length; index++) {
      routeList.push(routers.Academic[index]);
    }
    for (let index = 0; index < routers.Config.length; index++) {
      routeList.push(routers.Config[index]);
    }
  }
  console.log(routeList);
  return (
    <Suspense fallback={<h3>Loading..</h3>}>
      <>
        <Route path="/" component={COMPONENT_MAP["Dashboard"]} exact />
        <Route path="/dashboard" component={COMPONENT_MAP["Dashboard"]} />
        <Route path="/profile" component={COMPONENT_MAP["Profiles"]} />

        {/* ammar */}
        <Route path="/thesis/activity-export" component={COMPONENT_MAP["ThesisActivityExportPDF"]} />

        <Route
          path="/registration/revision-plan-course-form"
          component={COMPONENT_MAP["RevisionPCF"]}
        />
        {Object.keys(routeList).length > 0
          ? routeList.map((v) =>
              Object.keys(v.Child).length > 0 ? (
                <Route
                  key={v.ID}
                  path={v.PathURI}
                  render={({ match: { url } }) => (
                    <>
                      <Route
                        path={`${url}/`}
                        component={COMPONENT_MAP[v.Slug]}
                        exact
                      />
                      {v.Child.map((k) => (
                        <Route
                          key={v.ID}
                          path={`${url + k.URI}`}
                          component={COMPONENT_MAP[k.Slug]}
                        />
                      ))}
                    </>
                  )}
                />
              ) : (
                <Route
                  key={v.ID}
                  path={v.PathURI}
                  component={COMPONENT_MAP[v.Slug]}
                />
              )
            )
          : ""}
      </>
    </Suspense>
  );
}

export default RouteStudent;
