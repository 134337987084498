import React, { useEffect } from 'react'
import jwt_decode from "jwt-decode"
import AvatarBoy from "../../../../media/avatars/001-boy.svg";
import AvatarGirl from "../../../../media/avatars/002-girl.svg";
import { useState } from 'react';
import Overview from './Overview';
import Academic from './Academic';
import Settings from './Settings';
import { AlertNotif } from '../../../Library/AlertNotif';
import AxiosLibWithHeader2 from '../../../Library/AxiosLibWithHeader2';
import LoadingBtn from '../../Widgets/LoadingBtn';
import { StudentIDFormat } from '../../../Library/GlobalHelper';

export default function ProfileStudent() {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);

    const [stdStatus, setStdStatus] = useState({
        loading: false,
        message: "",
        data: []
    });

    const GET_STUDENT_STATUS = (parameters) => {
        setStdStatus({ loading: true, data: [], message: "" });
        var keys = {
            "AppId": AuthDecode.AppID,
            "AppEnv": AuthDecode.AppEnv,
            "Secretkey": AuthDecode.secretkey
        };
        var body = {
            "parameters": parameters,
            "target_data": "GET_STUDENT_STATUS"
        };
        const postData = {
            keys: keys,
            body: body
        };
        AxiosLibWithHeader2(postData, 'app/api/req-module')
            .then(
                response => {
                    if (response.error) {
                        setStdStatus({
                            data: [],
                            message: response.error,
                            loading: false,
                        });
                    } else if ("message" in response.response) {
                        var msg = response.response.message.user;
                        setStdStatus({
                            data: [],
                            message: msg,
                            loading: false,
                        });
                    } else {
                        var results = response.response.data;
                        if (results) {
                            setStdStatus({
                                data: results,
                                message: "",
                                loading: false,
                            });
                        } else {
                            setStdStatus({
                                data: [],
                                message: "No record found",
                                loading: false,
                            });
                        }
                    }
                }
            );
    }

    const [stdBio, setStdBio] = useState({
        loading: false,
        message: "",
        data: []
    });

    const GET_STUDENT_DATA_M = (parameters) => {
        setStdBio({ loading: true, data: [], message: "" });
        var keys = {
            "AppId": AuthDecode.AppID,
            "AppEnv": AuthDecode.AppEnv,
            "Secretkey": AuthDecode.secretkey
        };
        var body = {
            "parameters": parameters,
            "target_data": "GET_STUDENT_DATA_M"
        };
        const postData = {
            keys: keys,
            body: body
        };
        AxiosLibWithHeader2(postData, 'app/api/req-module')
            .then(
                response => {
                    if (response.error) {
                        setStdBio({
                            data: [],
                            message: response.error,
                            loading: false,
                        });
                    } else if ("message" in response.response) {
                        var msg = response.response.message.user;
                        setStdBio({
                            data: [],
                            message: msg,
                            loading: false,
                        });
                    } else {
                        var results = response.response.data;
                        if (results) {
                            setStdBio({
                                data: results,
                                message: "",
                                loading: false,
                            });
                        } else {
                            setStdBio({
                                data: [],
                                message: "No record found",
                                loading: false,
                            });
                        }
                    }
                }
            );
    }

    useEffect(() => {
        GET_STUDENT_STATUS({ studentid: AuthDecode.currentData.entityid });
        GET_STUDENT_DATA_M({ studentid: AuthDecode.currentData.entityid })
    }, [])

    const BoxItem = ({ title, value }) => {
        return (
            <div className="border border-gray-300 rounded py-3 px-4 ">
                <div className="d-flex align-items-center">
                    <div className="text-dark text-hover-primary font-size-h4 font-weight-bold">
                        {value}
                    </div>
                </div>
                <div className="font-weight-bold text-muted">
                    {title}
                </div>
            </div>
        );
    }

    const [selectedTab, setTab] = useState(0);
    const tabNav = [{ id: 1, name: "Overview", component: <Overview profile={Object.values(stdBio.data).length > 0 ? stdBio.data: []} /> },
    { id: 2, name: "Academic", component: <Academic profile={stdBio.data} status={stdStatus.data} /> },
    { id: 3, name: "Settings", component: <Settings profile={stdBio.data} /> }];

    const activeTab = { borderBottom: "2px solid #8950fc" };

    return (
        <div id="profiles">
            <div className="card mb-5 mb-xl-10">
                <div className="card-body pt-9 pb-0">
                    {stdBio.loading || stdStatus.loading ? <LoadingBtn /> : stdBio.message || stdStatus.message ? <AlertNotif message={stdBio.message || stdStatus.message} color={"danger"} title={"Information"} /> : (
                        Object.values(stdBio.data).length > 0 || Object.values(stdStatus.data).length > 0 ? (
                            <>
                                <div className="d-flex mb-9">
                                    <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                                        <div className="symbol symbol-50 symbol-lg-120">
                                            <img src={((stdBio.data.gender === "w") ? AvatarGirl : AvatarBoy)} alt="image" className="bg-gray-100 p-2" />
                                        </div>
                                        <div className="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
                                            <span className="font-size-h3 symbol-label font-weight-boldest">{stdBio.data.fullname}</span>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1">
                                        <div className="d-flex justify-content-between flex-wrap mt-1">
                                            <div className="d-flex mr-3">
                                                <span className="text-dark-75 text-hover-primary font-size-h2 font-weight-bold mr-3">{stdBio.data.fullname}</span>
                                                <i className="flaticon2-correct text-success font-size-h2"></i>
                                            </div>
                                            <div className="my-lg-0 my-3">
                                                <span className="btn btn-sm btn-light font-weight-bolder text-uppercase mr-3">GPA {(stdStatus.data.cgpa) ? stdStatus.data.cgpa : "0"}</span>
                                                <span className="btn btn-sm btn-info font-weight-bolder text-uppercase">Student {(stdStatus.data.is_active === 1) ? 'Active' : 'Not Active'}</span>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-wrap justify-content-between mt-1">
                                            <div className="d-flex flex-column flex-grow-1 pr-8">
                                                <div className="d-flex flex-wrap mb-4">
                                                    <span className="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                        <span className="svg-icon svg-icon-4 mr-1">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path opacity="0.3" d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z" fill="currentColor"></path>
                                                                <path d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z" fill="currentColor"></path>
                                                            </svg>
                                                        </span>
                                                        <span className="text-lowercase">
                                                            {stdStatus.data.studentid && StudentIDFormat(stdStatus.data.studentid)}
                                                        </span>
                                                    </span>
                                                    <span className="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                        <span className="svg-icon svg-icon-4 mr-1">
                                                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24" />
                                                                    <path d="M9.82829464,16.6565893 C7.02541569,15.7427556 5,13.1079084 5,10 C5,6.13400675 8.13400675,3 12,3 C15.8659932,3 19,6.13400675 19,10 C19,13.1079084 16.9745843,15.7427556 14.1717054,16.6565893 L12,21 L9.82829464,16.6565893 Z M12,12 C13.1045695,12 14,11.1045695 14,10 C14,8.8954305 13.1045695,8 12,8 C10.8954305,8 10,8.8954305 10,10 C10,11.1045695 10.8954305,12 12,12 Z" fill="#000000" />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        <span className="text-capitalize">
                                                            {(stdStatus.data.program) ? stdStatus.data.program.name : '-'}
                                                        </span>
                                                    </span>
                                                    <span className="text-dark-50 text-hover-primary font-weight-bold">
                                                        <span className="svg-icon svg-icon-4 mr-1">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z" fill="currentColor"></path>
                                                                <path d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z" fill="currentColor"></path>
                                                            </svg>
                                                        </span>
                                                        <span className="text-lowercase">
                                                            {(stdBio.data.sgumail) ? stdBio.data.sgumail : stdBio.data.firstname + "." + stdBio.data.lastname + "@student.sgu.ac.id"}
                                                        </span>
                                                    </span>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <BoxItem title="Faculty" value={(stdStatus.data.clu) ? ((Object.values(stdStatus.data.clu.faculty).length > 0) ? stdStatus.data.clu.faculty.name : '-') : '-'} />
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <BoxItem title="Department" value={(stdStatus.data.clu) ? ((Object.values(stdStatus.data.clu.dept).length > 0) ? stdStatus.data.clu.dept.name : '-') : '-'} />
                                                    </div>
                                                    {(stdStatus.data.clu_spec) ? (
                                                        <div className="col-lg-4">
                                                            <BoxItem title="Specialize" value={stdStatus.data.clu_spec.name} />
                                                        </div>
                                                    ) : ''}
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex">
                                    <ul className="nav nav-tabs nav-tabs-line border-bottom-0">
                                        {tabNav.map((v, index) => (
                                            <li className='nav-item py-5 mr-10' key={v.id} style={((selectedTab === index) ? activeTab : { cursor: "pointer" })} onClick={(e) => setTab(index)}>
                                                <span className={"font-size-h4 text-center font-weight-bold text-" + ((selectedTab === index) ? 'info' : 'muted')}>
                                                    {v.name}
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </>
                        ) : ""
                    )}

                </div>
            </div>

            {(Object.values(stdBio.data).length > 0) || (Object.values(stdBio.data).length > 0) ? (
                <div className="card card-tabs mb-5 mb-xl-10">
                    <div className="card-header">
                        <h3 className="card-title mb-0">
                            {tabNav[selectedTab].name}
                        </h3>
                    </div>
                    <div className="card-body py-9">
                        {tabNav[selectedTab].component}

                        <AlertNotif title={"Update Biodata"} message={"If you wants to update your data please contact <b>Academic Administration Office (AAO) at academic@sgu.ac.id</b>"} color={"warning"} />
                    </div>
                </div>
            ) : ""}
        </div>

    )
}
