import React from "react";
import { Link } from 'react-router-dom'

const icon_freemason = (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <path
        d="M7.07744993,12.3040451 C7.72444571,13.0716094 8.54044565,13.6920474 9.46808594,14.1079953 L5,23 L4.5,18 L7.07744993,12.3040451 Z M14.5865511,14.2597864 C15.5319561,13.9019016 16.375416,13.3366121 17.0614026,12.6194459 L19.5,18 L19,23 L14.5865511,14.2597864 Z M12,3.55271368e-14 C12.8284271,3.53749572e-14 13.5,0.671572875 13.5,1.5 L13.5,4 L10.5,4 L10.5,1.5 C10.5,0.671572875 11.1715729,3.56793164e-14 12,3.55271368e-14 Z"
        fill="#000000"
        opacity="0.3"
      ></path>
      <path
        d="M12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 Z M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z"
        fill="#000000"
        fill-rule="nonzero"
      ></path>
    </g>
  </svg>
);

const icon_box = (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <path
        d="M20.4061385,6.73606154 C20.7672665,6.89656288 21,7.25468437 21,7.64987309 L21,16.4115967 C21,16.7747638 20.8031081,17.1093844 20.4856429,17.2857539 L12.4856429,21.7301984 C12.1836204,21.8979887 11.8163796,21.8979887 11.5143571,21.7301984 L3.51435707,17.2857539 C3.19689188,17.1093844 3,16.7747638 3,16.4115967 L3,7.64987309 C3,7.25468437 3.23273352,6.89656288 3.59386153,6.73606154 L11.5938615,3.18050598 C11.8524269,3.06558805 12.1475731,3.06558805 12.4061385,3.18050598 L20.4061385,6.73606154 Z"
        fill="#000000"
        opacity="0.3"
      />
      <polygon
        fill="#000000"
        points="14.9671522 4.22441676 7.5999999 8.31727912 7.5999999 12.9056825 9.5999999 13.9056825 9.5999999 9.49408582 17.25507 5.24126912"
      />
    </g>
  </svg>
);

const icon_dashboard = (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <defs></defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon points="0 0 24 0 24 24 0 24"></polygon>
      <path
        d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z"
        fill="#000000"
        fillRule="nonzero"
      ></path>
      <path
        d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z"
        fill="#000000"
        opacity="0.3"
      ></path>
    </g>
  </svg>
);

const icon_info = (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <defs />
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
      <rect fill="#000000" x="11" y="10" width="2" height="7" rx="1" />
      <rect fill="#000000" x="11" y="7" width="2" height="2" rx="1" />
    </g>
  </svg>
);

const icon_arrow_right = (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon points="0 0 24 0 24 24 0 24" />
      <rect fill="#000000" opacity="0.3" transform="translate(14.000000, 12.000000) rotate(-90.000000) translate(-14.000000, -12.000000) " x="13" y="5" width="2" height="14" rx="1" />
      <rect fill="#000000" opacity="0.3" x="3" y="3" width="2" height="18" rx="1" />
      <path d="M11.7071032,15.7071045 C11.3165789,16.0976288 10.6834139,16.0976288 10.2928896,15.7071045 C9.90236532,15.3165802 9.90236532,14.6834152 10.2928896,14.2928909 L16.2928896,8.29289093 C16.6714686,7.914312 17.281055,7.90106637 17.675721,8.26284357 L23.675721,13.7628436 C24.08284,14.136036 24.1103429,14.7686034 23.7371505,15.1757223 C23.3639581,15.5828413 22.7313908,15.6103443 22.3242718,15.2371519 L17.0300721,10.3841355 L11.7071032,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(16.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-16.999999, -11.999997) " />
    </g>
  </svg>
)

const KTICon = ({ iconname, classname }) => {
  return (
    <span
      className={
        "svg-icon " + (classname ? classname : "svg-icon-primary svg-icon-2x")
      }
    >
      {iconname
        ? iconname.includes("jangkar")
          ? icon_freemason
          : iconname.includes("box")
            ? icon_box
            : iconname.includes("info")
              ? icon_info
              : iconname.includes("arrow-right")
                ? icon_arrow_right :
                icon_info
        : icon_info}
    </span>
  );
};

const CardMessage = ({ message, icon }) => {
  return (
    <div className="bg-white p-5 rounded">
      <div className="border rounded p-5 text-center bg-light">
        <KTICon iconname={icon} />
        <p className="text-primary mb-0">{message}</p>
      </div>
    </div>
  );
};

const CardSpinner = () => {
  return (
    <div className="bg-white p-5 rounded">
      <div className="border rounded p-5 text-center bg-light">
        <div className="spinner spinner-primary spinner-lg mr-15"></div>
        <p className="text-primary mb-0">Wait for a seccond</p>
      </div>
    </div>
  );
};

const CardTitle = ({ message, icon, is_open }) => {
  return (
    <div
      className="alert alert-custom alert-white alert-shadow fade show gutter-b"
      role="alert"
    >
      <div className="alert-icon">
        <KTICon iconname={icon} />
      </div>
      <div className="alert-text">
        <h3>{message}</h3>
      </div>
    </div>
  );
};

const BoxLabel = ({ title, value, color }) => {
  return (
    <div
      className={
        "border border-dashed rounded py-2 px-3 min-w-80px mr-3 " +
        (color || "border-gray-300")
      }
    >
      <div className="h4 font-weight-bolder text-gray-700 text-center">
        <span className="w-75px">{value}</span>
      </div>
      <div className="font-weight-bolder text-muted">{title}</div>
    </div>
  );
};

const ButtonPrimary = (props) => {
  const { children, className, ...others } = props;
  return (
    <button className={"btn btn-primary " + className} {...others} >
      {children}
    </button>
  )
}

const ButtonPCFIcon = ({ title, url }) => {
  return (
    <Link to={{ pathname: url }} className="btn font-weight-bolder text-uppercase font-size-lg btn-primary py-2 px-6">
      <span>{title}</span>
      <KTICon iconname={"arrow-right"} classname={"svg-icon-2x"} />
    </Link>
  )
}

const InputTextPCF = ({ title, required = false, msgerror, ...other }) => {
  return (
    <div className="form-group">
      <label className="font-weight-bolder">
        {title}
        {required && (<sup className="text-danger">*</sup>)}
      </label>
      <input type="text" required={required} {...other} />
      <span className="text-danger">
        {msgerror}
      </span>
    </div>
  )
}

const RenderCounselor = ({counselor}) => {
  return (
    <div className="col-lg-3">
      <div className="form-group">
        <label className="font-weight-bolder">Counselor</label>
        {counselor.data.map((v, index) => (
          <div className="list-counselor form-control form-control-solid" key={index}>
            <span className="font-weight-bold">
              {v.emp ? v.emp.fullname : ""}
            </span>
            {index < Object.values(counselor.data).length - 1 && (
              <span className="mr-1">, </span>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export { CardMessage, CardTitle, BoxLabel, CardSpinner, ButtonPrimary, ButtonPCFIcon, InputTextPCF, RenderCounselor };
